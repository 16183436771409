<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
         
          <view-accounting-report
            :results="results"
            :refresh="fetchData"
            :searchQuery="searchTerm"
          /> 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ViewAccountingReport from "./../components/property-manager/ViewAccountingReport.vue";
  // import Loader from "./../components/Loader.vue";
  // import { viewAccountingReport } from "../../api/property-manager/reports";
  import { ElLoading } from "element-plus";
  import { ref } from "vue";
  import bus from '@/utils/events.js';

  const isLoading = ref(false);
  const results = ref([]);
  const searchTerm = ref("");
  export default {
    name: "results",
    components: {
      ViewAccountingReport,
    //   Loader,
    },
    setup() {

    bus.on('fetch-report-data', (data)=>{
        results.value = data;
    })
    //   const fetchData = async () => {
    //     isLoading.value = true;
    //     try {
    //       const response = await viewAccountingReport();
    //       results.value = response.data;

    //       console.log(results.value);
    //     } catch (error) {
    //       console.log(error);
    //     } finally {
    //       isLoading.value = false;
    //     }
    //   };
  
      const fullScreenLoader = () => {
        ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      };
  
      return {
        fullScreenLoader,
        // fetchData,
        results,
        isLoading,
        searchTerm
      };
    },
    mounted() {
    //   this.fetchData();
    },
  };
  </script>
  