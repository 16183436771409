<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-home text-primary me-2" />
        {{ props.unit.unitId != null ? "Edit Unit" : "New Unit" }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="unitFormRef"
        :model="unitForm"
        :rules="rules"
        label-width="120px"
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="inline-form-group">
              <div class="col-md-12 mb-2 inline-form-item">
                <el-form-item label="Unit code" prop="unit_code">
                  <el-input v-model="unitForm.unit_code" />
                </el-form-item>
              </div>

              <div class="col-md-12 mb-2 inline-form-item">
                <el-form-item label="Rental Rate" prop="rent">
                  <el-input v-model="unitForm.rent" />
                </el-form-item>
              </div>
            </div>
            <div class="inline-form-group">
              <div class="col-md-12 mb-2 inline-form-item">
                <el-form-item label="Service Charge" prop="service_charge">
                  <el-input v-model="unitForm.service_charge" />
                </el-form-item>
              </div>
              <div class="col-md-12 mb-2 inline-form-item">
                <el-form-item label="Kra Rate" prop="kra_rate">
                  <el-select style="width: 100%" v-model="unitForm.kra_rate">
                    <el-option label="7.5" value="7.5"></el-option>
                    <el-option label="16" value="16"></el-option>
                    <el-option label="" value=""></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs null null"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(unitFormRef)"
          >
            {{ unit.unitId != null ? "Update Unit" : "Create Unit" }}</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { createUnit, updateUnit } from "../../../api/property-manager/units";
import { ElNotification } from "element-plus";

const unitFormRef = ref();
const isLoading = ref(false);
const rents = ref([]);

export default {
  name: "unit-form",

  props: {
    unit: {
      type: Object,
      required: false,
    },
    propertyId: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    console.log(props?.unit);

    const unitForm = reactive({
      id: props?.unit.unitId || 0,
      unit_code: props?.unit?.unitCode || "",
      service_charge: props?.unit?.garbage || "",
      kra_rate: props?.unit?.kra_rate || "",
      rent: props?.unit?.hoaFee || "",
      property_id: props?.propertyId || 0,
    });

    const rules = reactive({
      rent: [
        {
          required: true,
          message: "Please select a rent",
          trigger: ["blur", "change"],
        },
      ],
      unit_code: [
        {
          required: true,
          message: "Please input unit code",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("service_charge", unitForm.service_charge);
          formData.append("kra_rate", unitForm.kra_rate);
          formData.append("unit_code", unitForm.unit_code);
          formData.append("rent", unitForm.rent);
          formData.append("property_id", unitForm.property_id);

          if (unitForm.id > 0) {
            updateUnit(unitForm, unitForm.id)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("close-modal");
                } else if (data.error) {
                  ElNotification({
                    title: "Error",
                    message: data.error,
                    type: "error",
                  });
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) =>
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                })
              )
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            createUnit(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("close-modal");
                } else if (data.error) {
                  ElNotification({
                    title: "Error",
                    message: data.error,
                    type: "error",
                  });
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) => console.log(err))
              .finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      unitForm,
      submitForm,
      rules,
      unitFormRef,
      isLoading,
      props,
      rents,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 550px;
  max-height: 360px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: 0.75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.close i {
  font-size: 15px;
}

input {
  border-radius: 6px;
}
.inline-form-group {
  display: flex;
}

.inline-form-item {
  flex: 1;
  margin-right: 20px;
}
</style>
