<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center gap-2"></div>
      <div class="cta">
        <button v-if="hasPermission('create.units')" @click="newUnit" class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button">
          <i class="fas fa-plus  me-1" aria-hidden="true"></i>New Unit
        </button>
        <FilterTablesForm
            :show_property_units_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
        <el-button-group>
          <el-button
              type="info"
              @click="submitExcelForm(accountFormRef)"
          >
            <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
          </el-button>
          <el-button
              type="warning"
              @click="downloadPdf(accountFormRef)"
          >
            <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="card-body">
      <div  v-if="units.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Unit Code
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Rental Rate
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Service Charges
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Status
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Created Date
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in units.data" :key="item.unitId + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.unitCode
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.unitCode }}
                    </h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs font-weight-bold mb-0">{{ item.hoaFee }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <p class="text-xs font-weight-bold mb-0">{{ item.garbage }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                                    <span
                                        class="badge badge-sm"
                                        :class="getStatusColor(parseInt(item.isLeased))"
                                    >{{ parseInt(item.isLeased) === 0 ? "Vacant" : "Occupied" }}</span
                                    >
              </td>
              <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{
                                        dateTime(item.lastUpdated)
                                      }}</span>
              </td>
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                                      <span class="el-dropdown-link">
                                        <i class="fa fa-ellipsis-v text-primary"></i>
                                      </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link
                          v-if="parseInt(item.isLeased) === 1"
                          :to="{ path: `/unit/${item.unitId}` }"
                          class="text-secondary"
                      ><el-dropdown-item
                          v-if="hasPermission('view.units')"
                          class="text-secondary font-weight-bold text-xs"
                      ><i class="fa fa-eye" aria-hidden="true"></i>
                        View</el-dropdown-item
                      ></router-link
                      >
                      <el-dropdown-item
                          v-if="hasPermission('update.units')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="editUnit(item)"
                      ><i class="fa fa-pencil" aria-hidden="true"></i>
                        Edit
                      </el-dropdown-item>

                      <el-dropdown-item

                          v-if="parseInt(item.isLeased) === 0 && hasPermission('create.leases')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="leaseUnit(item.unitId)"
                      ><i class="fa fa-check" aria-hidden="true"></i>
                        Lease
                      </el-dropdown-item>

                      <el-dropdown-item
                          v-if="parseInt(item.isLeased) === 0 && hasPermission('delete.units')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="deleteUnit(item.unitId)"
                      ><i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
  <UnitForm
      v-if="showModal"
      @close-modal="showModal = false"
      :unit="unitDetails"
      @refresh="refresh"
      :propertyId="propertyId"
  />
  <LeaseForm
      v-if="showLeaseModal"
      @close-modal="showLeaseModal = false"
      :unit="UnitData"
      @refresh="refresh"
  />
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import UnitForm from "../../../components/Modals/property-manager/UnitForm.vue";
import LeaseForm from "../../../components/Modals/property-manager/LeaseForm.vue";
import {reactive, ref, h, toRefs, watch} from "vue";
import moment from "moment/moment";
import { removeUnit } from "../../../api/property-manager/units";
import { ElMessage, ElMessageBox } from "element-plus";
import FilterTablesForm from "./FilterTablesForm.vue";
import {
  downloadPropertyUnitsExcel,
  downloadPropertyUnitsPdf
} from "../../../api/property-manager/downloads";

const showModal = ref(false);
const showLeaseModal = ref(false);
const unitDetails = ref({});
const UnitData = ref({});
const filter_params = ref({});
const isLoading = ref(false);


export default {
  name: "Property Units Table",
  components: {
    FilterTablesForm,
    LeaseForm,
    UnitForm,
    EmptyStateTable,
    Pagination
  },
  props: {
    units: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    propertyId: {
      type: Object,
      required: true,
    }
  },
  setup(props){
    const { units } = toRefs(props);

    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = units.value?.total || 0;
      const per_page = units.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(units, () => {
      updateTotalPages();
      Pagination.current_page = units.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: units.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const onPageChange = (page) => {
      props.refresh(filter_params, page);
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };

    const deleteUnit = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The unit will be deleted. Continue?"
          ),

          "Delete Unit",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeUnit(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const editUnit = (item) => {
      unitDetails.value = item
      showModal.value = true;
    };

    const newUnit = () => {
      unitDetails.value = {}
      showModal.value = true;
    };

    const leaseUnit = (unitId) => {
      UnitData.value = {
        unitId : unitId,
      };
      showLeaseModal.value = true
    }

    const downloadPdf = async () => {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadPropertyUnitsPdf(filter_params, props.propertyId)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "units_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };
    const submitExcelForm = async () => {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadPropertyUnitsExcel(filter_params, props.propertyId)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "units_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      editUnit,
      getInitials,
      dateTime,
      getStatusColor,
      Pagination,
      onPageChange,
      deleteUnit,
      newUnit,
      unitDetails,
      showModal,
      UnitData,
      showLeaseModal,
      leaseUnit,
      props,
      filter_params,
      _filterCallBack,
      downloadPdf,
      submitExcelForm
    }
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    }, 
}
</script>
<style scoped>
.cta button {
  margin-right: 10px;
}
.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>