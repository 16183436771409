<template>
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-male text-primary text-md opacity-10"></i>
          <h6 v-if="results.units.length != 0">Accounting Report From {{ dateTime(results.from) }} To {{ dateTime(results.to) }}</h6>
          <h6  v-else>Accounting Report </h6>
        </div>
        <div class="cta">
        </div>
      </div>
      <div class="card-body">
        <div v-if="results.units.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
              <tr>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Unit Code
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Tenant Name
                </th>

                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
                >
                  Rental Rate
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Water Bill
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Service Charges
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Deposits
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Unit Charges
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Penalties
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Arrears
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Balance B/F
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Expected Amount
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Total Paid
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Balance
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Current Account Balance
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in results.units" :key="item.id + index">
                <td>
                  <div class="d-flex py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.unitCode
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6
                        class="text-sm mb-0 font-weight-bold"
                      >
                        {{ item.unitCode }}
                      </h6>
                      <p
                        v-if="item?.property"
                        class="text-xs text-secondary mb-0"
                      >
                      {{ item?.property?.propertyName }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item?.leases[0]?.tenant?.firstName }} {{ item?.leases[0]?.tenant?.lastName }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.hoaFee ?item.hoaFee:0}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item?.water[0]?.water_total ? item?.water[0]?.water_total:0}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.garbage ? item.garbage:0}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.deposits[0]?.total_deposits?item.deposits[0]?.total_deposits:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.charges[0]?.total_charges ? item.charges[0]?.total_charges:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.penalties[0]?.total_penalties?item.penalties[0]?.total_penalties:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.arrears[0]?.total_arrears?item.arrears[0]?.total_arrears:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.advances[0]?.total_advances?item.advances[0]?.total_advances:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ totalExpected(item)}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.payments[0]?.total_amount_paid ? item.payments[0]?.total_amount_paid:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.leases[0]?.accounts[0]?.account_transactions[0]?.total_transactional_balances ?item.leases[0]?.accounts[0]?.account_transactions[0]?.total_transactional_balances:0 }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ item.leases[0]?.accounts[0]?.balance ? item.leases[0]?.accounts[0]?.balance:0 }}</p>
                </td>
              </tr>

              <tr>
                  <td>
                  <p class="text-sm text-center font-weight-bold mb-0"> Total </p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">  </p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_rent_sum}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_water_bill}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_garbage_sum}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_deposits }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_charges }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_penalties }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_arrears }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_advances}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.expected_sum}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_paid_amount }}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{ results.total_balance}}</p>
                </td>
                <td>
                  <p class="text-xs text-center font-weight-bold mb-0">{{results.total_account_balance }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-center mb-3">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import EmptyStateTable from "../../components/EmptyTableState.vue";
  import moment from "moment";
  
  export default {
    name: "results-table",
    props: {
      results: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      refresh: {
        type: Function,
        required: true,
      },
      searchQuery: {
        type: String,
        required: true,
      },
    },
    components: {
      EmptyStateTable,
    },
  
    setup() {
      
      const getInitials = (name) => {
        if (name) {
          const nameSplit = name?.split(" ");
          const firstInitials = nameSplit[0]?.charAt(0);
          const secondInitials = nameSplit[1]?.charAt(0);
  
          return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
          );
        }
        return "";
      };
     
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };

      const totalExpected = (item) => {
        let total_expected_amt = 0;

        let water_bill = item.water[0]?.water_total ? item.water[0]?.water_total:0
        let garbage = item.garbage ? item.garbage:0
        let deposit = item.deposits[0]?.total_deposits?item.deposits[0]?.total_deposits:0
        let charges = item.charges[0]?.total_charges ? item.charges[0]?.total_charges:0 
        let penalties = item.penalties[0]?.total_penalties?item.penalties[0]?.total_penalties:0
        let arrears = item.arrears[0]?.total_arrears?item.arrears[0]?.total_arrears:0
        let advances = item.advances[0]?.total_advances?item.advances[0]?.total_advances:0

        total_expected_amt += parseInt(item.hoaFee, 10);
        total_expected_amt += parseInt(water_bill, 10);
        total_expected_amt += parseInt(garbage, 10);
        total_expected_amt += parseInt(deposit, 10);
        total_expected_amt += parseInt(charges, 10);
        total_expected_amt += parseInt(penalties, 10);
        total_expected_amt += parseInt(arrears, 10);
        total_expected_amt -= parseInt(advances, 10);

        return total_expected_amt
      }
  
      return {
        dateTime,
        getInitials,
        totalExpected
       
      };
    },
   
  };
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }
  .card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  h6 {
    margin: 0;
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #5f924d;
    border-radius: 50px;
  }
  .sticky-top th {
    color: #fff !important;
  }

  </style>
  