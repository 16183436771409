<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg'); background-position: top;"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h3 class="text-white mb-2 mt-5">Simplify Rent Collection</h3>
            <p
              class="text-lead text-white"
            >Digitize rent payment and property management for seamless reconciliation, reporting, and communication with tenants.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Forgot Password</h5>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3">
            </div>
            <div class="card-body">
              <el-form
                ref="forgotPassFormRef"
                :model="resetPassForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                size="large"
                status-icon
                :scroll-to-error="true"
                label-position="top"
                :hide-required-asterisk="true"
              >
                <el-form-item label="Email" prop="email">
                  <el-input v-model="resetPassForm.email" />
                </el-form-item>

                <el-button
                  type="success"
                  class="px-5 d-block my-4 mx-auto"
                  :loading="isLoading"
                  @click="submitForm(forgotPassFormRef)"
                >
                  Send Link
                </el-button>
              </el-form>
              <div class="px-1 pt-0 text-center card-footer px-lg-2">
                <p class="mx-auto mb-4 text-sm">
                  Back to
                  <router-link
                    to="/signin"
                    class="text-success text-gradient font-weight-bold"
                    >Sign In</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { sendLink } from "../api/services/authentication";
import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
const body = document.getElementsByTagName("body")[0];
const forgotPassFormRef = ref();
const isLoading = ref(false);

export default {
  components: {
    Navbar,
    AppFooter,
  },
  setup() {
    const resetPassForm = reactive({
      email: "",
    });

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("email", resetPassForm.email);
          localStorage.setItem("reset_email", resetPassForm.email);

          sendLink(formData)
            .then((data) => {
              if (data.status === 200) {
                ElMessage({
                  message: data.data.message,
                  type: "success",
                });
                // router.replace({ path: `auth/reset-password-link/${data.data.token}` });
              } else {
                let error = Object.values(data.errors);

                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      isLoading,
      submitForm,
      rules,
      resetPassForm,
      forgotPassFormRef,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
