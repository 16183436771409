<template>
  <div class="fixed-top">
    <div class="sub-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8">
            <ul class="info">
              <li><i class="fa fa-envelope" style="color:#5F924D;"></i> mreside@swifthub.co.ke</li>
              <li><i class="fa fa-phone" style="color:#5F924D;"></i> +254711852147</li>
              <li><i class="fa fa-map" style="color:#5F924D;"></i> Westlands, Commercial Center</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-4">
            <ul class="social-links">
              <li><a href="https://www.facebook.com/MresideKenya/" target="_blank"><i class="fab fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/mresideKENYA" target="_blank"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-linkedin"></i></a></li>
              <li><a href="https://www.instagram.com/mresidedigital" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- ***** Header Area Start ***** -->
    <header class="header-area header-sticky">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav class="main-nav">
              <!-- ***** Logo Start ***** -->
              <a href="#" class="logo mt-2">
                <img src="@/assets/img/logo-ct.png" alt="">
              </a>
              <!-- ***** Logo End ***** -->
              <!-- ***** Menu Start ***** -->
              <ul class="nav">
                <li><a href="#" class="active">Home</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#value">Package Value</a></li>
                <li><a href="#contact">Contact Us</a></li>
                <!-- <li><a @click.prevent="demo" class="pointer"><i class="fa fa-calendar" ></i> Schedule a demo</a></li> -->
                <li><router-link to="/book-demo" class="pointer"><i class="fa fa-calendar" ></i> Schedule a demo</router-link></li>
              </ul>
              <a class='menu-trigger'>
                <span>Menu</span>
              </a>
              <!-- ***** Menu End ***** -->
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
  <!-- ***** Header Area End ***** -->

  <section id="hero" class="hero topp">
    <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg" alt="" data-aos="fade-in">
    <div class="container">
      <div class="">
        <div class="text-center mt-3">
          <h2 >Simplify Rent Collection</h2>
          <p class="col-lg-6 mx-auto mt-3 text-white">Digitize rent payment and property management for seamless reconciliation, reporting, and communication with tenants.</p>
        </div>
        <div class="mt-5">
          <div class="d-flex align-items-center gap-5 text-center btns">
            <button @click.prevent="signIn"  class="button">Sign In</button>
            <button @click.prevent="signUp" class="button" >Sign Up</button>
          </div>
        </div>
        <div class="btns2 mt-5">
          <button class="button" > <a href="https://play.google.com/store/apps/details?id=com.sharasolutions.mreside" target="_blank" style="text-decoration:none; color:white;">Download M-reside Mobile App</a></button>
        </div>
      </div>
    </div>
  </section><!-- End Hero Section -->

  <section class="container" id="about">
    <div class="text-center mt-5 py-2">
      <h3>Why Choose M-reside Property Management Platform</h3>
    </div>
    <div class="row align-items-center">
      <div class="col-xs-12 col-md-6 col-lg-6 py-5">
        <div class="mt-lg-2">
          <div class="row align-items-center mb-lg-5">
            <div class="col-auto">
              <i class="fa fs-2 fa-clock-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <h4>M-reside Saves You Money and Time</h4>
              <span>
            M-reside gives you a Dashboard, It gives the state of your business in less than 3 Mins. <br>
            Reduced manual records save you time. <br>
            Fewer manhours needed to do reconciliation. <br>
          </span>
            </div>
          </div>
          <div class="row align-items-center mb-lg-5">
            <div class="col-auto">
              <i class="fa fs-2 fa-files-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <h4>Clean Payments Records</h4>
              <span>
            Every Payment transaction can be traced. <br>
            Get reports for ALL transactions. <br>
          </span>
            </div>
          </div>
          <div class="row align-items-center mb-lg-5">
            <div class="col-auto">
              <span class="fs-5 side-icon" style="font-weight: 700;">KSH</span>
            </div>
            <div class="col">
              <h4>M-reside Makes money for you</h4>
              <span>
            Repetitive jobs are automated giving you more time on marketing and business growth.  <br>
            Happy/satisfied Tenants pay more.  <br>
          </span>
            </div>
          </div>
          <div class="row align-items-center mb-lg-3">
            <div class="col-auto">
              <i class="fa fs-2 fa-window-close-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <h4>Don’t lose money to Theft </h4>
              <span>
            Each Payment transaction is unique. <br>
            Fake deposit receipts are easily busted.
          </span>
            </div>
          </div>
        </div>
      </div>
      <div  class="col-xs-12 col-md-6 col-lg-6 "><img src="@/assets/img/property-04.jpg" style="border-radius: 15px;" class="img-fluid"></div>
    </div>
  </section>

  <section class="container" id="value">
    <div class="text-center">
      <h3>M-reside Package Value</h3>
    </div>
    <div class="row align-items-center">
      <div class="col-xs-12 col-md-6 col-lg-6 ">
        <img src="@/assets/img/mreside.jpg" style="border-radius: 15px;" class="img-fluid">
      </div>
      <div class="col-xs-12 col-md-6 col-lg-6 py-5">
        <div class="mt-lg-2">
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Automated reconciliations</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Realtime reports- Performance dashboard</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Automated invoicing with detailed billing breakdown</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Customized Bulk SMS to pick unit balances/arrears</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Customized payment confirmation sent to customer</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Physical receipts available in PDF format</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Automated payment for tenants through m-reside app</p>
            </div>
          </div>
          <div class="row align-items-center mb-lg-">
            <div class="col-auto">
              <i class="fa fs-4 fa-check-square-o side-icon" aria-hidden="true"></i>
            </div>
            <div class="col">
              <p class="fs-5">Ready inbuilt tax computation module/eTIMS compliance tax invoices capability</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>


  <!-- Footer -->
  <footer class="text-center text-lg-start bg-body-tertiary text-muted border-top" id="contact">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4  ">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Get connected with us on social networks:</span>
      </div>
      <!-- Left -->

      <!-- Right -->
      <div class="">
        <a href="https://www.facebook.com/MresideKenya/" target="_blank" class="me-4 text-reset">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com/mresideKENYA" target="_blank" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>

        <a href="https://www.instagram.com/mresidedigital" target="_blank" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="#" class="me-4 text-reset">
          <i class="fab fa-linkedin"></i>
        </a>

      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">


      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <img src="@/assets/img/logo-ct.png" alt="">
            </h6>
            <!-- <p class="text-center">
              Simplify Rent Collection
            </p> -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Useful Links
            </h6>
            <p>
              <a @click.prevent="signIn" class="text-reset pointer">Sign In</a>
            </p>
            <p>
              <a @click.prevent="signUp" class="text-reset pointer">Sign Up</a>
            </p>
            <p>
              <a href="https://play.google.com/store/apps/details?id=com.sharasolutions.mreside" target="_blank" class="text-reset">Download Mobile App</a>
            </p>

          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              This Page links
            </h6>
            <p><a class="text-reset pointer" @click.prevent="demo" >Schedule a demo</a></p>
            <p>
              <a href="#about" class="text-reset">About Us</a>
            </p>
            <p>
              <a href="#value" class="text-reset">Package Value</a>
            </p>
            <p>
              <a href="#" class="text-reset">Home</a>
            </p>

          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p><i class="fas fa-home me-3"></i> Westlands, Commercial Center P.O Box 79930-00200 Nairobi Kenya</p>
            <p>
              <i class="fas fa-envelope me-3"></i>
              mreside@swifthub.co.ke
            </p>
            <p><i class="fas fa-phone me-3"></i> +254711852147</p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2024 Copyright:
      <a class="text-reset fw-bold" href="https://m-reside.com/">M-Reside Property Management</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->


  <demoForm
      class="topp"
      v-if="showModal"
      @close-modal="showModal = false"
  />



</template>

<script>
import {  ref } from "vue";
import demoForm from "@/components/Modals/property-manager/demoForm.vue"
//   import { ElNotification, ElMessage } from "element-plus";
import { useRouter } from "vue-router";

//   import { h } from "vue";


const body = document.getElementsByTagName("body")[0];


export default {
  components: {
    demoForm
  },

  setup(){
    const showModal = ref(false)

    const router = useRouter();
    const signIn = ()=>{
      router.push('/signin')
    }
    const signUp = ()=>{
      router.push('/signup')
    }

    const demo = ()=>{
      console.log('clicked')
      showModal.value = true
    }
    return{
      signIn,
      signUp,
      showModal,
      demo

    }
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
@import '@/assets/css/landing-page.css';
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
.pointer{
  cursor:pointer;
}
</style>
