import {api} from "@/api";

const property_manager_url = (root, filter, page) => {
    var url = root;

    if (filter.value !== undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=name:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value !== undefined && filter.value.propertyManager_name)
        url = url + "&propertyManager_name=" + filter.value.propertyManager_name;

    url = url.replace("admin/property-managers?", "admin/search/property-manager?");

    return url;
};

const property_manager_invoices_url = (root, filter, page) => {
    var url = root;

    if (filter.value !== undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=invoice_number:like;number_of_units:like;commisioned_amount:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    url = url.replace("admin/property-managers/invoices?", "admin/property-managers/invoices?");

    return url;
};

const property_url = (root, filter, page) => {
    var url = root;

    if (filter.value !== undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=propertyName:like;propertyAddress:like;propertyManagerId:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;


    if (filter.value !== undefined && filter.value.propertyManagerId)
        url = url + "&propertyManagerId=" + filter.value.propertyManagerId;

    if (filter.value !== undefined && filter.value.property_name)
        url = url + "&property_name=" + filter.value.property_name;

    url = url.replace("admin/paginated/properties?", "admin/paginated/properties?");

    return url;
};

export const fetchManagersData = async(filter, page) => {
    return await api
        .get(property_manager_url("admin/property-managers?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const fetchPropertyManager = async() => {
    let response = await api
        .get("admin/search/property-manager")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyManagersData = async() => {
    let response = await api
        .get("admin/property-manager")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyManagerDetails = async(id) => {
    return await api
        .get(`admin/property-managers/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const fetchManagerProperties = async(filter, page, id) => {
    return await api
        .get(property_url(`admin/property-managers/properties/${id}?`, filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};
export const fetchManagerUsers = async(id) => {
    return await api
        .get(`admin/property-managers/users/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};
export const fetchManagerInvoices = async(filter, page, id) => {
    return await api
        .get(property_manager_invoices_url(`admin/property-managers/invoices/${id}?`, filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const updatePropertyManager = async(id, postBody) => {
    return await api
        .post("admin/property-managers/" + id, postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const updatePropertyManagerSenderId = async(id, postBody) => {
    return await api
        .post("admin/property-managers/sender-id" + id, postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const updatePropertyManagerMpesaCredentials = async(id, postBody) => {
    return await api
        .post("admin/property-managers/mpesa-credentials/" + id, postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const removePropertyManager = async(id) => {
    return await api
        .delete(`admin/property-managers/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
}

export const fetchManagerRoles = async (id) => {
    return await api
        .get(`admin/property-managers/permissions/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response);
};

export const smsParam = async(postBody) => {
    let response = await api
        .post("admin/sms-param", postBody)
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};
