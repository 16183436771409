<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid mt-6" v-else>
    <div class="card shadow-lg mb-4">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-lg bg-gradient-primary">
              <h4 class="text-white">{{ getInitials( user?.firstName  + " " + user?.lastName ) }}</h4>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user?.firstName  + " " + user?.lastName }}</h5>
              <p class="mb-0 font-weight-bold text-sm">Managerial User</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <el-form
            ref="profileFormRef"
            :model="profileForm"
            label-width="auto"
            class="demo-ruleForm"
            size="large"
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">User Information</p>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Name" prop="first_name">
                    <el-input v-model="profileForm.first_name" disabled/>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="profileForm.email" disabled/>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                <el-form-item label="Phone" prop="created_at">
                  <el-input v-model="profileForm.phone" disabled />
                </el-form-item>
              </div>
                <div class="col-md-6">
                  <el-form-item label="Role" prop="role">
                    <el-input v-model="profileForm.role" disabled />
                  </el-form-item>
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
        </el-form>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="text-uppercase text-sm">Change Password</p>
            </div>
          </div>
          <div class="card-body">
            <el-form
                ref="changePasswordFormRef"
                :model="changePasswordForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                status-icon
                :scroll-to-error="true"
                label-position="top"
                :hide-required-asterisk="true"
            >
              <el-form-item label="New Password" prop="new_password">
                <el-input
                    v-model="changePasswordForm.new_password"
                    type="password"
                    :show-password="true"/>
              </el-form-item>
              <el-form-item label="Confirm Password" prop="confirm_password">
                <el-input
                    v-model="changePasswordForm.confirm_password"
                    type="password"
                    :show-password="true"
                />
              </el-form-item>

              <el-button
                  type="success"
                  class="px-5 d-block mt-4 mx-auto"
                  :loading="isLoading"
                  @click="submitForm(changePasswordFormRef)"
              >
                Change Password
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase text-sm">User Activities Log</p>
            <logs-table
                :logs="logs"
                :refresh="fetchLogs"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import setNavPills from "../../../assets/js/nav-pills.js";
import setTooltip from "../../../assets/js/tooltip.js";
import Loader from "../../components/Loader.vue"
import { reactive, ref, onMounted, watch } from "vue";
import moment from "moment";
import { viewManagerialUser, fetchManagerialUserLogs } from "../../../api/property-manager/managerialUsers";
import {changePassword} from "../../../api/services/authentication";
import {ElNotification} from "element-plus";
import LogsTable from "./LogsTable.vue";

const body = document.getElementsByTagName("body")[0];
const profileFormRef = ref();
const searchTerm = ref();
const user = ref({});
const logs = ref({});
const isLoading = ref(false);
const landlord = ref(false);
const isUpdatingProfile = ref(false);
const showMenu = ref(false);
const changePasswordFormRef = ref();
const user_id = ref(null);

export default {
  name: "landlord profile",
  components: {LogsTable, Loader },
  props: {
    landlord_id: {
      required: true,
    },
  },
  setup(props) {

    const profileForm = reactive({
      email: "",
      phone: "",
      address: "",
      first_name: "",
      last_name: "",
      created_at: "",
      role: "",
    });
    const fetchDetails = async () => {
      isLoading.value = true;
      try {
        const response = await viewManagerialUser(props.landlord_id);
        let data = response.data;
        user.value = response.data;

        profileForm.first_name = data.firstName  + " " + data.lastName  || "Unavailable";
        profileForm.email = data.email || "Unavailable";
        profileForm.phone = data.primaryPhone || "Unavailable";
        profileForm.user_id = data.user_id;
        profileForm.address = data.phy_add || "Unavailable";
        profileForm.role = "Managerial User";
        profileForm.created_at = moment(data.created_on).format("DD/MM/YYYY") || "Unavailable";

        user_id.value = data.user_id;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchLogs = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchManagerialUserLogs(user_id.value, search, page);
        logs.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    watch(user_id, (newUserId) => {
      const search = "";
      const page = 1;

      fetchLogs(newUserId, search, page);
    });

    onMounted(() => {
      fetchDetails();
    });
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const changePasswordForm = reactive({
      new_password: "",
      confirm_password: "",
    });

    const rules = reactive({
      new_password: [
        {
          required: true,
          message: "Please input your new password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          required: true,
          message: "Please confirm your password",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("new_password", changePasswordForm.new_password);
          formData.append("new_password_confirmation", changePasswordForm.confirm_password);
          changePassword(formData)
              .then((data) => {
                console.log(data.data.message)
                if (data.data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                } else {
                  Object.values(data.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  }),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      showMenu,
      landlord,
      logs,
      fetchDetails,
      fetchLogs,
      profileForm,
      user,
      getInitials,
      isLoading,
      profileFormRef,
      resetForm,
      isUpdatingProfile,
      changePasswordForm,
      changePasswordFormRef,
      submitForm,
      rules
    };
  },

  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
