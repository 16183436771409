<template>
  <div>
    <body>
      <svg
        class="house"
        viewBox="0 0 200 160"
        width="200px"
        height="160px"
        role="img"
        aria-label="Animated outline drawing of a house spinning"
      >
        <g
          fill="none"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(30,0)"
        >
          <polyline class="house__roof1" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="70 1,139 70;70 1,1 70;70 1,-27 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof2" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="70 1,139 70;70 1,167 70;70 1,139 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof-edge" points="1 70,139 70">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="1 70,139 70;-27 70,167 70;1 70,139 70;-27 70,167 70;1 70,139 70"
            />
          </polyline>
          <polyline class="house__edge1" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139 70,139 159;1 70,1 159;-27 70,-27 159;1 70,1 159"
            />
          </polyline>
          <polyline class="house__edge2" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="139 70,139 159;167 70,167 159;139 70,139 159;1 70,1 159"
            />
          </polyline>
          <rect class="house__left-window" x="20" y="85" width="40" height="32">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;84;20;-13;1"
            />
          </rect>
          <rect
            class="house__right-window"
            x="80"
            y="85"
            width="40"
            height="32"
          >
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;127;80;29;1"
            />
          </rect>
          <rect class="house__door1" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="40;28;0;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="50;7;1;1"
            />
          </rect>
          <rect class="house__door2" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="0;0;28;40"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139;139;105;50"
            />
          </rect>
          <polygon
            class="house__outline"
            points="70 1,139 70,139 159,1 159,1 70"
          >
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70"
            />
          </polygon>
        </g>
      </svg>
      <svg
        class="house"
        viewBox="0 0 200 160"
        width="200px"
        height="160px"
        role="img"
        aria-label="Animated outline drawing of a house spinning"
      >
        <g
          fill="none"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(30,0)"
        >
          <polyline class="house__roof1" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="70 1,139 70;70 1,1 70;70 1,-27 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof2" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="70 1,139 70;70 1,167 70;70 1,139 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof-edge" points="1 70,139 70">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="1 70,139 70;-27 70,167 70;1 70,139 70;-27 70,167 70;1 70,139 70"
            />
          </polyline>
          <polyline class="house__edge1" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139 70,139 159;1 70,1 159;-27 70,-27 159;1 70,1 159"
            />
          </polyline>
          <polyline class="house__edge2" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="139 70,139 159;167 70,167 159;139 70,139 159;1 70,1 159"
            />
          </polyline>
          <rect class="house__left-window" x="20" y="85" width="40" height="32">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;84;20;-13;1"
            />
          </rect>
          <rect
            class="house__right-window"
            x="80"
            y="85"
            width="40"
            height="32"
          >
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;127;80;29;1"
            />
          </rect>
          <rect class="house__door1" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="40;28;0;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="50;7;1;1"
            />
          </rect>
          <rect class="house__door2" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="0;0;28;40"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139;139;105;50"
            />
          </rect>
          <polygon
            class="house__outline"
            points="70 1,139 70,139 159,1 159,1 70"
          >
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70"
            />
          </polygon>
        </g>
      </svg>
      <svg
        class="house"
        viewBox="0 0 200 160"
        width="200px"
        height="160px"
        role="img"
        aria-label="Animated outline drawing of a house spinning"
      >
        <g
          fill="none"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(30,0)"
        >
          <polyline class="house__roof1" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="70 1,139 70;70 1,1 70;70 1,-27 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof2" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="70 1,139 70;70 1,167 70;70 1,139 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof-edge" points="1 70,139 70">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="1 70,139 70;-27 70,167 70;1 70,139 70;-27 70,167 70;1 70,139 70"
            />
          </polyline>
          <polyline class="house__edge1" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139 70,139 159;1 70,1 159;-27 70,-27 159;1 70,1 159"
            />
          </polyline>
          <polyline class="house__edge2" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="139 70,139 159;167 70,167 159;139 70,139 159;1 70,1 159"
            />
          </polyline>
          <rect class="house__left-window" x="20" y="85" width="40" height="32">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;84;20;-13;1"
            />
          </rect>
          <rect
            class="house__right-window"
            x="80"
            y="85"
            width="40"
            height="32"
          >
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;127;80;29;1"
            />
          </rect>
          <rect class="house__door1" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="40;28;0;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="50;7;1;1"
            />
          </rect>
          <rect class="house__door2" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="0;0;28;40"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139;139;105;50"
            />
          </rect>
          <polygon
            class="house__outline"
            points="70 1,139 70,139 159,1 159,1 70"
          >
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70"
            />
          </polygon>
        </g>
      </svg>
      <svg
        class="house"
        viewBox="0 0 200 160"
        width="200px"
        height="160px"
        role="img"
        aria-label="Animated outline drawing of a house spinning"
      >
        <g
          fill="none"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(30,0)"
        >
          <polyline class="house__roof1" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="70 1,139 70;70 1,1 70;70 1,-27 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof2" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="70 1,139 70;70 1,167 70;70 1,139 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof-edge" points="1 70,139 70">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="1 70,139 70;-27 70,167 70;1 70,139 70;-27 70,167 70;1 70,139 70"
            />
          </polyline>
          <polyline class="house__edge1" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139 70,139 159;1 70,1 159;-27 70,-27 159;1 70,1 159"
            />
          </polyline>
          <polyline class="house__edge2" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="139 70,139 159;167 70,167 159;139 70,139 159;1 70,1 159"
            />
          </polyline>
          <rect class="house__left-window" x="20" y="85" width="40" height="32">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;84;20;-13;1"
            />
          </rect>
          <rect
            class="house__right-window"
            x="80"
            y="85"
            width="40"
            height="32"
          >
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;127;80;29;1"
            />
          </rect>
          <rect class="house__door1" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="40;28;0;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="50;7;1;1"
            />
          </rect>
          <rect class="house__door2" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="0;0;28;40"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139;139;105;50"
            />
          </rect>
          <polygon
            class="house__outline"
            points="70 1,139 70,139 159,1 159,1 70"
          >
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70"
            />
          </polygon>
        </g>
      </svg>
      <svg
        class="house"
        viewBox="0 0 200 160"
        width="200px"
        height="160px"
        role="img"
        aria-label="Animated outline drawing of a house spinning"
      >
        <g
          fill="none"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(30,0)"
        >
          <polyline class="house__roof1" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="70 1,139 70;70 1,1 70;70 1,-27 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof2" points="70 1,70 95">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="70 1,139 70;70 1,167 70;70 1,139 70;70 1,1 70"
            />
          </polyline>
          <polyline class="house__roof-edge" points="1 70,139 70">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="1 70,139 70;-27 70,167 70;1 70,139 70;-27 70,167 70;1 70,139 70"
            />
          </polyline>
          <polyline class="house__edge1" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139 70,139 159;1 70,1 159;-27 70,-27 159;1 70,1 159"
            />
          </polyline>
          <polyline class="house__edge2" points="139 70,139 159">
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="139 70,139 159;167 70,167 159;139 70,139 159;1 70,1 159"
            />
          </polyline>
          <rect class="house__left-window" x="20" y="85" width="40" height="32">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;84;20;-13;1"
            />
          </rect>
          <rect
            class="house__right-window"
            x="80"
            y="85"
            width="40"
            height="32"
          >
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="0;28;40;28;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;0.75;1"
              values="139;127;80;29;1"
            />
          </rect>
          <rect class="house__door1" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="40;28;0;0"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;0.5;1"
              values="50;7;1;1"
            />
          </rect>
          <rect class="house__door2" x="50" y="110" width="40" height="49">
            <animate
              attributeName="width"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="0;0;28;40"
            />
            <animate
              attributeName="x"
              dur="2s"
              repeatCount="indefinite"
              keyTimes="0;0.5;0.75;1"
              values="139;139;105;50"
            />
          </rect>
          <polygon
            class="house__outline"
            points="70 1,139 70,139 159,1 159,1 70"
          >
            <animate
              attributeName="points"
              dur="2s"
              repeatCount="indefinite"
              values="70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70;70 1,167 70,167 159,-27 159,-27 70;70 1,139 70,139 159,1 159,1 70"
            />
          </polygon>
        </g>
      </svg>
    </body>
  </div>
</template>

<style scoped>
* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}
body {
  background-color: var(--bg);
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
  color: var(--fg);
  display: flex;
  font: 1em/1.5 sans-serif;
  height: 100vh;
  transition: background-color var(--trans-dur), color var(--trans-dur);
}
.house {
  display: block;
  margin: auto;
  width: 16em;
  height: auto;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}
</style>
<script setup></script>
