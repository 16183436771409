<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <admin-invoices-payments-table
            :invoice_payments="invoice_payments"
            :refresh="fetchData"
            :searchQuery="searchTerm"
            v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdminInvoicesPaymentsTable from "../components/admin/AdminInvoicesPaymentsTable.vue";
import Loader from "../components/Loader.vue";
import { fetchInvoicesPayments } from "../../api/admin/invoices";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const invoice_payments = ref([]);
const searchTerm = ref("");
export default {
  name: "invoice_payments",
  components: {
    AdminInvoicesPaymentsTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
        let entity_params = {};
        isLoading.value = true;
        try {
          if (filter === undefined) {
            entity_params = ref({});
          } else {
            entity_params = filter;
          }
        const response = await fetchInvoicesPayments(entity_params, page);
        invoice_payments.value = response.data;
        console.log(response.data)
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      invoice_payments,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>
