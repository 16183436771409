<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading"><Loader /></div>
          <admin-properties-table
            :properties="properties"
            :refresh="fetchData"
            :searchQuery="searchTerm"
            :propertyManager="propertyManager"
            v-else
          />
        </div>
      </div>
    </div> 
  </template>
  
  <script>
  import AdminPropertiesTable from "../components/admin/AdminPropertiesTable.vue";
  import Loader from "../components/Loader.vue";
  import { fetchPaginatedManagersProperties } from "../../api/admin/properties";
  import { ElLoading } from "element-plus";
  import { ref } from "vue";
  
  
  const isLoading = ref(false);
  const propertyManager = ref(true);
  const properties = ref([]);
  const searchTerm = ref("");
  export default {
    name: "admin properties",
    components: {
      AdminPropertiesTable,
      Loader,
    },
    setup() {
      const fetchData = async (filter, page) => {
        let entity_params = {};
        isLoading.value = true;
        try {
          if (filter === undefined) {
            entity_params = ref({});
          } else {
            entity_params = filter;
          }
          const response = await fetchPaginatedManagersProperties(entity_params, page);
          properties.value = response.data;
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };
  
      const fullScreenLoader = () => {
        ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      };
  
      return {
        fullScreenLoader,
        fetchData,
        properties,
        isLoading,
        searchTerm,
        propertyManager,
       
      };
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  