<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg'); background-position: top;"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h3 class="text-white mb-2 mt-5">Simplify Rent Collection</h3>
            <p
              class="text-lead text-white"
            >Digitize rent payment and property management for seamless reconciliation, reporting, and communication with tenants.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-body">
                  <h4 class="font-weight-bolder">Page Not Found</h4>
                  <p>The page you're looking for doesn't exist.</p>
            </div>
            <div class="px-1 pt-0 text-center card-footer px-lg-2">
              <p class="mx-auto mb-4 text-sm">
                <router-link
                  to="/signin/"
                  class="text-success text-gradient font-weight-bold"
                  >Sign In</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template> 
 
<script>
  import { useRoute } from "vue-router";
  import Navbar from "@/components/PageLayout/Navbar.vue";
  import AppFooter from "@/components/PageLayout/Footer.vue";

const body = document.getElementsByTagName("body")[0];
export default {
  name: "welcome",
  components: {
    Navbar,
    AppFooter,
  },
  setup() {
    const route = useRoute();

    return { 
      route,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>