import {api} from "../";

const property_url = (root) => {
    var url = root;
        url = url.replace("managers/paginated/properties?", "managers/paginated/properties?");
    return url;
};

export const fetchProperties = async() => {
    let response = await api
        .get("properties")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLandlordsProperties = async() => {
    let response = await api
        .get("landlord/properties")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPaginatedManagersProperties = async(filter, page) => {
    return await api
        .get(property_url("landlord/paginated/properties?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const fetchPropertyDetails = async(id) => {
    let response = await api
        .get(`properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createProperty = async(postBody) => {
    let response = await api
        .post("properties", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateProperty = async(body) => {
    let response = await api
        .patch("properties/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changePropertyStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/property`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeProperty = async(id) => {
    let response = await api
        .delete(`properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const totalExpectedAmount = async(filter, page, id) => {
    let response = await api
        .get("total-expected-amount?id="+id, filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const totalMonthBalance = async(filter, page, id) => {
    let response = await api
        .get("total-month-property-balance?id="+id, filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

