import { api } from "@/api";

const payment_url = (root, filter) => {
  var url = root;

  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=unitCode:like;thirdpartyPaymentRef:like;amountPaid:like;source:like;&search=" +
      filter.value.search_query;

  if (filter.value != undefined && filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;

  if (filter.value != undefined && filter.value.tenant)
    url = url + "&leaseId=" + filter.value.tenant;

  if (filter.value != undefined && filter.value.reference_code)
    url = url + "&thirdpartyPaymentRef=" + filter.value.reference_code;

  if (filter.value != undefined && filter.value.payment_source)
    url = url + "&source=" + filter.value.payment_source;

  if (filter.value != undefined && filter.value.unit_code)
    url = url + "&unitCode=" + filter.value.unit_code;

  if (filter.value != undefined && filter.value.propertyId)
    url = url + "&propertyId=" + filter.value.propertyId;

  if (filter.value != undefined && filter.value.allocation_status)
    url = url + "&allocationStatus=" + filter.value.allocation_status;

  return url;
};

const tenant_url = (root, filter) => {
  var url = root;

  if (filter.value != undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=firstName:like;lastName:like;email:like;primaryPhone:like;&search=" +
      filter.value.search_query;

  if (filter.value != undefined && filter.value.tenant_email)
    url = url + "&tenant_email=" + filter.value.tenant_email;

  if (filter.value != undefined && filter.value.tenant_name)
    url = url + "&tenant_name=" + filter.value.tenant_name;

  if (filter.value != undefined && filter.value.propertyId)
    url = url + "&propertyId=" + filter.value.propertyId;

  if (filter.value != undefined && filter.value.lease_status)
    url = url + "&lease_status=" + filter.value.lease_status;

  return url;
};

const units_url = (root, filter, id) => {
  var url = root;

  if (filter.value != undefined && filter.value.rental_rate)
    url = url + "&hoaFee=" + filter.value.rental_rate;

  if (filter.value != undefined && filter.value.unit_code)
    url = url + "&unitCode=" + filter.value.unit_code;

  if (filter.value != undefined && filter.value.lease_status)
    url = url + "&isLeased=" + filter.value.lease_status;

  url = url.replace(`property/units/${id}?`, `property/units/${id}?`);

  return url;
};

export const downloadPaymentsPdf = async (filter) => {
  let response = await api
    .get(payment_url("payments-pdf?", filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadReceiptPdf = async (id) => {
  return await api
    .get(`print/receipt/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadPaymentsExcel = async (filter) => {
  let response = await api
    .get(payment_url("payments-excel?", filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadPropertyUnitsPdf = async (filter, id) => {
  let response = await api
    .get(units_url(`property-units-pdf/${id}?`, filter, id), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadPropertyUnitsExcel = async (filter, id) => {
  let response = await api
    .get(units_url(`property-units-excel/${id}?`, filter, id), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const managerDownloadAccountingReportPdf = async (body) => {
  let response = await api
    .post("manager-accounting-report-pdf", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const managerDownloadAccountingReportExcel = async (body) => {
  let response = await api
    .post("manager-accounting-report-excel", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const managerDownloadPaymentReportPdf = async (body) => {
  let response = await api
    .post("manager-payment-report-pdf", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const managerDownloadPaymentReportExcel = async (body) => {
  let response = await api
    .post("manager-payment-report-excel", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const managerDownloadLeaseReportPdf = async (body) => {
  let response = await api
    .post("manager-lease-report-pdf", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const managerDownloadLeaseReportExcel = async (body) => {
  let response = await api
    .post("manager-lease-report-excel", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const sampleDownload = async (name, propertyId) => {
  return await api
    .get(`sample-downloads?name=${name}&propertyId=${propertyId}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadTenantsPdf = async (filter) => {
  let response = await api
    .get(tenant_url("tenants-pdf?", filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadTenantsExcel = async (filter) => {
  let response = await api
    .get(tenant_url("tenants-excel?", filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadExpectedPdf = async (filter, id) => {
  let response = await api
    .get(tenant_url("expected-amount-pdf?id=" + id, filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadExpectedExcel = async (filter, id) => {
  let response = await api
    .get(tenant_url("expected-amount-excel?id=" + id, filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadMonthBalancePdf = async (filter, id) => {
  let response = await api
    .get(tenant_url("month-balance-pdf?id=" + id, filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadMonthBalanceExcel = async (filter, id) => {
  let response = await api
    .get(tenant_url("month-balance-excel?id=" + id, filter), {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadInvoicePdf = async (id) => {
  return await api
    .get(`/print-tenant-invoice/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadCreditNotePdf = async (id) => {
  return await api
    .get(`/print-tenant-credit-note/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadPaymentInvoicePdf = async (id) => {
  return await api
    .get(`/print-tenant-payment-invoice/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadManagerInvoicePdf = async (id) => {
  return await api
    .get(`/print-manager-invoice/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
};


export const managerTenantStatementPdf = async (body) => {
  let response = await api
    .post("manager-tenant-statement-pdf", body, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
