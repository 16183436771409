<template>
    <div class="card container-fluid">
      <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
        <template #icon>
          <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
        </template>
      </el-page-header>
      <el-form
          ref="accountFormRef"
          :model="accountForm"
          label-width="100px"
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
          <div class="card-header pb-0 mb-5">
            <div class="d-flex align-items-center gap-2">
              <i class="fa fa-line-chart text-primary text-md opacity-10"></i>
              <h6>Tenant Statement</h6>
            </div>
            <el-button-group>
              <el-button
                  type="warning"
                  @click="downloadPdf(accountFormRef)"
              >
                <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; Download PDF
              </el-button>
            </el-button-group>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="row mb-6">
              <div class="col-md-6 card-body2">
                <el-card>
                  <el-input v-model="searchText" placeholder="Search tenant or unit code" clearable @clear="clearSearch"></el-input>
                  <div v-for="(unit, index) in filteredUnits" :key="index">
                    <div class="d-flex gap-2 align-items-center">
                      <el-checkbox id="check" style="font-size: 14px;" :checked="isSelected(unit.unitId)"
                       @change="toggleSelection(unit.unitId)"></el-checkbox>
                      <div style="font-size: 14px;">{{ unit.leases[0]?.tenant?.firstName }}</div>
                      <div style="font-size: 14px;">{{ unit.leases[0]?.tenant?.lastName }}</div>
                      <div>-</div>
                      <div style="font-size: 14px;">{{ unit.unitCode }}</div>
                    </div>
                  </div>
                </el-card>
              </div>
             
              <div class="col-md-4">
                <div class="demo-date-picker">
                  <el-date-picker
                      v-model="accountForm.dateRange"
                      type="daterange"
                      unlink-panels
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                      :shortcuts="shortcuts"
                  />
                </div>
              </div>
            
        </div>
          </div>
      </el-form>
    </div>
  </template>
  
  <script>
  
  import {reactive, ref, computed, watch} from 'vue'
  import {fetchLeasedManagerUnits} from "../../../api/property-manager/units";
  import router from "@/router";
  import {ElMessage} from "element-plus";
  import {
    managerDownloadAccountingReportExcel,
    managerTenantStatementPdf
  } from "../../../api/property-manager/downloads";
  
  const units = ref([]);
  const isLoading = ref(false);
  const accountFormRef = ref();
  
  export default {
    name: "accounts-view",
    setup() {
      const units = ref([]);
      const data = ref([]);
      const rightValue = ref([]);
      const leftValue = ref([]);
      const selectedUnitIds = ref([]);
  
      const accountForm = reactive({
        selectedUnits: [],
        dateRange: [],
        selectedRadio: 'Agent'
      });
  
      const renderFunc = (h, option) => {
        return h('span', null, option.label);
      };
  
      const handleChange = (value, direction, movedKeys) => {
        console.log(value, direction, movedKeys);
      };
  
      const generateData = (units) => {
        const data = units.map((unit) => ({
          key: unit.value,
          label: unit.label,
          disabled: false,
        }));
        return data;
      };
      const fetchunitsList = async () => {
        try {
          const response = await fetchLeasedManagerUnits();
          units.value = response.data; 
          data.value = generateData(units.value);
        } catch (error) {
          console.log(error);
        }
      };
      fetchunitsList();
  
      const shortcuts = [
        {
          text: 'Last week',
          value: function() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          }
        },
        {
          text: 'Last month',
          value: function() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          }
        },
        {
          text: 'Last 3 months',
          value: function() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          }
        }
      ];
      const goBack = () => {
        router.go(-1);
      }

      const otherCheckboxSelected = (theList) =>{
      return theList.length > 1;
    }

    watch(selectedUnitIds.value, (newValue, oldValue )=>{
      console.log(oldValue)
      otherCheckboxSelected(newValue)
    })

      const isSelected = (unitId) => {
      return selectedUnitIds.value.includes(unitId);
    };

    const toggleSelection = (unitId) => {
      const index = selectedUnitIds.value.indexOf(unitId);
      if (index === -1) {
        selectedUnitIds.value.push(unitId);
      } else {
        selectedUnitIds.value.splice(index, 1);
      }
    };
  
      const downloadPdf = async () => {
        try {
          ElMessage({
            showClose: true,
            type: "success",
            message: "Your report will be downloading shortly. Please wait...",
          });
          const formData = new FormData();
          formData.append('date', accountForm.dateRange.join(' - '));
          formData.append('unitIds', selectedUnitIds.value);
          formData.append('type', accountForm.selectedRadio);
          formData.append('format', "pdf");
          await managerTenantStatementPdf(formData)
              .then((Response) => {
                let fileURL = window.URL.createObjectURL(
                    new Blob([Response], {
                      type: "application/pdf",
                    })
                );
                let fileLink = document.createElement("a");
  
                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "accounting_" + Math.round(+new Date() / 1000) + ".pdf"
                );
                document.body.appendChild(fileLink);
  
                fileLink.click();
              })
              .catch((err) => {
                console.log(err);
  
                ElMessage({
                  type: "error",
                  message: "Whoops! something went wrong downloading report",
                });
              });
        } catch (error) {
          console.log(error);
          ElMessage({
            type: "error",
            message: "Whoops! something went wrong downloading report",
          });
        } finally {
          isLoading.value = false;
        }
      };
  
      const submitExcelForm = async () => {
        try {
          ElMessage({
            showClose: true,
            type: "success",
            message: "Your report will be downloading shortly. Please wait...",
          });
          isLoading.value = true;
          const formData = new FormData();
          formData.append('date', accountForm.dateRange.join(' - '));
          formData.append('unitIds', accountForm.selectedUnits.join(','));
          formData.append('type', accountForm.selectedRadio);
          formData.append('format', "excel");
          await managerDownloadAccountingReportExcel(formData)
              .then((Response) => {
                let fileURL = window.URL.createObjectURL(
                    new Blob([Response], {
                      type: "application/vnd.ms-excel",
                    })
                );
                let fileLink = document.createElement("a");
  
                fileLink.href = fileURL;
                fileLink.setAttribute(
                    "download",
                    "accounting_" + Math.round(+new Date() / 1000) + ".xlsx"
                );
                document.body.appendChild(fileLink);
  
                fileLink.click();
              })
              .catch((err) => {
                console.log(err);
                ElMessage({
                  type: "error",
                  message: "Whoops! something went wrong downloading report",
                });
              });
        } catch (error) {
          console.log(error);
          ElMessage({
            type: "error",
            message: "Whoops! something went wrong downloading report",
          });
        } finally {
          isLoading.value = false;
        }
      };

      const searchText = ref('');

      const filteredUnits = computed(() => {
      const lowerSearchText = searchText.value.toLowerCase();
      return units.value.filter(unit => {
        const unitCodeMatch = unit.unitCode.toLowerCase().includes(lowerSearchText);
        const tenantMatchFirstName = unit.leases[0]?.tenant?.firstName.toLowerCase().includes(lowerSearchText)
        const tenantMatchLastName = unit.leases[0]?.tenant?.lastName.toLowerCase().includes(lowerSearchText)
       
        return unitCodeMatch || tenantMatchFirstName || tenantMatchLastName;
      });
    });

    const clearSearch = () => {
      searchText.value = '';
    };

      return {
        handleChange,
        renderFunc,
        data,
        shortcuts,
        leftValue,
        rightValue,
        goBack,
        downloadPdf,
        accountForm,
        submitExcelForm,
        accountFormRef,
        searchText,
        filteredUnits,
        clearSearch,
        isSelected,
        toggleSelection,
        otherCheckboxSelected
      };
    },
  
    created() {
      this.fetchunitsList();
    },
    methods: {
      async fetchunitsList() {
        try {
          const response = await fetchLeasedManagerUnits();
  
          units.value = response.data
          console.log(units)
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .transfer-footer {
    margin-left: 15px;
    padding: 6px 5px;
  }
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }
  .demo-date-picker {
    display: flex;
    width: 70%;
    padding: 0;
    flex-wrap: wrap;
  }
  
  .demo-date-picker .block {
    padding: 30px 0;
    text-align: center;
    border-right: solid 1px var(--el-border-color);
    flex: 1;
  }
  
  .demo-date-picker .block:last-child {
    border-right: none;
  }
  
  .card-body {
    max-height: 50vh;
    
  }
  
  .demo-date-picker .demonstration {
    display: block;
    color: var(--el-text-color-secondary);
    font-size: 14px;
    margin-bottom: 20px;
  }
  h6 {
    margin: 0;
  }
  .card-body2{
    max-height: 50vh;
    overflow-y: auto;
  }
  </style>
  