import {api} from "../..";

export const login = async(postBody) => {
    return await api
        .post("auth/login", postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const logout = async(postBody) => {
    return await api
        .post("auth/logout", postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const signup = async(postBody) => {
    return await api
        .post("auth/register", postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const sendLink = async(postBody) => {
    return await api
        .post("auth/forgot-password", postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const resetPassword = async(postBody, token) => {
    return await api
        .post(`auth/reset-password/${token}`, postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const verifyEmail = async(postBody, token) => {
    return await api
        .post(`auth/verify/${token}`, postBody)
        .then((response) => response)
        .catch((e) => e.response);
};

export const resetPasswordConfirmation = async(postBody, token) => {
    return await api
        .post(`auth/reset-password-confirmation/${token}`, postBody)
        .then((response) => response)
        .catch((e) => e.response);
};
export const changePassword = async(postBody) => {
    return await api
        .post("auth/update/password", postBody)
        .then((response) => response)
        .catch((e) => e.response);
};

export const fetchRoles = async () => {
    return await api
        .get("roles?orderBy=name&sortedBy=asc")
        .then((response) => response.data)
        .catch((e) => e.response);
};
export const fetchPermissions = async () => {
    return await api
        .get("permissions?orderBy=name&sortedBy=asc")
        .then((response) => response.data)
        .catch((e) => e.response);
};