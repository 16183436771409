<template>
    <div class="card h-100 mb-4">
      <div class="card-header pb-0 px-3">
        <div class="row">
          <div class="col-md-6 d-flex justify-content-end align-items-center">
            <h6 class="mb-0">SMS BALANCE {{ sms_balance }}</h6><br>
            <button class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button" @click="buySms">
              <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;
                BUY SMS
              </button>
          </div>
          <div class="col-md-6 d-flex justify-content-end align-items-center">
            <h6>
              <i class="far fa-calendar-alt me-2" aria-hidden="true"></i>
              {{ currentTime }}
            </h6>
          </div>
        </div>
      </div>
      <div class="pt-4 p-3">
        <PaymentsTable
            :payments="payments"
            :refresh="refreshPayments"
            :isNotDashboard="isNotDashboard"
        />
      </div>
    </div>
    <BuySmsForm
        v-if="showModal"
        @close-modal="showModal = false"
        :payment_code="payment_code"
        @refresh="refresh"
    />
  </template>
  
  <script>
  import {ref} from "vue";
  import PaymentsTable from "./property-manager/PaymentsTable.vue";
  import BuySmsForm from "../../components/Modals/property-manager/BuySmsForm.vue";

  const showModal = ref(false);
  export default {
    name: "dashboard-card",
    components: {
      BuySmsForm,
      PaymentsTable
    },
    data() {
      return {
        currentTime: new Date().toLocaleString(),
      };
    },
    props: {
      sms_balance: {
        type: String,
        required: true,
      },
      payment_code: {
        type: String,
        required: true,
      },
      refresh: {
        type: Function,
        required: true,
      },
      refreshPayments: {
        type: Function,
        required: true,
      },
      payments: {
        type: String,
        required: true,
      },
      isNotDashboard: {
        type: Boolean,
        required: true,
      }
    },
    setup() {
      const buySms = () => {
        showModal.value = true;
      }

      return{
        buySms,
        showModal
      }
    },
    created() {
      setInterval(() => {
        this.currentTime = new Date().toLocaleString();
      }, 1000);
    }
  };
  </script>
  <style lang="scss" scoped>
  
  </style>