<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-credit-card text-primary text-md opacity-10"></i>
        <h6>Invoices</h6>
      </div>
      <div class="cta">
        <el-popover
          placement="bottom"
          title="Filter Cases"
          :width="800"
          trigger="click"
        >
          <template #reference>
            <el-button class="m-2 btn-xs null null ms-auto" type="primary">
              <i class="fa fa-filter me-1" aria-hidden="true"></i>&nbsp;Filter
            </el-button>
          </template>
          <el-form style="margin-bottom: 20px">
            <div>
              <hr />
            </div>
            <div class="row">
              <div class="col-sm-3 col-md-4">
                <label for="status" class="form-control-label"
                  >Transaction Reference</label
                >
                <el-form-item class="class-body">
                  <el-input></el-input>
                </el-form-item>
              </div>
              <div class="col-sm-3 col-md-4">
                <label for="status" class="form-control-label"
                  >Payment Source</label
                >
                <el-form-item class="class-body">
                  <el-input></el-input>
                </el-form-item>
              </div>
              <div class="col-sm-3 col-md-4">
                <label for="status" class="form-control-label"
                  >Date Range</label
                >
                <el-form-item prop="date_range">
                  <el-date-picker
                    v-model="paymentForm.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <el-button
                  type="secondary"
                  class="col-md-6 custom-green-button"
                  style="width: 100%"
                  >Filter</el-button
                >
              </div>
              <div class="col-md-6" v-if="hasPermission('manage.invoices')">
                <el-button type="danger" class="col-md-6" style="width: 100%"
                  >Cancel</el-button
                >
              </div>
            </div>
          </el-form>
        </el-popover>
      </div>
    </div>
    <div class="card-body">
      <div v-if="invoices.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Invoice Date
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Invoice Number
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Number Of Units
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Sub Total
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Total
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Status
                </th>

                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in invoices.data" :key="item.id + index">
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ dateTime(item.created_at) }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.invoice_number }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatNumber(item.number_of_units) }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatNumber(item.commisioned_amount) }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatNumber(item.total_amount) }}
                  </p>
                </td>

                <td class="text-xs font-weight-bold mb-0">
                  <span
                    class="badge badge-sm"
                    :class="getStatusColor(parseInt(item.status))"
                    >{{ parseInt(item.status) === 0 ? "Unpaid" : "Paid" }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          @click="payInvoice(item)"
                          ><i
                            class="fa fa-cc-diners-club"
                            aria-hidden="true"
                          ></i>
                          Pay Invoice
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="hasPermission('print.invoices')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="printInvoicePdf(item)"
                          ><i class="fa fa-id-badge" aria-hidden="true"></i>
                          Print Invoice
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
          :totalPages="Pagination.total_pages"
          :perPage="Pagination.per_page"
          :currentPage="Pagination.current_page"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
  <vue3-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1500"
    filename="receipt.pdf"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    :html-to-pdf-options="htmlToPdfOptions"
    pdf-content-width="100%"
    @progress="onProgress($event)"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <PrintInvoiceForm :invoice="invoice" />
    </template>
  </vue3-html2pdf>

  <InvoicePaymentForm
    v-if="showModal"
    @close-modal="showModal = false"
    :invoice="invoiceDetails"
    @refresh-invoice="refresh"
  />
</template>

<script>
import EmptyStateTable from "../../components/EmptyTableState.vue";
import moment from "moment";
import { ref, reactive } from "vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import PrintInvoiceForm from "../../../components/Modals/property-manager/PrintInvoiceForm.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import InvoicePaymentForm from "../../../components/Modals/property-manager/InvoicePaymentForm.vue";

const modalData = ref({});
const invoiceDetails = ref({});
const isLoading = ref(false);
const download_perc = ref(0);
const showProgress = ref(false);
const showModal = ref(false);

export default {
  name: "invoices-table",
  props: {
    invoices: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    PrintInvoiceForm,
    Pagination,
    EmptyStateTable,
    Vue3Html2pdf,
    InvoicePaymentForm,
  },

  setup(props) {
    const htmlToPdfOptions = {
      margin: 0.3,
      enableLinks: false,
      image: {
        type: "jpeg",
        quality: 1,
      },
      html2canvas: {
        scale: 5,
        useCORS: true,
      },

      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
    };
    const onProgress = async (percentage) => {
      if (percentage == 100) showProgress.value = false;
      else {
        showProgress.value = true;
      }
      download_perc.value = percentage;
    };

    const total_records = props?.invoices?.total || 0;
    const per_page = props?.invoices?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.invoices?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      console.log(page);
      props.refresh(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    const paymentForm = reactive({
      id: props?.property?.unitId || 0,
      reference_no: props?.property?.reference_no || "",
      amount: props?.property?.amount || "",
      date: props?.property?.date || "",
      propertyId: props?.propertyId || "",
      date_range: "",
      tenant: "",
    });

    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    const payInvoice = (invoice) => {
      invoiceDetails.value = invoice;
      showModal.value = true;
    };

    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };

    return {
      getStatusColor,
      formatNumber,
      Pagination,
      onPageChange,
      dateTime,
      getInitials,
      modalData,
      isLoading,
      onSelectHandler,
      Filter,
      paymentForm,
      shortcuts,
      onProgress,
      htmlToPdfOptions,
      payInvoice,
      showModal,
      invoiceDetails,
    };
  },

  data() {
    const storedPermissions = JSON.parse(
      localStorage.getItem("permissionlist")
    );
    return {
      invoice: null,
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    printInvoicePdf(invoice) {
      this.invoice = invoice;
      this.$refs.html2Pdf.generatePdf();
    },
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>
.cta button {
  margin-right: 10px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}

.custom-green-button {
  background-color: #1bb11b;
  color: #fff;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
