<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <business-income-table
            :businessIncome="businessIncome"
            :refresh="fetchData"
            :searchQuery="searchTerm"
            v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import BusinessIncomeTable from "./../components/property-manager/BusinessIncomeTable.vue";
import Loader from "./../components/Loader.vue";
import { fetchBusinessIncome } from "../../api/property-manager/businessIncome";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const businessIncome = ref([]);
const searchTerm = ref("");
export default {
  name: "business-income",
  components: {
    BusinessIncomeTable,
    Loader,
  },
  setup() {
    const fetchData = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchBusinessIncome(search, page);
        businessIncome.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      businessIncome,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>
