<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-male text-primary me-2" />{{
            props.landlord.id != null ? "Edit Landlord" : "New Landlord"
          }}
        </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
          ref="landlordFormRef"
          :model="landlordForm"
          :rules="rules"
          label-width="100px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="inline-form-group">
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Full Name" prop="full_name">
                    <el-input v-model="landlordForm.full_name" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="landlordForm.email" :disabled="isInputDisabled" />
                  </el-form-item>
                </div>
              </div>
              <div class="inline-form-group">
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Physical Address" prop="address">
                    <el-input v-model="landlordForm.address" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Kra Pin" prop="kra_pin">
                    <el-input v-model="landlordForm.kra_pin" />
                  </el-form-item>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <label class="el-form-item__label">Phone Number</label>
                <vue-tel-input
                    v-model="landlordForm.phone"
                    :value="landlordForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                ></vue-tel-input>
              </div>

            </div>
          </div>
          <div class="card--footer">
            <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(landlordFormRef)"
            >
              {{
                props.landlord.id != null
                  ? "Update Landlord"
                  : "Create Landlord"
              }}</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { createLandlord, updateLandlord } from "../../../api/property-manager/landlords";
  import { ElNotification } from "element-plus";
  
  const landlordFormRef = ref();
  const isLoading = ref(false);
  const propertyManagerId = localStorage.getItem("propertyManagerId");

  const phoneBindingProps = {
    mode: "international",
    styleClasses: "has-default",
    defaultCountry: "KE",
    onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
    inputOptions: {
      showDialCode: false,
      placeholder: "Mobile Number",
      name: "phone",
    },
  };
  export default {
    name: "landlord-form",
  
    props: {
      landlord: {
        type: Object,
        required: false,
      },
    },
    
  
    setup(props, { emit }) {
      const landlordForm = reactive({
        id: props?.landlord?.id || 0,
        email: props?.landlord?.email || "",
        full_name: props?.landlord?.name || "",
        phone: props?.landlord?.phone || "",
        address: props?.landlord?.phy_add || "",
        kra_pin: props?.landlord?.kra_pin || ""
      });
  
      const rules = reactive({
        email: [
          {
            required: true,
            message: "Please input email address",
            trigger: "blur",
          }
        ],
        phone: [
          {
            required: true,
            message: "Please input your phone number",
            trigger: ["blur", "change"],
          },
  
          {
            min: 6,
            max: 255,
            message: "Minimum characters should be 6",
            trigger: "blur",
          },
        ],
        full_name: [
          {
            required: true,
            message: "Please input full name",
            trigger: ["blur", "change"],
          },
        ],
        address: [
          {
            required: true,
            message: "Please input address",
            trigger: ["blur", "change"],
          },
        ]
      });
  
      const checkMobileNumber = (event) => {
        if (event.valid) {
          landlordForm.phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          landlordForm.phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };
  
      
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {

            isLoading.value = true;
            const formData = new FormData();
            formData.append("email", landlordForm.email);
            formData.append("full_name", landlordForm.full_name);
            formData.append("phone", landlordForm.phone);
            formData.append("address", landlordForm.address);
            formData.append("kra_pin", landlordForm.kra_pin);
            formData.append("managerId", propertyManagerId);

            let body = {
              email:landlordForm.email,
              full_name: landlordForm.full_name,
              phone: landlordForm.phone,
              address: landlordForm.address,
              kra_pin: landlordForm.kra_pin,
              managerId: propertyManagerId
            }
  
            if (landlordForm.id > 0) {
              updateLandlord(props?.landlord?.id, body)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-landlords");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
                )
                .finally(() => {
                  isLoading.value = false;
                });
            } else {
              createLandlord(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-landlords");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                console.log(err),
                 
                )
                .finally(() => {
                  isLoading.value = false;
                });
            }
          } else {
            console.log("error submit!", fields);
          }
        });
      };
      
  
      return {
        landlordForm,
        submitForm,
        rules,
        landlordFormRef,
        isLoading,
        props,
        phoneBindingProps,
        checkMobileNumber,
       
      };
    },
    computed: {
    isInputDisabled() {
      return this.landlord?.id > 0;
    },
  },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 700px;
    max-height: 480px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }

  .close {
    margin: 5% 0 0 10px;
    cursor: pointer;
    background-color: #f4443e;
    color: #ffffff;
    border: none;
    height: 30px;
    width: 30px;
    opacity: .75;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease;
  }

  .close i {
    font-size: 15px;
  }
  input {
    border-radius: 6px;
  }

  .inline-form-group {
    display: flex;
  }

  .inline-form-item {
    flex: 1;
    margin-right: 20px;
  }
</style>
  