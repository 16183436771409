<template>
    <div class="card">
        <scale-loader color="#5f924d" height="20px"></scale-loader>
    </div>
</template>
<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
export default {
    components:{ScaleLoader}
}
</script>
