<template>
  <div class="card">
    <div class="card-header">
        <button v-if="hasPermission('create.payments')" @click="newPayment" class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button" >
          <i class="fas fa-plus  me-1" aria-hidden="true"></i>New Payment
        </button>
    </div>
    <div class="row">
      <div class="card-body">
        <div  v-if="payments.data.length === 0">
          <empty-state-table />
        </div>
          <div class="table-responsive" v-else>
            <div class="card-table">
              <table class="table align-items-center">
                <thead class="sticky-top">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Payment Channel
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Amount
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Balance
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Reference number
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Payment Date
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in payments.data" :key="item.payId + index">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div>
                        <div class="avatar avatar-sm me-3 bg-gradient-primary">
                          {{
                            getInitials(
                                item.source
                            ).toUpperCase()
                          }}
                        </div>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">
                          {{ item.source }}
                        </h6>
                      </div>
                    </div>
                  </td>

                  
                  <td class="align-middle text-center text-sm">
                    <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item.amountPaid) }}</p>
                  </td>

                  <td class="align-middle text-center text-sm">
                    <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item.account_snapshot) }}</p>
                  </td>

                  <td class="align-middle text-center text-sm">
                    <p class="text-xs font-weight-bold mb-0">{{ item.thirdpartyPaymentRef }}</p>
                  </td>

                  <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{
                                        dateTime(item.paymentDate)
                                      }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <el-dropdown trigger="click">
                                      <span class="el-dropdown-link">
                                        <i class="fa fa-ellipsis-v text-primary"></i>
                                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                              class="text-secondary font-weight-bold text-xs"
                              @click="downloadReceipt(item.payId)"
                          ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                            Download Receipt
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        <div class="card-footer d-flex justify-content-center mb-3">
          <Pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
  <ManualPaymentForm
      v-if="showModal"
      @close-modal="showModal = false"
      :unitId="props.unitId"
      @refresh-unit-payment="refresh"
  />
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import {reactive, ref, toRefs, watch} from "vue";
import moment from "moment/moment";
import ManualPaymentForm from "@/components/Modals/property-manager/ManualPaymentForm.vue";
import {ElMessage} from "element-plus";
import {downloadReceiptPdf} from "../../../api/property-manager/downloads";

const isLoading = ref(false);
const showModal = ref(false);
export default {
  name: "ExpensesTable",
  components: {
                ManualPaymentForm,
                EmptyStateTable,
                Pagination
              },
  props: {
    payments: {
      type: Object,
      required: true,
    },
    unitId: {
      type: Object,
      required: true,
    },
    amountDue: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  setup(props){
    const { payments, refresh, searchQuery } = toRefs(props);
    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = payments.value?.total || 0;
      const per_page = payments.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(payments, () => {
      updateTotalPages();
      Pagination.current_page = payments.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: payments.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });
    const newPayment = () => {
      showModal.value = true;
    }

    async function downloadReceipt(id){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Receipt will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadReceiptPdf(id)
            .then((Response) => {
              console.log(Response)

              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenant_receipt_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }


    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    

    return {
      formatNumber,
      newPayment,
      showModal,
      props,
      getInitials,
      dateTime,
      Pagination,
      onPageChange,
      downloadReceipt
    }
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },   
}
</script>
<style scoped>

.suspend-button {
  position: absolute;
  right: 50px;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
</style>