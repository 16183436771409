<template>
  <div class="card">
    <div class="card-header">
      <button  @click="newArrear" class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button">
        <i class="fas fa-plus  me-1" aria-hidden="true"></i>New Arrear
      </button>
    </div>
    <div class="row">
      <div class="card-body">
        <div  v-if="arrears.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Description
                </th>

                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Amount
                </th>

                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in arrears.data" :key="item.arrearId + index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.description
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item.description }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-xs font-weight-bold mb-0">{{ item.amount }}</p>
                </td>

                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{
                                        dateTime(item.arr_date)
                                      }}</span>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                                      <span class="el-dropdown-link">
                                        <i class="fa fa-ellipsis-v text-primary"></i>
                                      </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="deleteArrear(item.arrearId)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <Pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
      <hr class="horizontal dark" />
    </div>
  </div>
  <ArrearForm
      v-if="showModal"
      @close-modal="showModal = false"
      :unitId="unitId"
      @refresh="refresh"
  />
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import ArrearForm from "../../../components/Modals/property-manager/ArrearForm.vue";
import {ElMessage, ElMessageBox} from "element-plus";
import { removeArrear } from "@/api/property-manager/arrears";

import {reactive, ref, toRefs, watch, h} from "vue";
import moment from "moment/moment";
const showModal = ref(false);
const isLoading = ref(false);
export default {
  name: "ExpensesTable",
  components: {
    EmptyStateTable,
    Pagination,
    ArrearForm
  },
  props: {
    arrears: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    unitId: {
      required: true,
    },
  },
  setup(props){
    
    const { arrears, refresh, searchQuery } = toRefs(props);
    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = arrears.value?.total || 0;
      const per_page = arrears.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(arrears, () => {
      updateTotalPages();
      Pagination.current_page = arrears.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: arrears.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const newArrear = () => {
      showModal.value = true;
    }

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const deleteArrear = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The arrear will be deleted. Continue?"
          ),

          "Delete Arrear",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeArrear(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    return {
      props,
      getInitials,
      dateTime,
      Pagination,
      onPageChange,
      newArrear,
      showModal,
      deleteArrear,
      isLoading
    }
  }
}
</script>
<style scoped>

.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>