<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ManagerNav v-if="userType === 'manager'"/>
    <LandlordNav v-else-if="userType ==='landlord'"/>
    <AdminNav v-else/>
  </div>
</template>
<script>

import AdminNav from "./AdminNav.vue";
import ManagerNav from "./ManagerNav.vue";
import LandlordNav from "./LandlordNav.vue";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));

    const userType = localStorage.getItem("userType");

    return {
      title: "PropertyManagement",
      controls: "dashboardsMain",
      isActive: "active",
      userType: userType,
      userPermissions: storedPermissions || [],

    };
  },
  components: {ManagerNav, LandlordNav, AdminNav},
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>
<style scoped>

</style>
