<template>
  <div class="py-1 container-fluid">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-shield text-primary text-md opacity-10"></i>
          <h6>
            {{
              roleForm.id !== 0 ? "Update Role" : "Create Role"
            }}
          </h6>
        </div>
      </div>
      <div class="card-body">
        <el-form
          ref="RolePermissionFormRef"
          :model="roleForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div>
              <el-form-item label="Name" prop="name">
                <el-input v-model="roleForm.name" />
              </el-form-item>
            </div>

            <div class="mb-3">
              <el-form-item label="Description" prop="description">
                <el-input v-model="roleForm.description" type="textarea" />
              </el-form-item>
            </div>
          </div>

          <div class="table-responsive">
            <h6 style="padding: 1.5rem">Permissions</h6>
            <div class="card-table">
              <table class="table align-items-center">
                <thead class="sticky-top">
                <tr>
                  <th
                      class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Module
                  </th>
                  <th
                      class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      v-for="action in actions"
                      :key="action"
                  >
                    {{ capitalizedText(action) }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(permission, _module) in permissions" :key="_module">
                  <td>
                    {{ capitalizedText(_module)}}
                  </td>
                  <td v-for="action in actions" :key="action">
                    <el-checkbox
                        v-if="permission[action]"
                        v-model="roleForm.selected_permissions[permission[action]]"
                        :checked="current_permissions.includes(permission[action])"
                        size="large"
                    />
                    <i v-else class="fa fa-ban"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-form>
      </div>
      <div class="card-footer p-5">
        <div class="d-flex flex-row-reverse">
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(RolePermissionFormRef)"
            >
            {{
              roleForm.id !== 0 ? "Update" : "Save"
            }}
          </el-button
          >
          <el-button
              type="danger"
              class="me-2"
              @click="clearSelection"
          > Clear Selection </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import { fetchPermissions } from "../../api/services/authentication";
import {createRole,updateRole} from "../../api/property-manager/roles";
import {ElNotification} from "element-plus";
import { useRoute, useRouter } from 'vue-router';

const RolePermissionFormRef = ref();
const isLoading = ref(false);
const permissions = ref([]);
const selected_permissions = ref([]);
const current_permissions = ref([]);

const actions = ["manage", "view", "create", "update", "delete","allocate", "sms", "email", "download", "upload", "print", "activate", "deactivate"];
export default {
  name: "Roles Form",
  components: {},
  props: {
    role: {
      type: Array,
      required: false,
    },
    managerId: {
      type: Array,
      required: false,
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const parsedRole = ref(null);

    const extractedIds = {};

    onMounted(() => {
      const roleParam = route.query.role;
      if (roleParam) {
        parsedRole.value = JSON.parse(roleParam);
        parsedRole.value.permissions.forEach(item => {
          extractedIds[item.id.toString()] = true;
        });
      }
    });

    const roleForm = reactive({
      id: 0,
      name: "",
      description: "",
      managerId: "",
      selected_permissions: extractedIds,
    });

    watch(
        () => [parsedRole.value, props.role, props.managerId],
        ([newParsedRole, newPropsRole, propsManagerId]) => {
          if (!newParsedRole && !newPropsRole) return;

          roleForm.id = newParsedRole ? newParsedRole.id || 0 : newPropsRole?.id || 0;
          roleForm.name = newParsedRole ? newParsedRole.name || newPropsRole?.name || "" : newPropsRole?.name || "";
          roleForm.description = newParsedRole ? newParsedRole.description || "" : newPropsRole?.description || "";
          roleForm.managerId = propsManagerId != null ? propsManagerId : "";


          if (newPropsRole) {
            newPropsRole.permissions.forEach(item => {
              extractedIds[item.id.toString()] = true;
            });
          }

          if (newParsedRole && newParsedRole.permissions) {
            newParsedRole.permissions.forEach(item => {
              extractedIds[item.id.toString()] = true;
            });
          }
        },
        { immediate: true }
    );

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input the role name",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input a description of what this role is about",
          trigger: ["blur", "change"],
        },
      ],
    });

    const getPermissions = async () => {
      isLoading.value = true;
      try {
        const response = await fetchPermissions();
        permissions.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }

    };
    const capitalizedText = (text) => {
      if (text.includes('_')) {
        const splitText = text.split('_');
        const capitalizedParts = splitText.map((part) => part.charAt(0).toUpperCase() + part.slice(1));
        return capitalizedParts.join(' ');
      } else {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    };
    onMounted(() => {
      getPermissions();
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {

          const selectedPermissionKeys = Object.keys(roleForm.selected_permissions).filter(
              (key) => roleForm.selected_permissions[key]
          );

          console.log(roleForm.selected_permissions)
          console.log(selectedPermissionKeys)


          isLoading.value = true;
          const formData = new FormData();
          formData.append("id", roleForm.id);
          formData.append("name", roleForm.name);
          formData.append("description", roleForm.description);
          formData.append("selected_permission_ids", JSON.stringify(selectedPermissionKeys));
          formData.append("managerId", roleForm.managerId);

          if (roleForm.id > 0) {
            updateRole(formData, roleForm.id)
                .then((data) => {
                  if (data.success) {
                    console.log('sussess')
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    router.push(`/roles`)
                  } else {
                    if (data.errors) {
                      Object.values(data.errors).forEach((val) =>
                          ElNotification({
                            title: "Error",
                            message: val[0],
                            type: "error",
                          })
                      );
                    } else {
                      ElNotification({
                        title: "Error",
                        message: data.message,
                        type: "error",
                      });
                    }
                  }
                })
                .catch((err) =>

                    ElNotification({
                      title: "Error",
                      message: err.message,
                      type: "error",
                    })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            createRole(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    router.push(`/roles`)

                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),

                )
                .finally(() => {
                  isLoading.value = false;
                });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const clearSelection = () => {
      for (const module in roleForm.selected_permissions) {
        roleForm.selected_permissions[module] = false;
      }
    };

    return {
      current_permissions,
      selected_permissions,
      capitalizedText,
      actions,
      permissions,
      roleForm,
      RolePermissionFormRef,
      rules,
      isLoading,
      submitForm,
      clearSelection
    };
  },
};
</script>
<style scoped>
.card-header {
  margin-bottom: 0; /* Remove any bottom margin on the card header */
}

.card-body {
  padding-top: 0; /* Remove padding at the top of the card body */
}
.card-table {
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
  border-radius: 50px;
}
.sticky-top th {
  color: #fff !important;
}
</style>
