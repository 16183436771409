<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="card">
    <div class="card-body">
      <div v-if="invoices?.data?.length === 0">
        <empty-state-table />
      </div>
      <div v-else class="card-table">
        <table class="table align-items-center">
          <thead class="sticky-top">
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Submitted Date
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Unit Code
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Tenant Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Phone Number
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Tenant Email
              </th>

              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Rent
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoices.data" :key="item.id + index">
              <td class="text-xs font-weight-bold mb-0">
                {{ dateTime(item?.created_at) }}
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item?.units?.unitCode }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item?.tenants?.firstName + " " + item?.tenants?.lastName }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item?.tenants?.primaryPhone }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item?.tenants?.email }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item?.amount }}
                </p>
              </td>

              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        @click="downloadInvoice(item?.id)"
                        ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Download Invoice
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="text-secondary font-weight-bold text-xs"
                        @click="submitCreditNote(item?.id)"
                        ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Raise Credit Note
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="invoices?.data?.length !== 0"
      class="card-footer d-flex justify-content-center"
    >
      <pagination
        :totalPages="Pagination.total_pages"
        :perPage="Pagination.per_page"
        :currentPage="Pagination.current_page"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, h, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { downloadInvoicePdf } from "@/api/property-manager/downloads";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import moment from "moment/moment";
import { sendRentInvoiceCreditNotes } from "@/api/kra"; //kraResponse, postToKra
import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Loader from "@/views/components/Loader.vue";

const isLoading = ref(false);
export default defineComponent({
  components: { Loader, EmptyStateTable, Pagination },
  props: {
    invoices: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const total_records = props?.invoices?.total || 0;
    const per_page = props?.invoices?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.invoices?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    async function downloadInvoice(id) {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Invoice will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadInvoicePdf(id)
          .then((Response) => {
            console.log(Response);

            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "tenant_invoice_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

    const submitCreditNote = async (id) => {
      ElMessageBox.confirm(
        h(
          "div",
          { style: "color: black, font-size: 14px" },
          "The credit note will be raised. Continue?"
        ),

        "Submit Credit Note",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await sendRentInvoiceCreditNotes(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
            isLoading.value = false;
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY HH:mm:ss");
    };

    return {
      isLoading,
      downloadInvoice,
      Pagination,
      onPageChange,
      dateTime,
      submitCreditNote,
    };
  },
});
</script>

<style scoped>
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
