<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <div class="card-body">
                <div v-if="invoice_payments.data.length === 0">
                  <empty-state-table />
                </div>
                <div class="table-responsive" v-else>
                  <div class="card-table">
                    <table class="table align-items-center">
                      <thead class="sticky-top">
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Property Manager
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Invoice number
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Paid Amount
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Reference Number
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Balance
                          </th>

                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Payment Date
                          </th>

                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in invoice_payments.data"
                          :key="item.id + index"
                        >
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item?.property_manager?.name }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item.invoice_id }}
                            </p>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ formatNumber(item.amount) }}
                            </p>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item.reference_number }}
                            </p>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ formatNumber(item.balance) }}
                            </p>
                          </td>
                          <td class="align-middle text-center">
                            <span
                              class="text-secondary text-xs font-weight-bold"
                              >{{ dateTime(item.created_at) }}</span
                            >
                          </td>

                          <td class="align-middle text-center">
                            <el-dropdown trigger="click">
                              <span class="el-dropdown-link">
                                <i class="fa fa-ellipsis-v text-primary"></i>
                              </span>
                              <template #dropdown>
                                <el-dropdown-menu>
                                  <el-dropdown-item
                                    v-if="item.qr_code_url"
                                    class="text-secondary font-weight-bold text-xs"
                                    @click="downloadInvoice(item.id)"
                                    ><i
                                      class="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                    Download Invoice
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                            </el-dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import moment from "moment/moment";
import EmptyStateTable from "../EmptyTableState.vue";
import { ElMessage } from "element-plus";
import { downloadManagerInvoicePdf } from "@/api/property-manager/downloads";

const selected = ref([]);
const isLoading = ref(false);
export default {
  name: "AdminInvoicesPayments-table",
  props: {
    invoice_payments: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    EmptyStateTable,
  },

  setup() {
    const dateFormRef = ref();

    const dateForm = reactive({
      date: "",
    });

    const messageForm = reactive({
      message: "",
    });

    const rules = reactive({
      date: [
        {
          required: true,
          message: "Please pick a date",
          trigger: ["blur", "change"],
        },
      ],
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });
    const shortcuts = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Tomorrow",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "Next week",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];

    const multipleTableRef = ref();

    const toggleSelection = () => {
      return (selected.value = []);
    };

    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    async function downloadInvoice(id) {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Invoice will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadManagerInvoicePdf(id)
          .then((Response) => {
            console.log(Response);

            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "manager_invoice_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      isLoading,
      multipleTableRef,
      toggleSelection,
      selected,
      capitalized,
      dateTime,
      downloadInvoice,
      rules,
      messageForm,
      dateFormRef,
      dateForm,
      shortcuts,
      formatNumber,
      getStatusColor,
    };
  },
};
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
