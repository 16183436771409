<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg');
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h3 class="text-white mb-2 mt-5">Simplify Rent Collection</h3>
            <p class="text-lead text-white">
              Digitize rent payment and property management for seamless
              reconciliation, reporting, and communication with tenants.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Login Here.</h5>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3"></div>
            <div class="card-body">
              <el-form
                ref="loginFormRef"
                :model="loginForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                size="large"
                status-icon
                :scroll-to-error="true"
                label-position="top"
                :hide-required-asterisk="true"
              >
                <el-form-item label="Email" prop="email">
                  <el-input v-model="loginForm.email" />
                </el-form-item>
                <el-form-item label="Password" prop="password">
                  <el-input
                    v-model="loginForm.password"
                    type="password"
                    :show-password="true"
                  />
                </el-form-item>

                <el-button
                  type="success"
                  class="px-5 d-block my-3 mx-auto"
                  :loading="isLoading"
                  @click="submitForm(loginFormRef)"
                >
                  Login
                </el-button>
              </el-form>
            </div>
            <div class="px-1 pt-0 text-center card-footer px-lg-2">
              <p class="mx-auto mb-4 text-sm">
                Forgot your password?
                <router-link
                  to="/forgot-password"
                  class="text-success text-gradient font-weight-bold"
                  >Reset</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { login } from "../api/services/authentication";
import { useRouter } from "vue-router";
import { h } from "vue";
import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";

const body = document.getElementsByTagName("body")[0];
const loginFormRef = ref();
const isLoading = ref(false);

export default {
  components: {
    Navbar,
    AppFooter,
  },

  setup() {
    const router = useRouter();
    const loginForm = reactive({
      email: "",
      password: "",
    });

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: ["blur", "change"],
        },

        {
          min: 3,
          max: 255,
          message: "Minimum characters should be 3",
          trigger: "blur",
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          let body = {
            grant_type: "password",
            client_id: "9a622bde-72e1-4103-8493-9e8f96d585a2",
            client_secret: "0JxDd6KjccEUgPp31SMpHClVrgzeHiaGKFJYxLSx",
            username: loginForm.email,
            password: loginForm.password,
          };

          login(body)
            .then((response) => {
              if (response.status === 200) {
                localStorage.setItem("isAuthenticated", true);
                localStorage.setItem(
                  "tokenExpiration",
                  response.data.expires_in
                );
                localStorage.setItem("token", response.data.access_token);
                localStorage.setItem(
                  "user",
                  JSON.stringify(response.data.user)
                );
                localStorage.setItem("profileName", response.data.user.name);
                localStorage.setItem("userType", response.data.user.userType);
                localStorage.setItem(
                  "permissionlist",
                  JSON.stringify(response.data.permissions)
                );
                localStorage.setItem("role", response.data.roles);
                if (response.data.user.userType === "manager") {
                  // localStorage.setItem(
                  //     "propertyManagerId",
                  //     response.data.user.manager.id
                  // );

                  localStorage.setItem(
                    "propertyManagerId",
                    response.data.prop_manager.id
                  );

                  localStorage.setItem(
                    "managerLogo",
                    response.data.user.manager.business_logo
                  );
                  localStorage.setItem(
                    "propertyManager",
                    JSON.stringify(response.data.prop_manager)
                  );
                  router.replace({ path: "/dashboard-default" });
                } else if (response.data.user.userType === "landlord") {
                  router.replace({ path: "/landlord-dashboard" });
                } else if (response.data.user.userType === "admin") {
                  router.replace({ path: "/admin-dashboard" });
                }
                ElMessage({
                  message: h(
                    "i",
                    { style: "color: teal" },
                    `Welcome ${response.data.user.name} to your dashboard`
                  ),
                  type: "success",
                });
              } else if (response.status === 401) {
                ElNotification({
                  title: "Error",
                  message:
                    "Invalid login credentials. Please confirm login details and try again.",
                  type: "error",
                });
              } else {
                ElNotification({
                  title: "Error",
                  message: response.data.message,
                  type: "error",
                });
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      isLoading,
      resetForm,
      submitForm,
      rules,
      loginForm,
      loginFormRef,
    };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
.el-button .custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}
</style>
