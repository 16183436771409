<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-home text-primary me-2" />
          {{
            props.property.propertyId != null ? "Edit Property" : "New Property"
          }}
        </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
          ref="propertyFormRef"
          :model="propertyForm"
          :rules="rules"
          label-width="120px"
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="inline-form-group">
                <div class="col-md-12 mb-2 inline-form-item">
                  <el-form-item label="Property Name" prop="property_name">
                    <el-input v-model="propertyForm.property_name" />
                  </el-form-item>
                </div>

                <div class="col-md-12 mb-2 inline-form-item">
                  <el-form-item label="Location" prop="location">
                    <el-input v-model="propertyForm.location" />
                  </el-form-item>
                </div>
              </div>

              <div class="inline-form-group">
                <div class="col-md-12 mb-2 inline-form-item">
                  <el-form-item label="Kra Rate" prop="kra_rate">
                    <el-select
                        style="width: 100%"
                        v-model="propertyForm.kra_rate"
                    >
                      <el-option label="7.5" value="7.5"></el-option>
                      <el-option label="16" value="16"></el-option>
                      <el-option label="" value=""></el-option>
                    </el-select>
                  </el-form-item>
                </div>

                <div class="col-md-12 mb-2 inline-form-item">
                  <el-form-item label="Landlord Kra Pin" prop="landlord_kra_pin">
                    <el-input v-model="propertyForm.landlord_kra_pin" />
                  </el-form-item>
                </div>
              </div>

              <div class="inline-form-group">
                <div class="col-md-6 mb-2 inline-form-item">
                  <el-form-item label="Landlord" prop="landlord">
                    <el-select-v2
                        v-model="propertyForm.landlord"
                        filterable
                        :options="landlords"
                        style="width: 100%"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="card--footer">
            <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(propertyFormRef)"
            >
              {{
                props.property.propertyId != null
                  ? "Update Property"
                  : "Create Property"
              }}</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import {reactive, ref} from "vue";
  import {createProperty, updateProperty} from "../../../api/property-manager/properties";
  import {fetchManagerNotPaginatedLandlords} from "../../../api/property-manager/landlords";
  import {ElNotification} from "element-plus";

  const propertyFormRef = ref();
  const isLoading = ref(false);
  const landlords = ref([]);
  export default {
    name: "property-form",
  
    props: {
      property: {
        type: Object,
        required: false,
      },
    },
  
    setup(props, { emit }) {
      const propertyForm = reactive({
        id: props?.property?.propertyId || 0,
        property_name: props?.property?.propertyName || "",
        kra_rate: props?.property?.kra_rate || "",
        landlord_kra_pin: props?.property?.landlord_kra_pin || "",
        location: props?.property?.propertyAddress || "",
        landlord: "",
      });

      const rules = reactive({
        landlord: [
            {
            required: true,
            message: "Please select a landlord",
            trigger: ["blur", "change"],
            },
        ],
        property_name: [
          {
            required: true,
            message: "Please input property name",
            trigger: ["blur", "change"],
          },
        ],
        location: [
          {
            required: true,
            message: "Please input location",
            trigger: ["blur", "change"],
          },
        ]
      });

      const submitForm = async (formEl) => {
        console.log(props.property);
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {
            isLoading.value = true;
            const formData = new FormData();
            formData.append("location", propertyForm.location);
            formData.append("kra_rate", propertyForm.kra_rate);
            formData.append("property_name", propertyForm.property_name);
            formData.append("kra_rate", propertyForm.kra_rate);
            formData.append("landlord_kra_pin", propertyForm.landlord_kra_pin);
            formData.append("landlord", propertyForm.landlord);

            if (propertyForm.id > 0) {
              updateProperty(propertyForm.id, propertyForm)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-properties");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
                )
                .finally(() => {
                  isLoading.value = false;
                });
            } else {
              createProperty(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-properties");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                console.log(err),
                 
                )
                .finally(() => {
                  isLoading.value = false;
                });
            }
          } else {
            console.log("error submit!", fields);
          }
        });
      };
  
      return {
        propertyForm,
        submitForm,
        rules,
        propertyFormRef,
        isLoading,
        props,
        landlords,
      };
    },

    created() {
        this.fetchLandlordList();
    },
    methods: {
        async fetchLandlordList() {
        try {
          const response = await fetchManagerNotPaginatedLandlords();
          landlords.value = response.data.map((landlord) => ({
              value: `${landlord.id}`,
              label: `${landlord.name}`,
            }));
        } catch (error) {
            console.log(error);
        }
        },
    },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 550px;
    max-height: 440px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
  input {
    border-radius: 6px;
  }

  .inline-form-group {
    display: flex;
  }

  .inline-form-item {
    flex: 1;
    margin-right: 20px;
  }
</style>
  