<template>
    <div class="card h-100">
        <div class="pb-0 card-header mb-0">
            <h6>{{ title }}</h6>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas id="chart-doughnut" class="chart-canvas" height="500"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "donut-chart",

    props: {
        title: {
            type: String,
            default: "PAYMENT SOURCES ANALYSIS",
        },
        payment_amount: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        var ctx = document.getElementById("chart-doughnut").getContext("2d");

        new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: this.payment_amount.payment_sources,
                datasets: [{
                    label: "Total Collection",
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1,
                    data: this.payment_amount.total_amounts,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                    }
                }
            }
        });
    },
};
</script>
