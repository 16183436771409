import { api } from "../";
export const fetchPropertiesExpenses = async(search, page, id) => {
    var url = `list/property-expenses/${id}`;
    if (search)
        url =
            url +
            "&searchFields=amount:like;date:like;description:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`list/property-expenses/${id}?`, `list/property-expenses/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyExpenses = async() => {
    let response = await api
        .get("property-expenses")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createExpense = async(body) => {
    let response = await api
        .post("property-expenses", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeExpense = async(id) => {
    let response = await api
        .delete(`property-expenses/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export class fetchExpenses {
}