<template>

  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-grav text-primary text-md opacity-10"></i>
        <h6>Users</h6>
      </div>
      <div class="cta">
        <button
            v-if="hasPermission('create.system_users')"
            class="btn mb-0 btn-success btn-xs null null ms-auto"
            @click="newUser"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
          User
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-if="managerialUsers.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Phone Number
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Email
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Role
              </th>
              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created Date
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in managerialUsers.data" :key="item.id + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.firstName + " " + item.lastName
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h5 class="mb-0 text-sm">
                      {{ item.firstName + " " + item.lastName }}
                    </h5>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.primaryPhone }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.email }}
                </p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.user?.roles[0]?.name }}
                </p>
              </td>

              <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(parseInt(item.isActive))"
                  >{{ parseInt(item.isActive) === 0 ? "Inactive" : "Active" }}</span
                  >
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.createDate)
                    }}</span>
              </td>

              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link
                          :to="{ path: `/managerial-user/${item.id}` }"
                          class="text-secondary"
                      ><el-dropdown-item
                          v-if="hasPermission('view.system_users')"
                          class="text-secondary font-weight-bold text-xs"
                      ><i class="fa fa-eye" aria-hidden="true"></i>
                        View</el-dropdown-item
                      ></router-link
                      >
                      <el-dropdown-item
                          v-if="hasPermission('update.system_users')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="editUser(item)"
                      ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        Edit
                      </el-dropdown-item>

                      <el-dropdown-item
                          v-if="hasPermission('delete.system_users')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="deleteUser(item.id)"
                      ><i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
  <ManagerialUserForm
      v-if="showModal"
      @close-modal="showModal = false"
      :managerialUser="managerialUserDetails"
      @refresh-managerial-users="refresh"
  />

</template>

<script>
import ManagerialUserForm from "../../../components/Modals/property-manager/ManagerialUserForm.vue";
import EmptyStateTable from "../../components/EmptyTableState.vue";
import Pagination from "./Pagination.vue";
import moment from "moment";
import { ref, h, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {removeManagerialUser} from "../../../api/property-manager/managerialUsers";

const showModal = ref(false);
const managerialUserDetails = ref({});
const modalData = ref({});
const isLoading = ref(false);


export default {
  name: "managerial-users-table",
  props: {
    managerialUsers: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    ManagerialUserForm,
    EmptyStateTable,
    Pagination
  },

  setup(props) {

    const total_records = props?.managerialUsers?.total || 0;
    const per_page = props?.managerialUsers?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.managerialUsers?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(Filter.searchquery, page);
    };
    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (isActive) => {
      switch (isActive) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const editUser = (tenant) => {
      managerialUserDetails.value = tenant;
      showModal.value = true;
    };

    const newUser = () => {
      managerialUserDetails.value = {};
      showModal.value = true;
    };

    const deleteUser = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The user will be deleted. Continue?"
          ),

          "Delete User",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeManagerialUser(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };
   

    return {
      onPageChange,
      Pagination,
      dateTime,
      getStatusColor,
      getInitials,
      showModal,
      modalData,
      managerialUserDetails,
      editUser,
      newUser,
      isLoading,
      deleteUser,
      onSelectHandler,
    };
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },
        

};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
