<template>
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-male text-primary text-md opacity-10"></i>
          <h6>Landlords</h6>
        </div>
        <div class="cta">
          <FilterTablesForm
            :show_landlords_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
          <button
            v-if="hasPermission('create.landlords')"
            class="btn mb-0 btn-success btn-xs null null ms-auto"
            @click="newLandlord"
          >
            <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
            Landlord
          </button>
        </div>
      </div>
      <div class="card-body">
        <div v-if="landlords.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
              <tr>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Landlord Name
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Property Manager
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Phone Number
                </th>

                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
                >
                  Email
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Created Date
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in landlords.data" :key="item.id + index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.name
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item.name}}
                      </h6>
                      <p v-if="propertyManager" class="text-xs text-secondary mb-0">{{ item?.property_managers?.name }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.property_manager?.name }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.phone }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.email }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(item.isActive)"
                  >{{ item.isActive === 'no' ? "Deactivated" : "Active" }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.created_on)
                    }}</span>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <router-link
                            :to="{ path: `/landlord/${item.id}` }"
                            class="text-secondary"
                        ><el-dropdown-item
                            v-if="hasPermission('view.landlords')"
                            class="text-secondary font-weight-bold text-xs"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                          View</el-dropdown-item
                        ></router-link
                        >
                        <el-dropdown-item
                            v-if="hasPermission('update.landlords')"
                            class="text-secondary font-weight-bold text-xs"
                            @click="editLandlord(item)"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          Edit
                        </el-dropdown-item>

                        <el-dropdown-item
                            v-if="item.isActive === 'no' && hasPermission('delete.landlords')"
                            class="text-secondary font-weight-bold text-xs"
                            @click="deleteLandlord(item.id)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-center mb-3">
          <pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
    <LandlordsForm
      v-if="showModal"
      @close-modal="showModal = false"
      :landlord="landlordDetails"
      @refresh-landlords="refresh"
    />

  </template>
  
  <script>
  import LandlordsForm from "../../../components/Modals/property-manager/LandlordForm.vue";
  import EmptyStateTable from "../../components/EmptyTableState.vue";
  import Pagination from "../property-manager/Pagination.vue";
  import moment from "moment";
  import { ref, h, reactive } from "vue";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { removeLandlord } from "@/api/property-manager/landlords";
  import FilterTablesForm from "../admin/FilterTablesForm.vue";

  const showModal = ref(false);
  const landlordDetails = ref({});
  const modalData = ref({});
  const isLoading = ref(false);
  const filter_params = ref({});

  export default {
    name: "landlords-table",
    props: {
      landlords: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      refresh: {
        type: Function,
        required: true,
      },
      searchQuery: {
        type: String,
        required: true,
      },
    },
    components: {
      LandlordsForm,
      EmptyStateTable,
      Pagination,
      FilterTablesForm,
    },
  
    setup(props) {

      const total_records = props?.landlords?.total || 0;
      const per_page = props?.landlords?.per_page || 0;
      const total_pages = ref(0);
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      }

      const Pagination = reactive({
        current_page: props?.landlords?.current_page || 1,
        total_pages: total_pages,
        per_page: per_page,
      });

      const onPageChange = (page) => {
        props.refresh(Filter.searchquery, page);
      };
      const Filter = reactive({
        searchquery: props.searchQuery != null ? props.searchQuery : "",
      });
      const onSelectHandler = () => {};
      const getInitials = (name) => {
        if (name) {
          const nameSplit = name?.split(" ");
          const firstInitials = nameSplit[0]?.charAt(0);
          const secondInitials = nameSplit[1]?.charAt(0);
  
          return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
          );
        }
        return "";
      };
      const getStatusColor = (status) => {
        switch (status) {
          case "no":
            return "bg-gradient-danger";
          case "yes":
            return "bg-gradient-success";
  
          default:
            return null;
        }
      };
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };
  
      const editLandlord = (landlord) => {
        landlordDetails.value = landlord;
        showModal.value = true;
      };
  
      const newLandlord = () => {
        landlordDetails.value = {};
        showModal.value = true;
      };

      const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
  
      const deleteLandlord = async (id) => {
        ElMessageBox.confirm(
          h(
            "div",
            { style: "color: black, font-size: 14px" },
            "The landlord will be deleted. Continue?"
          ),
  
          "Delete Landlord",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
        ).then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await removeLandlord(id);
  
            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        });
      };
      
  
      return {
        onPageChange,
        Pagination,
        dateTime,
        getStatusColor,
        getInitials,
        showModal,
        modalData,
        landlordDetails,
        editLandlord,
        newLandlord,
        isLoading,
        deleteLandlord,
        onSelectHandler,
        filter_params,
        _filterCallBack
      };
    },
    data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },
  };
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }
  .card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  h6 {
    margin: 0;
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #5f924d;
    border-radius: 50px;
  }
  .sticky-top th {
    color: #fff !important;
  }

  </style>
  