<template>
    <main>
      <div class="py-4 container-fluid">
        <PropertyInfo :property_id="property_id" />
      </div>
    </main>
  </template>
  
  <script>
    import PropertyInfo from "./../components/property-manager/PropertyInfo.vue";
    import setNavPills from "@/assets/js/nav-pills.js";
    import setTooltip from "@/assets/js/tooltip.js";
    import { ref } from "vue";
    import { useRoute } from "vue-router";
  
  const property_id = ref(0);
  
  export default {
    components: { PropertyInfo },
    name: "profile",
  
    setup() {
      const route = useRoute();
      property_id.value = route.params.id;

      return {
        property_id,
      };
    },
  
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip();
    },
    beforeMount() {
      this.$store.state.imageLayout = "profile-overview";
      this.$store.state.showNavbar = true;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = true;
      
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.imageLayout = "default";
      this.$store.state.showNavbar = true;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = false;
     
    },
  };
  </script>
  <style scoped>
  dt {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.4rem;
    color: #495057;
    margin-bottom: 0.5rem;
  }
  dl {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 20px 0;
    padding: 0 16px;
  }
  dd {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
  }
  .sidebar dl {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 30px 0 10px;
  }
  .contant-info dl {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .side-glance {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
  }
  .sidebar button {
    margin: 20px auto;
    display: block;
  }
  </style>
  