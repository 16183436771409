<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0 mb-5">
            <div class="d-flex align-items-center gap-2">
              <i class="fa fa-paper-plane text-primary text-md opacity-10"></i>
              <h6>Send KRA Invoices</h6>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card-body">
              <p class="text-uppercase text-sm">Property Information</p>
              <div class="row">
                <div class="col-md-4 with-vertical-line">
                  <dl>
                    <dt v-if="property?.propertyName">Property Name</dt>
                    <dd v-if="property?.propertyName">
                      {{ capitalized(property?.propertyName) }}
                    </dd>
                    <dt v-if="property?.propertyAddress">Property Location</dt>
                    <dd v-if="property?.propertyAddress">
                      {{ capitalized(property?.propertyAddress) }}
                    </dd>
                  </dl>
                </div>
                <div class="col-md-4 with-vertical-line">
                  <dl>
                    <dt v-if="property?.landlords?.name">Landlord Name</dt>
                    <dd v-if="property?.landlords?.name">
                      {{ capitalized(property?.landlords?.name) }}
                    </dd>
                    <dt v-if="property?.lastUpdated">Created Date</dt>
                    <dd v-if="property?.lastUpdated">
                      {{ dateTime(property?.lastUpdated) }}
                    </dd>
                  </dl>
                </div>
                <div class="col-md-3">
                  <dl>
                    <dt v-if="property?.landlord_kra_pin">Landlord Pin</dt>
                    <dd v-if="property?.landlord_kra_pin">
                      {{ capitalized(property?.landlord_kra_pin) }}
                    </dd>
                    <dt v-if="property?.kra_rate">KRA Rate</dt>
                    <dd v-if="property?.kra_rate">{{ property?.kra_rate }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pt-0 pb-2">
            <div class="card-body">
              <el-tabs
                v-model="activeName"
                class="demo-tabs"
                @tab-click.native="handleClick"
              >
                <el-tab-pane name="units">
                  <template #label>
                    <i class="fa fa-home" aria-hidden="true"></i>&nbsp; &nbsp;
                    Units
                  </template>
                  <eTimsUnitsTable :units="units" :refresh="fetchData" />
                </el-tab-pane>
                <el-tab-pane name="invoices">
                  <template #label>
                    <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;
                    &nbsp; Invoices
                  </template>
                  <RentalInvoicesTable
                    :invoices="invoices"
                    :refresh="fetchInvoices"
                  />
                </el-tab-pane>
                <el-tab-pane name="credit_notes">
                  <template #label>
                    <i class="fa fa-copyright" aria-hidden="true"></i>&nbsp;
                    &nbsp; Credit Notes
                  </template>
                  <RentalCreditNotesTable
                    :credit_notes="credit_notes"
                    :refresh="fetchCreditNotes"
                  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { fetchLeasedUnits } from "@/api/property-manager/units";
import { fetchPropertyDetails } from "@/api/property-manager/properties";
import moment from "moment/moment";
import Loader from "./../../components/Loader.vue";
import { useRoute } from "vue-router";
import EmptyStateTable from "../EmptyTableState.vue";
import eTimsUnitsTable from "./eTimsUnitsTable.vue";
import RentalInvoicesTable from "./RentalInvoicesTable.vue";
import { getCreditNotes, getInvoices } from "@/api/property-manager/invoices";
import RentalCreditNotesTable from "@/views/components/property-manager/RentalCreditNotesTable.vue";

const selected = ref([]);
const invoices = ref([]);
const credit_notes = ref([]);
const units = ref([]);
const property = ref([]);
const isLoading = ref(false);
const activeName = ref("units");
const handleClick = () => {};

export default {
  components: {
    RentalCreditNotesTable,
    // eslint-disable-next-line vue/no-unused-components
    EmptyStateTable,
    eTimsUnitsTable,
    RentalInvoicesTable,
    Loader,
  },
  setup() {
    const route = useRoute();
    const multipleTableRef = ref();

    const fetchData = async () => {
      const propertyId = route.params.property_id;
      isLoading.value = true;
      try {
        const response = await fetchLeasedUnits(propertyId);
        const propertyInfo = await fetchPropertyDetails(propertyId);
        property.value = propertyInfo.data;
        units.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchInvoices = async () => {
      isLoading.value = true;
      const propertyId = route.params.property_id;
      try {
        const response = await getInvoices(propertyId);
        invoices.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchCreditNotes = async () => {
      isLoading.value = true;
      const propertyId = route.params.property_id;
      try {
        const response = await getCreditNotes(propertyId);
        credit_notes.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    watch(activeName, async (newTab) => {
      if (newTab === "units") {
        await fetchData();
      } else if (newTab === "invoices") {
        await fetchInvoices();
      } else if (newTab === "credit_notes") {
        await fetchCreditNotes();
      }
    });

    return {
      activeName,
      handleClick,
      isLoading,
      multipleTableRef,
      fetchData,
      property,
      selected,
      capitalized,
      dateTime,
      units,
      invoices,
      credit_notes,
      fetchInvoices,
      fetchCreditNotes,
    };
  },
};
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
</style>
