import { api } from "../";
export const fetchLandlords = async() => {
    let response = await api
        .get("landlords")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLandlordProperties = async(id) => {
    let response = await api
        .get(`landlord/properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLandlordDetails = async(id) => {
    let response = await api
        .get(`landlords/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchManagerNotPaginatedLandlords = async() => {
    let response = await api
        .get("manager/landlords")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchManagerLandlords = async(search, page) => {
    var url = "manager/paginated/landlords";
    if (search)
        url =
            url +
            "&searchFields=name:like;email:like;email:like;phone:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace("manager/landlords?", "manager/landlords?");
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createLandlord = async(postBody) => {
    let response = await api
        .post("landlords", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateLandlord = async(id,body) => {
    let response = await api
        .patch("landlords/" + id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changeLandlordStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/landlord`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeLandlord = async(id) => {
    let response = await api
        .delete(`landlords/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
