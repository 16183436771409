<template>
  <div class="card">
    <hr class="horizontal dark" />
    <div class="px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <div class="card-body">
          <el-table
              ref="multipleTableRef"
              :data="tableData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="unit.unitCode" label="Unit Code" width="300" />
            <el-table-column prop="unit.rentalRate" label="Rental Rate" show-overflow-tooltip />
            <el-table-column prop="unit.serviceCharge" label="Service Charge" show-overflow-tooltip />
            <el-table-column prop="unit.createdAt" label="Date Created" show-overflow-tooltip />
            <el-table-column label="Actions">
              <template #default="scope">
                <el-button type="danger" @click="handleView(scope.row)" style="display: block;">Delete</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="d-flex justify-content-center mt-5">
          <pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
          />
        </div>

        <div class="card--footer" style="margin-top: 0.2px; margin-bottom: 80px">
          <el-button
              type="primary"
              @click="toggleSelection()"
          >Clear selection</el-button>
          <el-button
              type="danger"
              :loading="isLoading"
              @click="submitChecked()"
          >Delete</el-button>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {h, reactive, ref, toRefs, watch} from 'vue';
import moment from "moment/moment";
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import { removeUnit, deleteUnits } from "../../../api/property-manager/units";

const tableData = ref([]);
const property = ref([]);
const isLoading = ref(false);
export default {
  components: { Pagination },
  props: {
    units: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    propertyId: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {

    const { units, refresh, searchQuery } = toRefs(props);

    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = units.value?.total || 0;
      const per_page = units.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
      console.log(total_records)
    };

    watch(units, () => {
      updateTotalPages();
      Pagination.current_page = units.value?.current_page || 1;
      tableData.value = units.value.data.map(transformData);
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: units.value.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };
    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });

    const transformData = (unit) => ({
      ...unit,
      unit: {
        unitId: unit.unitId,
        unitCode: unit.unitCode,
        rentalRate: unit.hoaFee,
        serviceCharge: unit.garbage,
        createdAt: unit.lastUpdated,
      },
    });

    const multipleTableRef = ref();
    const multipleSelection = ref([]);

    const toggleSelection = (rows) => {
      if (rows) {
        rows.forEach((row) => {
          multipleTableRef.value?.toggleRowSelection(row, undefined);
        });
      } else {
        console.log(multipleSelection.value)
        multipleTableRef.value?.clearSelection();
      }
    };

    const handleSelectionChange = (val) => {
      multipleSelection.value = val.map(item => {
        return {
          ...item,
          unitId: item.unitId
        };
      });
    };

    const handleView = async (row) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The unit will be deleted. Continue?"
          ),

          "Delete Unit",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeUnit(row.unitId);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const submitChecked = () => {
      const selectedLeaseIds = multipleSelection.value.map(item => item.unitId);
      console.log("My:",selectedLeaseIds)
      isLoading.value = true;
      const formData = new FormData();
      formData.append("unitId", selectedLeaseIds);
      deleteUnits(formData)
          .then((data) => {
            if (data.success) {
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
              props.refresh()
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {
            multipleTableRef.value?.clearSelection();
            isLoading.value = false;
            emit("refresh");
          });
    };

    return {
      Pagination,
      onPageChange,
      isLoading,
      multipleTableRef,
      multipleSelection,
      toggleSelection,
      handleSelectionChange,
      handleView,
      tableData,
      property,
      capitalized,
      dateTime,
      submitChecked
    };
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}

.card-body {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>

