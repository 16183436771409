<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-users text-primary text-md opacity-10"></i>
          <h6>Available Roles</h6>
        </div>
        <div class="cta">
          <router-link
              :to="{ path: `/roles/form` }"
          >
            <button v-if="hasPermission('create.roles')"  class="btn mb-0 btn-success btn-xs null null ms-auto">
              <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
              Role
            </button>
          </router-link>
        </div>
      </div>
      <div v-if="isLoading"><Loader /></div>
      <div class="card-body pb-2" v-else>
        <section class="card-area">
          <div class="row mb-4" v-for="role in roles" :key="role.id">
            <div class="col-md-2">
              <section class="card-section mt-md-4">
                <div class="role-card">
                  <div class="flip-card">
                    <div class="flip-card__container">
                      <div class="card-front">
                        <div
                          class="card-front__tp"
                          :class="`card-front__tp--${role.name}`"
                        >
                          <i class="fa fa-lock fa-2x" />

                          <h2 class="card-front__heading text-uppercase">
                            {{ ucfirst(role.name) }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-10">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h6 class="text-uppercase">{{ ucfirst(role.name) }} Permissions</h6>
                    <div class="d-flex">
                      <div  class="card-front__text-view card-front__text-view&#45;&#45;  mb-2">
                        <button  v-if="hasPermission('update.roles')" @click="updateRole(role)" class="btn mb-0 btn-primary btn-xs null null me-2">
                          <i class="fa fa-pencil-square-o text-white me-1" aria-hidden="true"></i>
                          Update Role
                        </button>
                      </div>
                      <div  class="card-front__text-view card-front__text-view&#45;&#45;  mb-2">
                        <button  v-if="hasPermission('delete.roles')" @click="deleteRole(role.id)" class="btn mb-0 btn-danger btn-xs null null">
                          <i class="fa fa-trash-o text-white me-1" aria-hidden="true"></i>
                          Delete Role
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3" v-for="(permission) in dividedRows(role.permissions)" :key="permission.id">
                      <ol>
                        <li v-for="(perm) in permission" :key="perm.id">
                          {{ capitalizedText(perm.name) }}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {h, ref} from "vue";
import { fetchRoles } from "../../api/services/authentication";
import {ElMessage, ElMessageBox} from "element-plus";
import { removeRole } from "../../api/property-manager/roles";
import Loader from "../components/Loader.vue";
import { useRouter } from "vue-router";

const roles = ref([]);
const isLoading = ref(false);
export default {
  name: "roles",
  components: {Loader},
  setup() {
    const router = useRouter(); // Get the router instance

    const fetchData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchRoles();
        roles.value = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const ucfirst = (value) => {
      if (!value) return "";
      return  value.charAt(0).toUpperCase() + value.slice(1);
    };
    const dividedRows = (permissions) => {
      const columnsPerRow = 10;
      const rows = [];
      console.log(roles.value);
      for (let i = 0; i < permissions.length; i += columnsPerRow) {
        const row = permissions.slice(i, i + columnsPerRow);
        rows.push(row);
      }

      return rows;
    };

    const capitalizedText = (text) => {
      if (text.includes('_')) {
        const splitText = text.split('_');
        const lowercaseParts = splitText.map((part) => part.toLowerCase());
        return lowercaseParts.join(' ');
      } else {
        return text.toLowerCase();
      }
    };

    const updateRole = (role) => {
      console.log(role)
      router.push({ name: "Roles Form", query: { role: JSON.stringify(role) } });
    }

    const deleteRole = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "Role will be deleted. Continue?"
          ),

          "Delete Role",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeRole(id);

          if (success) {
            fetchData();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    return {
      updateRole,
      deleteRole,
      dividedRows,
      ucfirst,
      roles,
      capitalizedText,
      isLoading,
      fetchData,
    };
  },
  mounted() {
    this.fetchData();
  },
  data(){
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
  },
  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  }
};
</script>
<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
</style>
<style scoped>
.card-front__heading {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 600;
}

.role-card[data-v-302c8cdd] {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: -0.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
  width: 100% !important;
}

.card-front__text-view {
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.2rem;
}

.card-front__text-view--admin {
  color: #ff62b2;
}

.card-front__text-view--doctor {
  color: #2aaac1;
}

.card-front__text-view--nurse {
  color: #8fb129;
}
.card-front__text-view--receptionist {
  color: #fa7f67;
}

/* Front cover */

.card-front__tp {
  color: #fafbfa;
}

.card-front__icon {
  fill: #fafbfa;
  font-size: 3vw;
  height: 3.25rem;
  margin-top: -0.5rem;
  width: 3.25rem;
}

.main {
  background: linear-gradient(to bottom right, #eee8dd, #e3d9c6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.card-section {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.role-card {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: -0.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.5);
  width: 7em;
}

.card-front {
  background-color: #fafbfa;
}

.card-front__tp {
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem;
  border-radius: 4px;
}

.card-front__tp--admin {
  background: linear-gradient(to bottom, #ff73b9, #ff40a1);
}

.card-front__tp--nurse {
  background: linear-gradient(to bottom, #bacd82, #8fb129);
}

.card-front__tp--doctor {
  background: linear-gradient(to bottom, #47c2d7, #279eb2);
}

.card-front__tp--receptionist {
  background: linear-gradient(to bottom, #fb9b88, #f86647);
}

/* Front card's bottom section */
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
}
ol {
  list-style: none;
  padding: 1rem 0;
}

ol li:before {
  content: "✓";
  color: green;
}
</style>
