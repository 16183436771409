<template>
    <div class="card">
      <div class="card-header pb-0 mb-5">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-grav text-primary text-md opacity-10"></i>
          <h6>Tenants</h6>
        </div>
        <div class="cta">
          <FilterTablesForm
              :show_tenants_table_filter="true"
              :filter_init="filter_params"
              :filterCallBack="_filterCallBack"
          />
          <button
            class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button"
            @click="newTenant"
          >
            <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
            Tenant
          </button>
          <el-button-group>
            <el-button
                type="info"
                @click="submitExcelForm(accountFormRef)"
            >
              <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
            </el-button>
            <el-button
                type="warning"
                @click="downloadPdf(accountFormRef)"
            >
              <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
            </el-button>
          </el-button-group>
        </div>
      </div>
      <div class="card-body">
        <div v-if="tenants.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
              <tr>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Tenant Name
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Phone Number
                </th>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Tenant Email
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Created Date
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in tenants.data" :key="item.tenantId + index">
                <td>
                  <div class="d-flex px-2 py-1v">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.firstName + " " + item.lastName
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h5 class="mb-0 text-sm">
                        {{ item.firstName + " " + item.lastName }}
                      </h5>
                      <h6 v-if="item?.leases[0]?.unit?.property" class="text-sm text-secondary mb-0">{{ item?.leases[0]?.unit?.property?.propertyName }}</h6>
                      <p v-if="item?.leases[0]?.unit" class="text-xs text-secondary mb-0">{{ item?.leases[0]?.unit.unitCode }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.primaryPhone }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.email }}
                  </p>
                </td>

                <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(parseInt(item.isLeased))"
                  >{{ parseInt(item.isLeased) === 0 ? "Unleased" : "Leased" }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.createDate)
                    }}</span>
                </td>

                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <router-link
                            :to="{ path: `/unit/${item?.leases[0]?.unit.unitId }` }"
                            class="text-secondary"
                            v-if="item?.leases[0]?.unit && parseInt(item.isLeased) === 1"
                        ><el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                          View</el-dropdown-item
                        ></router-link
                        >
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="editTenant(item)"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          Edit
                        </el-dropdown-item>

                        <el-dropdown-item
                            v-if="parseInt(item.isLeased) === 0"
                            class="text-secondary font-weight-bold text-xs"
                            @click="leaseTenant(item.tenantId)"
                        ><i class="fa fa-check" aria-hidden="true"></i>
                          Lease
                        </el-dropdown-item>

                        <el-dropdown-item
                            v-if="item.isLeased === 0"
                            class="text-secondary font-weight-bold text-xs"
                            @click="deleteTenant(item.tenantId)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-center mb-3">
          <pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>

    <TenantsForm
      v-if="showModal"
      @close-modal="showModal = false"
      :tenant="tenantDetails"
      @refresh-tenants="refresh"
    />

    <LeaseTenantForm
        v-if="showLeaseTenantModal"
        @close-modal="showLeaseTenantModal = false"
        :tenantId="tenantId"
        @refresh-tenants="refresh"
      />
  </template>
  
  <script>
  import TenantsForm from "../../../components/Modals/property-manager/TanantForm.vue";
  import LeaseTenantForm from "../../../components/Modals/property-manager/LeaseTenantForm.vue";
  import EmptyStateTable from "../../components/EmptyTableState.vue";
  import Pagination from "../property-manager/Pagination.vue";
  import moment from "moment";
  import { ref, h, reactive } from "vue";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { removeTenant } from "@/api/property-manager/tenants";
  import FilterTablesForm from "../landlord/FilterTablesForm.vue";
  
  import {
    downloadTenantsExcel,
    downloadTenantsPdf
  } from "../../../api/property-manager/downloads";

  const showModal = ref(false);
  const showLeaseTenantModal = ref(false);
  const tenantDetails = ref({});
  const tenantId = ref({});
  const modalData = ref({});
  const isLoading = ref(false);
  const filter_params = ref({});
 

  export default {
    name: "landlord-tenants-table",
    props: {
      tenants: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      refresh: {
        type: Function,
        required: true,
      }
    },
    components: {
      FilterTablesForm,
      TenantsForm,
      EmptyStateTable,
      LeaseTenantForm,
      Pagination
    },
  
    setup(props) {

      const total_records = props?.tenants?.total || 0;
      const per_page = props?.tenants?.per_page || 0;
      const total_pages = ref(0);
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      }

      const Pagination = reactive({
        current_page: props?.tenants?.current_page || 1,
        total_pages: total_pages,
        per_page: per_page,
      });
      const onPageChange = (page) => {
        props.refresh(filter_params, page);
      };
      const _filterCallBack = (filter_data) => {
        filter_params.value = filter_data;
        props.refresh(filter_params, 0);
      };
      const onSelectHandler = () => {};
      const getInitials = (name) => {
        if (name) {
          const nameSplit = name?.split(" ");
          const firstInitials = nameSplit[0]?.charAt(0);
          const secondInitials = nameSplit[1]?.charAt(0);
  
          return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
          );
        }
        return "";
      };
      const getStatusColor = (isLeased) => {
        switch (isLeased) {
          case 0:
            return "bg-gradient-danger";
          case 1:
            return "bg-gradient-success";
  
          default:
            return null;
        }
      };
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };
  
      const editTenant = (tenant) => {
        tenantDetails.value = tenant;
        console.log(tenantDetails)
        showModal.value = true;
      };
  
      const newTenant = () => {
        tenantDetails.value = {};
        showModal.value = true;
      };

      const leaseTenant = async (id) => {
        tenantId.value = id;
        showLeaseTenantModal.value = true;
      }

      const deleteTenant = async (id) => {
        ElMessageBox.confirm(
          h(
            "div",
            { style: "color: black, font-size: 14px" },
            "The tenant will be deleted. Continue?"
          ),
  
          "Delete Tenant",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
        ).then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await removeTenant(id);
  
            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        });
      };
      

    async function submitExcelForm(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadTenantsExcel(filter_params)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenants_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

   async function downloadPdf(){
    try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadTenantsPdf(filter_params)
            .then((Response) => {
              console.log('started downloading');
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenants_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

  
      return {
        submitExcelForm,
        downloadPdf,
        onPageChange,
        Pagination,
        dateTime,
        getStatusColor,
        getInitials,
        showModal,
        showLeaseTenantModal,
        modalData,
        tenantDetails,
        tenantId,
        editTenant,
        newTenant,
        isLoading,
        leaseTenant,
        deleteTenant,
        onSelectHandler,
        filter_params,
        _filterCallBack
      };
    },
    data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    }, 
  };
  </script>
  
  <style scoped>
  .cta button {
    margin-right: 10px;
  }
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
  }
  .card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }

  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #5f924d;
    border-radius: 50px;
  }
  .sticky-top th {
    color: #fff !important;
  }

  h6 {
    margin: 0;
  }
  </style>
  