import { api } from "../";
const landlord_url = (root, filter, page) => {
    var url = root;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.landlord_name)
        url = url + "&landlord_name=" + filter.value.landlord_name;

    if (filter.value != undefined && filter.value.propertyManagerId)
    url = url + "&propertyManagerId=" + filter.value.propertyManagerId;

    return url;
};
export const fetchLandlords = async(filter, page) => {
    let response = await api
        .get(landlord_url("/admin/landlords?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLandlordProperties = async(id) => {
    let response = await api
        .get(`landlord/properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLandlordDetails = async(id) => {
    let response = await api
        .get(`landlords/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchManagerNotPaginatedLandlords = async() => {
    let response = await api
        .get("/admin/landlords")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchManagerLandlords = async(filter, page) => {
    let response = await api
        .get(landlord_url("/admin/landlords?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createLandlord = async(postBody) => {
    let response = await api
        .post("landlords", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateLandlord = async(id,body) => {
    let response = await api
        .patch("landlords/" + id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changeLandlordStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/landlord`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeLandlord = async(id) => {
    let response = await api
        .delete(`landlords/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
