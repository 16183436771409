<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-home text-primary text-md opacity-10"></i>
        <h6>Service Requests</h6>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="serviceRequest.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-else>
        <div class="card-table">
          <table class="table align-items-center mb-0">
            <thead>
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Property Name
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Tenant Name
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Request Description
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date Requested
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date Actioned
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in serviceRequest.data" :key="item.propertyId + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.propertyName
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.propertyName }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item?.landlords?.name }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.propertyAddress }}</p>
              </td>

              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ item.unitCount }}</p>
              </td>

              <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(parseInt(item.active))"
                  >{{ parseInt(item.active) === 0 ? "Deactivated" : "Active" }}</span
                  >
              </td>
              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.lastUpdated)
                    }}</span>
              </td>
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          @click="editProperty(item)"
                      ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        Assign
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
          :totalPages="Pagination.total_pages"
          :perPage="Pagination.per_page"
          :currentPage="Pagination.current_page"
          @pagechanged="onPageChange"
      />
    </div>
  </div>
  <PropertiesForm
      v-if="showModal"
      @close-modal="showModal = false"
      :property="propertyDetails"
      @refresh-properties="refresh"
  />

</template>

<script>
import PropertiesForm from "../../../components/Modals/property-manager/PropertyForm.vue";
import EmptyStateTable from "../../components/EmptyTableState.vue";
import moment from "moment";
import {ref, reactive} from "vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";

const showModal = ref(false);
const propertyDetails = ref({});
const modalData = ref({});
const isLoading = ref(false);

export default {
  name: "service-request-table",
  props: {
    serviceRequest: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    Pagination,
    PropertiesForm,
    EmptyStateTable,
  },

  setup(props) {

    const total_records = props?.serviceRequest?.total || 0;
    const per_page = props?.serviceRequest?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.serviceRequest?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
      props.refresh(Filter.searchquery, page);
    };
    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });
    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const editProperty = (property) => {
      propertyDetails.value = property;
      showModal.value = true;
    };

    return {
      onPageChange,
      Pagination,
      dateTime,
      getStatusColor,
      getInitials,
      showModal,
      modalData,
      propertyDetails,
      editProperty,
      isLoading,
      onSelectHandler,
    };
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
  padding: 1.5rem;
}
h6 {
  margin: 0;
}
</style>
