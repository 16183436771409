<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-tint text-primary me-2" />Create Water Bill </div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="waterFormRef"
          :model="waterForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <el-form-item label="Previous Reading" prop="previous_reading">
                <el-input
                    v-model="waterForm.previous_reading"
                    placeholder="Please input previous reading"
                    />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <el-form-item label="Current Reading" prop="current_reading">
                <el-input
                    v-model="waterForm.current_reading"
                    placeholder="Please input current reading"
                    clearable />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <el-form-item label="Units Used" prop="units_used">
                <el-input
                    v-model="waterForm.units_used"
                    disabled
                />
              </el-form-item>
            </div>

            <div class="col-md-6 mb-3">
              <el-form-item label="Per Units Rate" prop="units_rate">
                <el-input
                    v-model="waterForm.units_rate"
                    clearable
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Amount" prop="amount">
                <el-input
                    v-model="waterForm.amount"
                    disabled />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(waterFormRef)"
          >Create Water Bill</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch} from "vue";
import { createWater} from "@/api/property-manager/waters";
import { ElNotification } from "element-plus";

const waterFormRef = ref();
const isLoading = ref(false);

export default {
  name: "water-form",

  props: {
    property: {
      type: Object,
      required: false,
    },
    propertyId: {
      type: Object,
      required: false,
    },
    unitId: {
      required: false,
    },
    unitRate: {
      required: true,
    },
    previousReading: {
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    refresh_rates: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const waterForm = reactive({
      current_reading: "",
      previous_reading: props.previousReading || 0,
      units_used: "",
      units_rate: props.unitRate || "",
      amount: "",
    });

    const rules = reactive({

      current_reading: [
        {
          required: true,
          message: "Please input current reading",
          trigger: ["blur", "change"],
        },
      ],

      previous_reading: [
        {
          required: true,
          message: "Please input previous reading",
          trigger: ["blur", "change"],
        },
      ]
    });

    watch(
      () => waterForm.current_reading, 
      (newValue, oldValue) => {
        console.log('Current Reading Changed:', oldValue, '->', newValue);
        waterForm.units_used = newValue - waterForm.previous_reading;
      }
      
    );
    watch(
      () => waterForm.previous_reading, 
      (newValue, oldValue) => {
        console.log('Current Reading Changed:', oldValue, '->', newValue);
        waterForm.units_used = waterForm.current_reading - newValue;
      }
    );
    watch(
      () => waterForm.units_used, 
      (newValue, oldValue) => {
        console.log('Current Reading Changed:', oldValue, '->', newValue);
        waterForm.amount = newValue * waterForm.units_rate;
      }
    );
    watch(
      () => waterForm.units_rate, 
      (newValue, oldValue) => {
        console.log('Current Reading Changed:', oldValue, '->', newValue);
        waterForm.amount =  waterForm.units_used * newValue;
      }
    );


    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("current", waterForm.current_reading);
          formData.append("previous", waterForm.previous_reading);
          formData.append("total", waterForm.amount);
          formData.append("diff", waterForm.units_used);
          formData.append("per_unit", waterForm.units_rate);
          formData.append("unitId", props.unitId);
          console.log(formData);

          createWater(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("refresh_rates");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      waterForm,
      submitForm,
      rules,
      waterFormRef,
      isLoading,
      props
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 580px;
  max-height: 480px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
