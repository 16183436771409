<template>
    <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
    >
      <el-menu-item index="2">
        <i class="fa fa-th text-primary text-sm opacity-10 me-2"></i>
        <router-link to="/landlord-dashboard" class="text-sm"
        >Dashboard </router-link>
      </el-menu-item>
  
      <el-sub-menu 
      index="16"
      >
        <template #title>
          <i class="fa fa-users text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
          <span>System Users</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/landlord-tenants" class="text-sm">
            <el-menu-item index="16-1">
              <i class="fa fa-user text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
              Tenants</el-menu-item></router-link>

        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="17">
        <template #title>
          <i class="fa fa-home text-sm opacity-10 text-primary me-2"></i>
          <span>Properties</span>
        </template>
        <el-menu-item-group title="">
          <router-link to="/landlord-properties">
            <el-menu-item index="17-1" >
              <i class="fa fa-building text-sm opacity-10 text-primary me-2"></i>
              Properties
            </el-menu-item> 
          </router-link>
          <router-link to="/landlord-closed-leases">
            <el-menu-item index="17-2">
              <i class="fa fa-ban text-sm opacity-10 text-primary me-2"></i>
              Closed Leases</el-menu-item>
            </router-link>
            
        </el-menu-item-group>
      </el-sub-menu>
      <el-sub-menu index="18">
      <template #title>
        <i class="fa fa-money text-sm opacity-10 text-primary me-2"></i>
        <span>Payments</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/landlord-payments"><el-menu-item index="18-2">
          <i class="fa fa-credit-card-alt text-sm opacity-10 text-primary me-2"></i>
          Payments</el-menu-item>
        </router-link>
      </el-menu-item-group>
    </el-sub-menu>
      <hr class="my-3 horizontal dark" />
      <el-menu-item index="2">
        <i class="fa fa-cog text-primary text-sm opacity-10 me-2"></i>
        <router-link to="/settings" class="text-sm">Business Settings</router-link>
      </el-menu-item>
  
    </el-menu>
  </template>
  <script>
  export default {
    name: "LandlordNav",
  
    props: {
      cardBg: String,
    },
    data() {
      const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
  
      const userType = localStorage.getItem("userType");
  
      return {
        title: "PropertyManagement",
        controls: "dashboardsMain",
        isActive: "active",
        userType: userType,
        userPermissions: storedPermissions || [],
  
      };
    },
    methods: {
      getRoute() {
        const routeArr = this.$route.path.split("/");
        return routeArr[1];
      },
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  $color-emperor: #4f4f4f;
  $color-mine-shaft: #333333;
  $color-bondi-blue: #344767;
  $left-spacing: 20px;
  .collapsible-menu {
    font-size: 14px;
    padding: 0;
    margin: 0;
    overflow: hidden;
  
    .check {
      position: absolute;
      user-select: none;
      pointer-events: none;
      opacity: 0;
  
      &:checked {
        ~ ul {
          opacity: 1;
          max-height: 9999em;
          pointer-events: all;
          transition: opacity 0.15s ease-in-out;
  
          > li {
            margin: 12px 0;
          }
        }
  
        + label {
          > .icon-wrapper {
            > .icon-angle {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  
    ul,
    li {
      list-style: none;
    }
  
    ul {
      opacity: 0;
      max-height: 0;
      pointer-events: none;
      padding: 0;
      overflow: hidden;
  
      li {
        margin: 0;
        transition: 0.15s ease-out;
        padding-left: $left-spacing;
      }
    }
  
    // collapsible menu
    label {
      font-weight: 600;
      color: #344767;
      user-select: none;
      cursor: pointer;
      transition: 0.15s ease-in-out;
      margin-bottom: 0;
      width: 100%;
      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1rem;
  
        .icon-angle {
          transform-origin: center;
          transition: 0.15s ease-in-out;
        }
      }
    }
  
    > li {
      &.active {
        overflow: hidden;
        box-shadow: none;
        color: #344767;
  
        > label {
          background-color: #f6f9fc;
          border-radius: 0.5rem;
        }
      }
  
      &:not(.active) {
        .check {
          &:checked {
            ~ ul {
              margin-top: -8px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  
  a {
    padding-left: 0 !important;
  }
  </style>