<template>
  <div class="card">
    <div class="card-header">
      <button v-if="hasPermission('create.penalties')" @click="newExpense" class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button">
        <i class="fas fa-plus  me-1" aria-hidden="true"></i>New Penalty
      </button>
    </div>
    <div class="row">
      <div class="card-body">
        <div  v-if="penalties.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start">
                    Description
                  </th>

                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Amount
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Created Date
                  </th>

                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Action
                </th>
              </tr>
              </thead >
              <tbody>
              <tr v-for="(item, index) in penalties.data" :key="item.penaltyId + index">
                <td>
                  <div class="d-flex px-2 py-1">

                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item.penalty_name }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ formatNumber( item.penalty_amount) }}</p>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">
                    {{
                      dateTime(item.penalty_date)
                    }}
                  </span>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                                      <span class="el-dropdown-link">
                                        <i class="fa fa-ellipsis-v text-primary"></i>
                                      </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            v-if="hasPermission('delete.penalties')"
                            class="text-secondary font-weight-bold text-xs"
                            @click="deletePenalty(item.penaltyId)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <Pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
      <hr class="horizontal dark" />
    </div>
  </div>
  <PenaltyForm
      v-if="showModal"
      @close-modal="showModal = false"
      :unitId="unitId"
      @refresh="refresh"
  />
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import PenaltyForm from "../../../components/Modals/property-manager/PenaltyForm.vue";
import { h, reactive, ref, toRefs, watch } from "vue";
import {ElMessage, ElMessageBox} from "element-plus";
import { removePenalty } from "@/api/property-manager/penalties";
import moment from "moment/moment";



const showModal = ref(false);
const isLoading = ref(false);
export default {
  name: "PenaltiesTable",
  components: {PenaltyForm, EmptyStateTable, Pagination},
  props: {
    penalties: {
      type: Object,
      required: true,
    },
    unitId: {
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    }
  },
  setup(props){

    const { penalties, refresh } = toRefs(props);
    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = penalties.value?.total || 0;
      const per_page = penalties.value?.per_page || 10;
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(penalties, () => {
      updateTotalPages();
      Pagination.current_page = penalties.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: penalties.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(page);
    };

    const newExpense = () => {
      showModal.value = true;
    }
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };
    const deletePenalty = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The penalty will be deleted. Continue?"
          ),

          "Delete Penalty",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removePenalty(id);

          if (success) {
            props.refresh();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

  

    return {
      newExpense,
      showModal,
      props,
      deletePenalty,
      getInitials,
      dateTime,
      Pagination,
      onPageChange,
      formatNumber
    }
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },

}
</script>
<style scoped>
.suspend-button {
  position: absolute;
  right: 50px;
}

.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>