<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <admin-tenants-table
          :tenants="tenants"
          :refresh="fetchData"
          :searchQuery="searchTerm"
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdminTenantsTable from "../components/admin/AdminTenantsTable.vue";
import Loader from "../components/Loader.vue";
import { fetchTenants } from "../../api/admin/tenants";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const tenants = ref([]);
const searchTerm = ref("");
export default {
  name: "admin tenants",
  components: {
    AdminTenantsTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchTenants(entity_params, page);
        tenants.value = response.data;
        console.log(response.data)
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      tenants,
      isLoading,
      searchTerm
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>
