import {api} from "@/api";

export const createWater = async(body) => {
    let response = await api
        .post("waters", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchData = async(unitId) => {
    let response = await api
        .get(`water-reading?unitId=${unitId}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const removeWater = async(id) => {
    let response = await api
        .delete(`waters/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}