import {api} from "../";

const property_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=propertyName:like;propertyAddress:like;propertyManagerId:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;


    if (filter.value != undefined && filter.value.propertyManagerId)
        url = url + "&propertyManagerId=" + filter.value.propertyManagerId;

    if (filter.value != undefined && filter.value.property_name)
        url = url + "&property_name=" + filter.value.property_name;

        url = url.replace("admin/paginated/properties?", "admin/paginated/properties?");

    return url;
};


export const fetchProperties = async() => {
    let response = await api
        .get("admin/properties")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchManagersProperties = async() => {
    let response = await api
        .get("admin/properties")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchPaginatedManagersProperties = async(filter, page) => {
    return await api
        .get(property_url("admin/paginated/properties?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};

export const fetchPropertyDetails = async(id) => {
    let response = await api
        .get(`properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createProperty = async(postBody) => {
    let response = await api
        .post("properties", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateProperty = async(body) => {
    let response = await api
        .patch("properties/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changePropertyStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/property`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeProperty = async(id) => {
    let response = await api
        .delete(`properties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}




// const expected_url = (root, filter, page) => {
//     var url = root;

//     if (filter.value != undefined && filter.value.search_query)
//         url =
//             url +
//             "&searchFields=log:like;device:like;slug:like;&search=" +
//             filter.value.search_query;

//     if (page) url = url + "&page=" + page;

//     if (filter.value != undefined && filter.value.user_name)
//         url = url + "&user_name=" + filter.value.user_name;

//     if (filter.value != undefined && filter.value.date_range)
//          url = url + "&date_range=" + filter.value.date_range;


//     url = url.replace("total-expected-amount?&", "total-expected-amount?");

//     return url;
// };
export const totalExpectedAmount = async(filter, page, id) => {
    let response = await api
        .get("total-expected-amount?id="+id, filter, page)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};