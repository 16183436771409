<template>
    <div class="row mt-5" v-if="isLoading"><Loader/></div>
    <div class="row mt-5" v-else>
      <div class="col-md-8">
        <div class="card">
          <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
            <template #icon>
              <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
            </template>
          </el-page-header>
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0 text-dark text-bold">
                {{
                  propertyDetails?.propertyName
                }}
              </p> 
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Property Information</p>
            <div class="row">
              <div class="col-md-6">
                <dl>
                  <dt>Property Name</dt>
                  <dd>{{ capitalized(propertyDetails?.propertyName) }}</dd>
                  <dt v-if="propertyDetails?.landlords?.name">Landlord Name</dt>
                  <dd v-if="propertyDetails?.landlords?.name">{{ capitalized(propertyDetails?.landlords?.name) }}</dd>
                  <dt>Property Location</dt>
                  <dd>{{ capitalized(propertyDetails?.propertyAddress) }}</dd>
                  <dt>Property Location</dt>
                  <dd>{{ capitalized(propertyDetails?.propertyAddress) }}</dd>
                  <dt>Created Date</dt>
                  <dd>{{ dateTime(propertyDetails?.lastUpdated) }}</dd>
                  <dt v-if="propertyDetails?.landlord_kra_pin">Landlord Kra Pin</dt>
                  <dd v-if="propertyDetails?.landlord_kra_pin">{{ propertyDetails?.landlord_kra_pin }}</dd>
                </dl>
              </div>
              <div class="col-md-6">
                <dl>
                  <dt v-if="propertyDetails?.kra_rate">Kra Rate</dt>
                  <dd v-if="propertyDetails?.kra_rate">{{ propertyDetails?.kra_rate }} %</dd>
                  <dt>Units Counts.</dt>
                  <dd>{{ propertyDetails?.unitCount }} (&nbsp;100 %&nbsp;)</dd>
                  <dt>Occupied Units</dt>
                  <dd>{{ propertyDetails?.leasedUnitCount }} (&nbsp;{{ propertyDetails?.leasedUnitPercentage }} %&nbsp;)</dd>
                  <dt>Vacant Units</dt>
                  <dd>{{ propertyDetails?.UnleasedUnitsCount }} (&nbsp;{{ propertyDetails?.UnleasedUnitPercentage }} %&nbsp;)</dd>
                  <dt>Total Rent</dt>
                  <dd>{{ propertyDetails?.sumRentExpected }}</dd>
                </dl>
              </div>
            </div>
  
            <hr class="horizontal dark" />
            <p class="text-uppercase text-sm">Property Settings</p>
            <div class="contant-info">
              <dl>
                <dt class="dd-with-button">Water Rate</dt>
                <dd class="dd-with-button">ksh {{ propertyDetails.water_rating != null ? propertyDetails.water_rating : 0 }} <button v-if="hasPermission('update.water')" type="button" @click="updateSettings('Water Rate')" class="btn btn-success">Update</button></dd>
                <dt class="dd-with-button">Tax</dt>
                <dd class="dd-with-button">{{ propertyDetails.vat != null ? propertyDetails.vat : 0}} % <button type="button" @click="updateSettings('Tax')" class="btn btn-success">Update</button></dd>
                <dt class="dd-with-button">Agent Commission</dt>
                <dd class="dd-with-button">{{ propertyDetails.commision_rate != null ? propertyDetails.commision_rate : 0 }} %<button  v-if="hasPermission('update.commisions')" type="button" @click="updateSettings('Commission')" class="btn btn-success">Update</button></dd>
                <dt class="dd-with-button">Service Charges</dt>
                <dd class="dd-with-button">ksh {{ propertyDetails.service_charge != null ? propertyDetails.service_charge : 0 }} <button v-if="hasPermission('update.unit_charges')" type="button" @click="updateSettings('Service Charges')" class="btn btn-success">Update</button></dd>
                <dt class="dd-with-button">Penalty Rate</dt>
                <dd class="dd-with-button">{{ propertyDetails.penalties != null ? propertyDetails.penalties : 0 }} % <button v-if="hasPermission('update.penalties')" type="button" @click="updateSettings('Penalty Rate')" class="btn btn-success">Update</button></dd>
              </dl>
            </div>
  
            <hr class="horizontal dark" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0 text-dark text-bold">Current Month Statistics</p>
            </div>
          </div>
          <div class="card-body sidebar">
            <img
              alt="Image placeholder"
              src="@/assets/img/stetho.jpg"
              class="side-glance"
            />
            <dl>
              <router-link to="#"><dt>Total Expected Amount</dt></router-link>
              <dd>{{ propertyDetails.totalExpected }}</dd>
              <router-link to="#"><dt>Total Collected</dt></router-link>
              <dd>{{ propertyDetails.sumAmountCollected }}</dd>
              <router-link :to="{ path: `/property-month-balance/${property_id}` }"><dt>Total Balance</dt></router-link>
                <dd>{{ propertyDetails.totalBalance }}</dd>
            </dl>
            <div v-if="
                hasPermission('manage.units') ||
                hasPermission('manage.payments')
                "
            class="d-flex justify-content-center align-items-center">
              <button class="btn btn-success" type="button" @click="openPropertyDrawer">More On Unit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  <PropertySettingsForm 
    v-if="showPropertySettingsModal"
    @close-modal="showPropertySettingsModal = false"
    :settings="settingsDetails"
    @refresh-settings="fetchPropertyData"
  />
  </template>
  
  <script>
  import Loader from "@/components/Loader.vue";
  import { ref } from "vue";
  import moment from "moment";
  import PropertySettingsForm from "../../../components/Modals/property-manager/PropertySettingsForm.vue"
  import { fetchPropertyDetails } from "../../../api/property-manager/properties";
  import router from "@/router";


  const isLoading = ref(false);
  const propertyDetails = ref({});
  const  settingsDetails= ref({});
  const showPropertySettingsModal = ref(false)
  const expenses = ref([]);
 
  export default {
    components: { Loader, PropertySettingsForm },
    name: "property-info",
    props: {
      property_id: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const fetchPropertyData = async () => {
        isLoading.value = true;
        try { 
          const response = await fetchPropertyDetails(props.property_id);
  
          propertyDetails.value = response.data;
          console.log()
        } catch (error) { 
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };

      const updateSettings = (settings) => {
        console.log(propertyDetails.value.propertyName)

        let passedValue = 0;
        let name = ""

        if (settings === "Tax") {
          passedValue = propertyDetails.value.vat
          name = "tax"
        } else if (settings === "Commission") {
          passedValue = propertyDetails.value.commision_rate
          name = "commision_rate"
        } else if (settings === "Service Charges") {
          passedValue = propertyDetails.value.service_charge
          name = "service_charge"
        } else if (settings === "Water Rate") {
          passedValue = propertyDetails.value.water_rating
          name = "water_rating"
        } else if (settings === "Penalty Rate") {
          passedValue = propertyDetails.value.penalties
          name = "penalties"
        }

        settingsDetails.value = {
          property_id: props.property_id,
          value: passedValue,
          name: name,
          label: settings
        };
        showPropertySettingsModal.value = true;
      };
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };
      const capitalized = (name) => {
        const capitalizedFirst = name[0].toUpperCase();
        const rest = name.slice(1);
  
        return capitalizedFirst + rest;
      };

      const openPropertyDrawer = () => {
        router.push(`/property/${props.property_id}/details`);
      }

      const goBack = () => {
        router.go(-1);
      }
     

      return {
        fetchPropertyData,
        isLoading,
        propertyDetails,
        capitalized,
        dateTime,
        updateSettings,
        settingsDetails,
        showPropertySettingsModal,
        expenses,
        goBack,
        openPropertyDrawer
      };
    },   
    data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    }, 
    created() {
      this.fetchPropertyData();
    }
  };
  </script>
  <style scoped>
    dt {
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 1.4rem;
      color: #495057;
      margin-bottom: 0.5rem;
    }
    dl {
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 20px 0;
      padding: 0 16px;
    }
    dd {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.4rem;
      color: #495057;
    }
    .sidebar dl {
      display: grid;
      grid-template-columns: 60% 40%;
      margin: 30px 0 10px;
    }
    .contant-info dl {
      display: grid;
      grid-template-columns: 30% 70%;
    }
    .side-glance {
      max-height: 180px;
      width: 100%;
      object-fit: cover;
    }
    .sidebar button {
      margin: 20px auto;
      display: block;
    }

  .dd-with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1px;
  }
 </style>
  