<template>
    <div class="card">
      <div class="card-header pb-0 mb-5 d-flex justify-content-between">
      <div class="d-flex align-items-center gap-2 ">
        <i class="fa fa-list text-primary text-md opacity-10"></i>
        <h6 class="mt-2">Outgoing SMS</h6>
      </div>
      <div class="cta">
        <!-- <FilterTablesForm
            :show_sms_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        /> -->
       </div>
      </div>
         <div class="card-body">
          <div v-if="sms.data.length === 0">
        <empty-state-table />
       </div>
       <div class="table-responsive" v-else>
         <div class="card-table">
           <table class="table align-items-center">
             <thead class="sticky-top">
             <tr>
              <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                 Tenant Name
               </th>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                Phone
               </th>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                Unit Code
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
               >
                 Message
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Status
               </th>
 
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Sent At
               </th>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Action
               </th>
             </tr>
             </thead>
             <tbody>
              <tr v-for="(item, index) in sms.data" :key="index">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex align-items-center">
                     <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                         getInitials(
                             item.tenantName
                         ).toUpperCase()
                       }}
                     </div>
                     <p class="text-xs font-weight-bold mb-0">{{ item.tenantName }}</p>
                   </div>
                 </div>
               </td>
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.tenantPhone }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.unitCode }}</p>
               </td>

               <td>
                 <p class="text-xs font-weight-bold mb-0 truncate-text">{{ item.message }}</p>
               </td>

               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.httpRes }}</p>
               </td>
 
               <td>
                <p class="text-xs font-weight-bold mb-0">{{  dateTime(item.createdAt) }}</p>
               </td>
               <td>
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="viewSms(item.message)"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          View
                        </el-dropdown-item>

                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
               </td>
             </tr>
               
             </tbody>
           </table>
         </div>
       </div>
       <div class="card-footer d-flex justify-content-center mb-3">
      <pagination
          :totalPages="paginate.total_pages"
          :perPage="paginate.per_page"
          :currentPage="paginate.current_page"
          @pagechanged="onPageChange"
        />
         <!-- <pg v-show="total_records>0" :total="total_records" :page.sync="paginate.current_page" :limit.sync="paginate.per_page" @pagination="refresh" /> -->
       </div>
     </div>
    </div>
    <OutgoingSmsForm
      v-if="showModal"
      @close-modal="showModal = false"
      :sms="smsMessage"
    />
   
 </template>
 <script  >
 import OutgoingSmsForm from "../../../components/Modals/property-manager/OutgoingSmsForm.vue";
 import Pagination from "./Pagination.vue";
 import EmptyStateTable from "../../components/EmptyTableState.vue";
 import { ref, reactive } from "vue";
 import moment from "moment";
//  import Pg from "../Pagination.vue"
//  import FilterTablesForm from "./FilterTablesForm.vue";
 const filter_params = ref({});
 const date = ref('')
 const showModal = ref(false);
 const smsMessage = ref('')
export default {
  name: "outgoing-sms-table",
  props: {
    sms: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    }
  },

  components: {
    Pagination,
    EmptyStateTable,
    // FilterTablesForm,
    OutgoingSmsForm,
    // Pg
  },

  setup(props){
  const total_records = props.sms?.total || 0;
  console.log('logs total  is', props.sms.total)
  const per_page = props.sms?.per_page || 0;
  const total_pages = ref(0);
  if (per_page > 0 && total_records > 0) {
    total_pages.value = Math.ceil(total_records / per_page);
  }

  const paginate = reactive({
    current_page: props.sms?.current_page || 1,
    total_pages: total_pages,
    per_page: per_page,
  });
  const onPageChange = (page) => {
   props.refresh(filter_params, page)
  };

  const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

  const dateTime = (value) => {
    return moment(value).format("Do MMM YYYY HH:mm:ss");
  };
  const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
  
  const viewSms = (message) => {
      smsMessage.value = message;
      showModal.value = true;
    };
  
  return {
      _filterCallBack,
      filter_params,
      onPageChange,
      paginate,
      getInitials,
      dateTime,
      date,
      showModal,
      smsMessage,
      viewSms,
      total_records
    }
  },

}
 </script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}
h6 {
  margin: 0;
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; 
    max-width: 150px; 
    display: inline-block; 
  }
  .sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
