import { api } from "../..";

export const getUserDetails = async() => {
    let response = await api
        .get("auth/user")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchPatients = async() => {
    let response = await api
        .get("patients")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchCheckIns = async() => {
    let response = await api
        .get("checkins")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPatientDetails = async(id) => {
    let response = await api
        .get(`patients/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createPatient = async(postBody) => {
    let response = await api
        .post("patients", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const updatePatient = async(body) => {
    let response = await api
        .patch("patients/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changePatientStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/patient`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const removePatient = async(id) => {
    let response = await api
        .delete(`patients/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeDoctor = async(id) => {
    let response = await api
        .delete(`delete/user/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const changeDoctorStatus = async(id) => {
    let response = await api
        .put(`deactivate/${id}/user`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const createDoctor = async(postBody) => {
    let response = await api
        .post("store/user/doctor", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createAdmin = async(postBody) => {
    let response = await api
        .post("store/user/admin", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeAdmin = async(id) => {
    let response = await api
        .delete(`delete/user/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const changeAdminStatus = async(id) => {
    let response = await api
        .put(`deactivate/${id}/user`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const createReceptionist = async(postBody) => {
    let response = await api
        .post("store/user/receptionist", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeReceptionist = async(id) => {
    let response = await api
        .delete(`delete/user/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const changeReceptionistStatus = async(id) => {
    let response = await api
        .put(`deactivate/${id}/user`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}


export const updateProfile = async(postBody, id) => {
    let response = await api
        .post(`update/user/${id}`, postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchDoctors = async() => {
    let response = await api
        .get("doctors/list")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchAdmins = async() => {
    let response = await api
        .get("list/all/user/admin")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchReceptionists = async() => {
    let response = await api
        .get("list/all/user/receptionist")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};