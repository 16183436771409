import { api } from "@/api";

export const createArrear = async (body) => {
  return await api
    .post("arrears", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const removeArrear = async (id) => {
  return await api
    .delete(`arrears/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
