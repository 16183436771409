<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-google-wallet text-primary me-2" aria-hidden="true"></i>Initiate Stk Push</div>
      <hr class="horizontal dark m-0" />
      <el-form
          ref="stkPushFormRef"
          :model="stkPushForm"
          :rules="rules"
          label-width="150px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-alert
                  title="warning alert !"
                  type="error"
                  description="Note that the tenant should be aware you are making these request."
                  show-icon
              />
            </div>
            <div class="col-md-12 mb-3">
              <label class="el-form-item__label">Tenant</label>
              <el-form-item prop="tenant">
                <el-select-v2
                    v-model="stkPushForm.tenant"
                    filterable
                    :options="tenants"
                    placeholder="Please select a tenant"
                    style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <label class="el-form-item__label">Mobile Number</label>
              <vue-tel-input
                  v-model="stkPushForm.phone"
                  :value="stkPushForm.phone"
                  @validate="checkMobileNumber"
                  v-bind="phoneBindingProps"
              ></vue-tel-input>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Amount" prop="amount">
                <el-input v-model="stkPushForm.amount" />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(stkPushFormRef)"
          >Request</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {stkPush} from "@/api/property-manager/payments";
import { fetchLeasedManagerTenants } from "../../../api/property-manager/tenants";
import { ElNotification } from "element-plus";

const value2 = ref('')
const stkPushFormRef = ref();
const isLoading = ref(false);
const tenants = ref([]);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "lease-form",

  props: {
    unit: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const stkPushForm = reactive({
      id: props?.unit?.unitId || 0,
      tenant: props?.unit?.tenant || "",
      amount: props?.unit?.amount || "",
      phone: props?.unit?.phone || "",
    });

    const phoneBindingProps = {
      mode: "international",
      styleClasses: "has-default",
      defaultCountry: "KE",
      onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
      inputOptions: {
        showDialCode: false,
        placeholder: "Mobile Number",
        name: "phone",
      },
    };

    console.log(props?.unit?.unitId)
    const rules = reactive({

      tenant: [
        {
          required: true,
          message: "Please select tenant",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input phone number",
          trigger: ["blur", "change"],
        },
      ],
      amount: [
        {
          required: true,
          message: "Please input amount",
          trigger: ["blur", "change"],
        },
      ]
    });

    const checkMobileNumber = (event) => {
      if (event.valid) {
        stkPushForm.phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        stkPushForm.phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("unitId", props?.unit?.unitId);
          formData.append("amount", stkPushForm.amount);
          formData.append("tenantId", stkPushForm.tenant);
          formData.append("phone", stkPushForm.phone);
          formData.append("managerId", propertyManagerId);
          console.log(formData);

          stkPush(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-properties");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      stkPushForm,
      submitForm,
      rules,
      stkPushFormRef,
      isLoading,
      props,
      tenants,
      phoneBindingProps,
      checkMobileNumber,
      value2
    };
  },

  created() {
    this.fetchTenantList();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.tenantId}`,
          label: `${tenant.firstName  + " " + tenant.lastName  + " - " + tenant.leases[0]?.unit?.unitCode}`,
        }));

        tenants.value = tempTenants;
        console.log(tenants)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  max-height: 550px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}

input {
  border-radius: 6px;
}
</style>
