<template>
    <div class="card">
      <div  class="card-header">
        <div class="d-flex align-items-center gap-2">
          <i class="fa fa-home text-primary text-md opacity-10"></i>
          <h6>Properties</h6>
        </div>
        <div class="cta">
          <FilterTablesForm 
              :show_properties_table_filter="true"
              :filter_init="filter_params"
              :filterCallBack="_filterCallBack"
          />
          <button
            class="btn mb-0 btn-success btn-xs null null ms-3 gap-2"
            @click="newProperty"
           
          >
            <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>New
            Property
          </button>
        </div>
      </div>
      <div class="card-body">
        <div v-if="properties.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive" v-else>
          <div class="card-table">
            <table class="table align-items-center">
              <thead class="sticky-top">
              <tr>
                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Property Name
                </th>

                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Location
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Unit Counts
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Created Date
                </th>

                <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in properties.data" :key="item.propertyId + index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.propertyName
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item.propertyName }}
                      </h6>
                     
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.propertyAddress }}</p>
                </td>

                <td class="align-middle text-center">
                  <p class="text-xs font-weight-bold mb-0">{{ item.unitCount }}</p>
                </td>

                <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(parseInt(item.active))"
                  >{{ parseInt(item.active) === 0 ? "Deactivated" : "Active" }}</span
                  >
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.lastUpdated)
                    }}</span>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <router-link
                            :to="{ path: `/property/${item.propertyId}` }"
                            class="text-secondary"
                        ><el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                        ><i class="fa fa-eye" aria-hidden="true"></i>
                          View</el-dropdown-item
                        ></router-link
                        >
                        <el-dropdown-item 
                            class="text-secondary font-weight-bold text-xs"
                            @click="editProperty(item)"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          Edit
                        </el-dropdown-item>

                        <el-dropdown-item  
                            v-if="item.unitCount === 0"
                            class="text-secondary font-weight-bold text-xs"
                            @click="deleteProperty(item.propertyId)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
    <PropertiesForm
      v-if="showModal"
      @close-modal="showModal = false"
      :property="propertyDetails"
      @refresh-properties="refresh"
    />

  </template>
  <script> 
  import PropertiesForm from "../../../components/Modals/property-manager/PropertyForm.vue";
  import EmptyStateTable from "../EmptyTableState.vue";
  import moment from "moment";
  import {ref, h, reactive} from "vue";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { removeProperty } from "../../../api/property-manager/properties";
  import Pagination from "@/views/components/property-manager/Pagination.vue";
  
  const showModal = ref(false);
  const propertyDetails = ref({});
  const modalData = ref({});
  const isLoading = ref(false);
  const filter_params = ref({});

  export default {
    name: "landlord-properties-table",
    props: {
      properties: {
        type: Object,
        required: true,
      },
      refresh: {
        type: Function,
        required: true,
      },
      searchQuery: {
        type: String,
        required: true,
      },
    },
    components: {
      Pagination,
      PropertiesForm,
      EmptyStateTable,
    },
  
    setup(props) {

      const total_records = props?.properties?.total || 0;
      const per_page = props?.properties?.per_page || 0;
      const total_pages = ref(0);
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      }

      const Pagination = reactive({
        current_page: props?.properties?.current_page || 1,
        total_pages: total_pages,
        per_page: per_page,
      });

      const onPageChange = (page) => {
        // eslint-disable-next-line prettier/prettier
        props.refresh(Filter.searchquery, page);
      };
      const Filter = reactive({
        searchquery: props.searchQuery != null ? props.searchQuery : "",
      });
      const _filterCallBack = (filter_data) => {
        filter_params.value = filter_data;
        props.refresh(filter_params, 0);
      };
      const onSelectHandler = () => {};
      const getInitials = (name) => {
        if (name) {
          const nameSplit = name?.split(" ");
          const firstInitials = nameSplit[0]?.charAt(0);
          const secondInitials = nameSplit[1]?.charAt(0);
  
          return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
          );
        }
        return "";
      };
      const getStatusColor = (status) => {
        switch (status) {
          case 0:
            return "bg-gradient-danger";
          case 1:
            return "bg-gradient-success";
  
          default:
            return null;
        }
      };
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };
  
      const editProperty = (property) => {
        propertyDetails.value = property;
        showModal.value = true;
      };
  
      const newProperty = () => {
        propertyDetails.value = {};
        showModal.value = true;
      };
  
      const deleteProperty = async (id) => {
        ElMessageBox.confirm(
          h(
            "div",
            { style: "color: black, font-size: 14px" },
            "The property will be deleted. Continue?"
          ),
  
          "Delete Property",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
        ).then(async () => {
          try {
            isLoading.value = true;
            const { message, success } = await removeProperty(id);
  
            if (success) {
              props.refresh();
              ElMessage({
                type: "success",
                message: message,
              });
            } else {
              ElMessage({
                type: "error",
                message: message,
              });
            }
          } catch (error) {
            ElMessage({
              type: "error",
              message: error.message,
            });
          } finally {
            isLoading.value = false;
          }
        });
      };
      
  
      return {
        onPageChange,
        Pagination,
        dateTime,
        getStatusColor,
        getInitials,
        showModal,
        modalData,
        propertyDetails,
        editProperty,
        newProperty,
        isLoading,
        deleteProperty,
        onSelectHandler,
        filter_params,
        _filterCallBack,
      };
    },
    data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },

  };
  </script>
  
  <style scoped>
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #5f924d;
  }
  .sticky-top th {
    color: #fff !important;
  }
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5px;
  }
  .card-table {
    max-height: 55vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  h6 {
    margin: 0;
  }
  </style>
  