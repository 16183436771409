import {api} from "../index";

export const fetchRoles = async() => {
    let response = await api
        .get("roles")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const unPagineatedRoles = async() => {
    let response = await api
        .get("manager/roles")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createRole = async(body) => {
    let response = await api
        .post("roles", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeRole = async(id) => {
    let response = await api
        .delete(`roles/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const updateRole = async(body, id) => {
    let response = await api
        .post("update/role/" + id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

