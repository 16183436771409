<template>
  <div v-if="isLoading">
    <Loader />
  </div>
  <div class="py-4 container-fluid" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <!-- doughnut -->
            <div class="card z-index-2">
              <donut-chart :payment_amount="dashboardData.payment_amount" />
            </div>
          </div>
          <div class="col-lg-6">
            <!-- Property vs Unit Analysis -->
            <div class="card z-index-2">
              <line-chart :title="'PROPERTY VS UNIT'" :property_unit="dashboardData.property_unit" />
            </div>
          </div>

          <div class="col-lg-6">
            <!-- SMS Analysis -->
            <div class="card z-index-2 mt-4">
              <bar-chart :title="'SMS ANALYYSIS'" :sms_data="dashboardData.sms_data" />
            </div>
          </div>

          <div class="col-lg-6">
            <!-- leased vs unleased Unit Analysis -->
            <div class="card z-index-2 mt-4">
              <pie-chart :title="'OCCUPIED VS UNOCCUPIED UNITS'" :units="dashboardData.units" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import LineChart from "../../Main/Charts/LineChart.vue";
import PieChart from "../../Main/Charts/PieChart.vue";
import BarChart from "../../Main/Charts/BarChart.vue";
import DonutChart from "../../Main/Charts/DonutChart.vue";
import { onMounted, ref } from "vue";
import { fetchStatsData } from "@/api/property-manager/currentStats";
import Loader from "../components/Loader.vue";
import { fetchDashboardManagerPayments } from "@/api/property-manager/payments";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";

const dashboardData = ref({
  payment_amount:[],
  sms_data: [],
  property_unit: [],
  units: [],
});
const payments = ref([]);
const monthlyCollection = ref();
const isLoading = ref();

export default {
  name: "dashboard-default",

  setup() {
    const router = useRouter();
    const paymentsTable = () => {
      router.push("/manager/payments");
    };
    const fetchPayments = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchDashboardManagerPayments(
          entity_params,
          page
        );
        payments.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchData = async () => {
      isLoading.value = true;
      try {
        const response = await fetchStatsData();
        if (response.status === 403) {
          router.push({
            path: "/subscription-page",
            query: { data: JSON.stringify(response.data) },
          });
          ElNotification({
            title: "Error",
            message: response.message,
            type: "error",
          });
        }
        // dashboardData.value = response.data;
        dashboardData.value = {
          payment_amount: response.data.payment_amount,
          sms_data: response.data.sms_data,
          property_unit: response.data.property_unit,
          units: response.data.units,
        };
        monthlyCollection.value = "ksh " + dashboardData.value.month_collection;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };


    onMounted(() => {
      fetchData();
      fetchPayments();
    });

    return {
      isLoading,
      dashboardData,
      monthlyCollection,
      fetchData,
      payments,
      fetchPayments,
      paymentsTable,
    };
  },

  components: {
    Loader,
    DonutChart,
    LineChart,
    PieChart,
    BarChart,
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
