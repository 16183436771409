<template>
  <div class="table-responsive p-0">
    <div class="card-body">
      <div v-if="payments?.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
              <tr>
                <th
                  class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                >
                  <input type="checkbox" v-model="selectAll" />
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Tenant Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Unit Code
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Amount Paid
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Transactional Reference
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Payment Date
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in payments" :key="item.payId + index">
                <td>
                  <input
                    type="checkbox"
                    v-model="selected"
                    :value="item.payId"
                    number
                  />
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{
                      item?.units?.leases[0]?.tenant?.firstName +
                      " " +
                      item?.units?.leases[0]?.tenant?.lastName
                    }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item?.units?.unitCode }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ formatNumber(item.amountPaid) }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.thirdpartyPaymentRef }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ dateTime(item.paymentDate) }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.qr_code_url ? "Sent" : "Not Sent" }}
                  </p>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="item.qr_code_url"
                          class="text-secondary font-weight-bold text-xs"
                          @click="downloadInvoice(item.payId)"
                          ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                          Download Invoice
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card--footer" style="margin-top: 20px; margin-bottom: 80px">
      <el-button type="success" :loading="isLoading" @click="submitChecked()"
        >Submit</el-button
      >
      <el-button type="danger" @click="toggleSelection()"
        >Clear selection</el-button
      >
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import { ElMessage, ElNotification } from "element-plus";
import { kraPaymentResponse, postToKra, sendPaymentInvoices } from "@/api/kra";
import { downloadPaymentInvoicePdf } from "@/api/property-manager/downloads";
import moment from "moment";

const selected = ref([]);
const isLoading = ref(false);

export default {
  components: {
    EmptyStateTable,
  },
  props: {
    payments: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const dateFormRef = ref();

    const dateForm = reactive({
      date: "",
    });

    const messageForm = reactive({
      message: "",
    });

    const rules = reactive({
      date: [
        {
          required: true,
          message: "Please pick a date",
          trigger: ["blur", "change"],
        },
      ],
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });
    const shortcuts = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Tomorrow",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "Next week",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];

    const multipleTableRef = ref();

    const toggleSelection = () => {
      return (selected.value = []);
    };

    const selectAll = computed({
      get() {
        if (props.payments && props.payments.length > 0) {
          let allChecked = true;
          for (const payment of props.payments) {
            if (!selected.value.includes(payment.payId)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          props.payments.forEach((payment) => {
            checked.push(payment.payId);
          });
        }

        selected.value = checked;
      },
    });

    const submitChecked = () => {
      if (!selected.value) {
        ElNotification({
          title: "Error",
          message: "Please select user",
          type: "error",
        });
        return;
      }

      isLoading.value = true;
      const formData = new FormData();
      formData.append("payIds", JSON.stringify(selected.value));

      sendPaymentInvoices(formData)
        .then(async (data) => {
          if (data.success) {
            const kra_results = [];
            const response = data.data;

            await Promise.all(
              response.map(async (field) => {
                const invoice_number = field.invoice_number;
                const result = await postToKra(field.kra_json);
                const result_object = {
                  invoice_number: invoice_number,
                  result: result.data,
                };
                kra_results.push(result_object);
              })
            );

            console.log(kra_results);
            kraPaymentResponse(kra_results).then((response) => {
              console.log(response);
            });

            selected.value = [];
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
          } else if (data.error) {
            selected.value = [];
            ElNotification({
              title: "Error",
              message: data.error,
              type: "error",
            });
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          // fetchData();
          props.refresh()
          isLoading.value = false;
        });
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };


    async function downloadInvoice(id) {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Invoice will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadPaymentInvoicePdf(id)
          .then((Response) => {
            console.log(Response);

            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "tenant_invoice_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };


    return {
      submitChecked,
      downloadInvoice,
      dateFormRef,
      selectAll,
      rules,
      messageForm,
      dateForm,
      shortcuts,
      multipleTableRef,
      toggleSelection,
      isLoading,
      selected,
      dateTime,
      formatNumber,
    };
  },
};
</script>

<style scoped></style>
