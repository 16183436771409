<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-color: #5f924d; background-position: top"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h3 class="text-white mb-2 mt-5">Renew Your Subscription Here.</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Initiate Payment.</h5>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3"></div>
            <div class="card-body">
              <el-form
                ref="paymentFormRef"
                :model="paymentForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                size="large"
                status-icon
                :scroll-to-error="true"
                label-position="top"
                :hide-required-asterisk="true"
              >
                <el-form-item label="Invoice Number" prop="invoice_number">
                  <el-input disabled v-model="paymentForm.invoice_number" />
                </el-form-item>
                <el-form-item label="Amount" prop="amount">
                  <el-input disabled v-model="paymentForm.amount" />
                </el-form-item>
                <el-form-item label="Default Phone Number" prop="phone">
                  <el-input v-model="paymentForm.phone" />
                </el-form-item>
                <el-button
                  type="success"
                  class="px-5 d-block my-3 mx-auto"
                  :loading="isLoading"
                  @click="submitForm(paymentFormRef)"
                >
                  Pay
                </el-button>
              </el-form>
            </div>
            <div class="px-1 pt-0 text-center card-footer px-lg-2">
              <p class="mx-auto mb-2 text-sm">Or <br /></p>
              <p class="mx-auto mb-1 text-sm">1. Go to your M-PESA account.</p>
              <br />
              <p class="mx-auto mb-1 text-sm">
                2. Select Lipa na M-PESA Option.
              </p>
              <br />
              <p class="mx-auto mb-1 text-sm">3. Select Pay Bill Option.</p>
              <br />
              <p class="mx-auto mb-1 text-sm">
                4. Enter <strong>4119871</strong> as your business no.
              </p>
              <br />
              <p class="mx-auto mb-1 text-sm">
                5. Enter <strong>{{ parsedData.invoice_number }}</strong> as
                your account number.
              </p>
              <br />
              <p class="mx-auto mb-1 text-sm">
                6. Enter <strong>{{ parsedData.amount }}</strong> as your amount
                and submit.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {reactive, ref, onMounted, watch} from "vue";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import { stkManagerPush } from "@/api/property-manager/payments";

const isLoading = ref(false);
const paymentFormRef = ref();
const body = document.getElementsByTagName("body")[0];
export default {
  setup() {
    const router = useRouter();

    const paymentForm = reactive({
      phone: "",
      amount: "",
      invoice_number: "",
    });

    const rules = reactive({
      phone: [
        {
          required: true,
          message: "Please input phone number",
          trigger: "blur",
        },
      ],
      amount: [
        {
          required: true,
          message: "Please input your amount",
          trigger: ["blur", "change"],
        },
      ],
    });
    const parsedData = ref(null);

    const parseData = () => {
      const rawData = router.currentRoute.value.query.data;
      parsedData.value = JSON.parse(rawData);

      console.log(parsedData.value);
    };

    onMounted(() => {
      parseData();
    });

    watch(parsedData, (newVal) => {
      if (newVal) {
        paymentForm.phone = newVal.phone_number || "";
        paymentForm.amount = newVal.amount || "";
        paymentForm.invoice_number = newVal.invoice_number || "";
      }
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;

          let body = {
            phone: paymentForm.phone,
            amount: paymentForm.amount,
            invoice_number: paymentForm.invoice_number,
          };

          stkManagerPush(body)
            .then((response) => {
              console.log(response);
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      paymentForm,
      rules,
      submitForm,
      paymentFormRef,
      isLoading,
      parsedData,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>

<style scoped></style>
