<template>
  <div class="card">
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="payments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive p-0" v-else>
        <div class="card-table">
          <table class="table align-items-center mb-0">
            <thead class="sticky-top">
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Unit Code
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Bill Name
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Balance
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Transaction Reference
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment Date
              </th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in payments.data" :key="item.id + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item?.unitCode
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.unitCode }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item?.billName }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item?.paymentAmount }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item?.accSnap }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item?.TxRef }}</p>
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.createdAtFromSourceSys)
                    }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>

import EmptyStateTable from "../../components/EmptyTableState.vue";
import moment from "moment";
import {ref, reactive, toRefs, watch} from "vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";

export default {
  name: "payments-table",
  props: {
    payments: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    Pagination,
    EmptyStateTable
  },

  setup(props) {

    const { payments, refresh, searchQuery } = toRefs(props);
    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = payments.value?.total || 0;
      const per_page = payments.value?.per_page || 10; // Set a default value if per_page is not available
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(payments, () => {
      updateTotalPages();
      Pagination.current_page = payments.value?.current_page || 1;
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: payments.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });

    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    return {
      Pagination,
      onPageChange,
      dateTime,
      getStatusColor,
      getInitials,
      onSelectHandler,
      Filter
    };
  }
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}
h6 {
  margin: 0;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
