<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading"><Loader /></div>
          <admin-landlords-table
            :landlords="landlords"
            :refresh="fetchData"
            :searchQuery="searchTerm"
            v-else
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AdminLandlordsTable from "../components/admin/AdminLandlordsTable.vue";
  import Loader from "../components/Loader.vue";
  import { fetchManagerLandlords } from "../../api/admin/landlords";
  import { ElLoading } from "element-plus";
  import { ref } from "vue";

  const isLoading = ref(false);
  const landlords = ref([]);
  const searchTerm = ref("");
  export default {
    name: "landlords",
    components: {
      AdminLandlordsTable,
      Loader,
    },
    setup() {
        // const fetchData = async (search, page) => {
        //   searchTerm.value = search;
        //   isLoading.value = true;
        //   try {
        //     const response = await fetchManagerLandlords(search, page);
        //     landlords.value = response.data;

        //     console.log(landlords.value);
        //   } catch (error) {
        //     console.log(error);
        //   } finally {
        //     isLoading.value = false;
        //   }
        // };

      const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter == undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchManagerLandlords(entity_params, page);
        landlords.value = response.data;
        console.log(response.data)
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
  
      const fullScreenLoader = () => {
        ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      };
  
      return {
        fullScreenLoader,
        fetchData,
        landlords,
        isLoading,
        searchTerm
      };
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  