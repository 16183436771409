import axios from "axios";
import {api} from "./";

// const kraUrl = "http://localhost:8080/VsdcConnector_v1/vsdc/saveSales";
const kraUrl = "http://localhost:8000/api/health";
const deities = "http://localhost:8000/api/deities";

export function postToKra(data) {
  return axios.post(kraUrl, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
  });
}


export function postToDeities(data) {
  return axios.post(deities, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
  });
}

export const sendRentInvoices = async (body) => {
  return await api
    .post("kra-rent-invoices", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const sendRentInvoiceCreditNotes = async (id) => {
  return await api
    .post(`kra-rent-invoices-credit-note/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const kraResponse = async (body) => {
  return await api
    .post("kra-invoices-response", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const kraRentInvoiceResponse = async (body) => {
  return await api
    .post("rent-invoices-response", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const sendPaymentInvoices = async (body) => {
  return await api
    .post("kra-payment-invoices", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const kraPaymentResponse = async (body) => {
  return await api
    .post("kra-payment-invoices-response", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const sendManagerInvoices = async (body) => {
  return await api
    .post("kra-manager-invoices", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const kraManagerInvoiceResponse = async (body) => {
  return await api
    .post("kra-manager-invoices-response", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
