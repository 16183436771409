<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-home text-primary me-2" />Lease Unit</div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="leaseFormRef"
          :model="leaseForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-form-item label="Unit" prop="unit">
                <el-select-v2
                    v-model="leaseForm.unit"
                    filterable
                    :options="units"
                    placeholder="Please select a unit"
                    style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Date Range" prop="date_range">
                <el-date-picker
                    v-model="leaseForm.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    :shortcuts="shortcuts"
                />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(leaseFormRef)"
          >Lease</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {createLease} from "@/api/property-manager/leases";
import { ElNotification } from "element-plus";
import {fetchVacantUnits} from "@/api/property-manager/units";

const value2 = ref('')
const leaseFormRef = ref();
const isLoading = ref(false);
const units = ref([]);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "lease-form",

  props: {
    tenantId: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const leaseForm = reactive({
      id:  0,
      unit: "",
      tenantId: props?.tenantId?.tenantId || "",
      date_range:  "",
    });

    const rules = reactive({

      unit: [
        {
          required: true,
          message: "Please select unit",
          trigger: ["blur", "change"],
        },
      ],

      date_range: [
        {
          required: true,
          message: "Please select date-range",
          trigger: ["blur", "change"],
        },
      ]
    });

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("tenantId", props?.tenantId);
          formData.append("date_range", leaseForm.date_range);
          formData.append("unitId", leaseForm.unit);
          formData.append("managerId", propertyManagerId);
          console.log(formData);

          createLease(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-tenants");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      leaseForm,
      submitForm,
      rules,
      leaseFormRef,
      isLoading,
      props,
      units,
      shortcuts,
      value2
    };
  },

  created() {
    this.fetchVacantUnitsList();
  },
  methods: {
    async fetchVacantUnitsList() {
      try {
        const response = await fetchVacantUnits();

        const tempUnits = response.data.map((unit) => ({
          value: `${ unit.unitId }`,
          label: `${  unit?.property?.propertyName  + " - " + unit.unitCode }`,
        }));

        units.value = tempUnits;
        console.log(units)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 600px;
  max-height: 380px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
