<template>
    <div class="row mt-5" v-if="isLoading"><Loader /></div>
    <div class="row mt-5" v-else>
      <div class="col-md-8">
        <div class="card">
          <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
            <template #icon>
              <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
            </template>
          </el-page-header>
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0 text-dark text-bold">
                {{
                  unitDetails.property.propertyName
                }}
              </p>
              <button v-if="hasPermission('delete.leases')" type="button" @click="closeLease(unitDetails)" class="btn mb-0 btn-success btn-xs null null ms-auto">Close Lease</button>
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Tenant Information</p>
            <div class="row">
              <div class="col-md-6">
                <dl>
                  <dt>Tenant Name</dt>
                  <dd>{{ capitalized(unitDetails.leases[0].tenant.firstName + " " + unitDetails.leases[0].tenant.lastName ) }}</dd>
                  <dt>Phone Number</dt>
                  <dd>{{unitDetails.leases[0].tenant.primaryPhone }}</dd>
                  <dt>Tenant Email</dt>
                  <dd>{{ unitDetails.leases[0].tenant.email }}</dd>
                  <dt v-if="unitDetails?.leases[0].tenant.kra_pin">Kra Pin</dt>
                  <dd v-if="unitDetails?.leases[0].tenant.kra_pin">{{ unitDetails?.leases[0].tenant.kra_pin }}</dd>
                  <dt v-if="unitDetails?.kra_rate">Kra Rate</dt>
                  <dd v-if="unitDetails?.kra_rate">{{ unitDetails?.kra_rate }} </dd>
                  <dt v-if="unitDetails?.leases[0].tenant.kin_name">Next of Kin</dt>
                  <dd v-if="unitDetails?.leases[0].tenant.kin_name">{{ unitDetails?.leases[0].tenant.kin_name }} </dd>
                  <dt v-if="unitDetails?.leases[0].tenant.kin_relationship">Relationship</dt>
                  <dd v-if="unitDetails?.leases[0].tenant.kin_relationship">{{ unitDetails?.leases[0].tenant.kin_relationship }} </dd>
                  <dt v-if="unitDetails?.leases[0].tenant.kin_phone">Kin's Phone</dt>
                  <dd v-if="unitDetails?.leases[0].tenant.kin_phone">{{ unitDetails?.leases[0].tenant.kin_phone }} </dd>
                  <dt>Created Date</dt>
                  <dd>{{ dateTime(unitDetails.leases[0].tenant.createDate) }}</dd>
                </dl>
              </div>
              <div class="col-md-6">
                <dl>
                  <dt>Units Code.</dt>
                  <dd> {{ capitalized(unitDetails.unitCode) }}</dd>
                  <dt>Rental Rate</dt>
                  <dd>{{ unitDetails.hoaFee }}</dd>
                  <dt>Service Charge</dt>
                  <dd>{{ unitDetails.garbage }}</dd>
                  <dt>Lease Start</dt>
                  <dd>{{ dateTime(unitDetails.leases[0].leaseStart) }}</dd>
                  <dt>Lease End</dt>
                  <dd>{{ dateTime(unitDetails.leases[0].leaseEnd) }}</dd>
                </dl>
              </div>
            </div>

            <hr class="horizontal dark" />
            <p class="text-uppercase text-sm">Communications</p>
            <div class="contant-info">
              <dl>
                <dt class="dd-with-button">Send SMS / Email</dt>
                <dd class="dd-with-button"><button type="button" v-if="hasPermission('create.outgoing_sms')"  @click="composeSMS" class="btn mb-0 btn-success btn-xs null null ms-auto">Compose</button></dd>
                <dt class="dd-with-button">Send Invoice</dt>
                <dd class="dd-with-button"><button type="button" v-if="hasPermission('create.outgoing_sms')" @click="invoiceSMS" class="btn mb-0 btn-success btn-xs null null ms-auto">Compose</button></dd>
                <dt class="dd-with-button">Send Water Bill</dt>
                <dd class="dd-with-button"><button type="button" v-if="hasPermission('create.outgoing_sms')" @click="WaterBillSMS" class="btn mb-0 btn-success btn-xs null null ms-auto">Compose</button></dd>
              </dl>
            </div>
            <hr class="horizontal dark" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0 text-dark text-bold">Quick Glance</p>
            </div>
          </div>
          <div class="card-body sidebar">
            <img
              alt="Image placeholder"
              src="@/assets/img/stetho.jpg"
              class="side-glance"
            />

            <dl>
              <dt>Current Balance</dt>
              <dd>{{ unitDetails.leases[0].accounts[0].balance }}</dd>
            </dl>
            <div class="d-flex justify-content-center align-items-center">
              <button v-if="
                hasPermission('manage.payments') ||
                hasPermission('manage.water') ||
                hasPermission('view.unit_charges') ||
                hasPermission('manage.penalties') ||
                hasPermission('manage.arrears') ||
                hasPermission('manage.deposits')
                "
               class="btn mb-0 btn-success btn-xs null null ms-auto" type="button"  @click="openUnitDrawer">More On Unit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <ComposeSMSForm
      v-if="showModal"
      @close-modal="showModal = false"
      :unitId ="unitDetails.unitId"
  />

  <InvoiceSMSForm
      v-if="showInvoiceModal"
      @close-modal="showInvoiceModal = false"
      :unitId ="unitDetails.unitId"
      :invoiceMessage="unitDetails.invoice"
  />

  <CloseLeaseForm
      v-if="showCloseLease"
      :unit="unitDetails"
      :refresh="fetchLeaseData"
      @close-modal="showCloseLease = false"
  />

  <WaterBillSMSForm
      v-if="WaterBillModal"
      @close-modal="WaterBillModal = false"
      :unitId ="unitDetails.unitId"
      :waterMessage="unitDetails.water"
  />
  </template>
  
  <script>
  import Loader from "@/components/Loader.vue";
  import { ref } from "vue";
  import moment from "moment";
  import { fetchUnitDetails } from "../../../api/property-manager/units";
  import ComposeSMSForm from "@/components/Modals/property-manager/ComposeSMSForm.vue";
  import WaterBillSMSForm from "@/components/Modals/property-manager/WaterBillSMSForm.vue";
  import InvoiceSMSForm from "@/components/Modals/property-manager/InvoiceSMSForm.vue";
  import router from "@/router";
  import CloseLeaseForm from "../../../components/Modals/property-manager/CloseLeaseForm.vue";
  import {fetchClosedLeases} from "../../../api/property-manager/closedLeases";

  const isLoading = ref(false);
  const unitDetails = ref({});
  const showModal = ref(false);
  const showInvoiceModal = ref(false);
  const showCloseLease = ref(false);
  const WaterBillModal = ref(false);
  const searchTerm = ref({});
  const closedLeases = ref({});

  export default {
    components: {
      ComposeSMSForm,
      WaterBillSMSForm,
      InvoiceSMSForm,
      CloseLeaseForm,
      Loader
    },
    name: "unit-info",
    props: {
      unit_id: {
        required: true,
      }
    },
    setup(props) {
      const fetchUnitData = async () => {
        isLoading.value = true;
        try {
          const response = await fetchUnitDetails(props.unit_id);
          unitDetails.value = response.data;
          console.log(response.data)
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };
      const fetchLeaseData = async (search, page) => {
        searchTerm.value = search;
        isLoading.value = true;
        try {
          const response = await fetchClosedLeases(search, page);
          closedLeases.value = response.data;
          console.log(response.data)
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };
      const dateTime = (value) => {
        return moment(value).format("Do MMM YYYY");
      };
      const capitalized = (name) => {
        const capitalizedFirst = name[0].toUpperCase();
        const rest = name.slice(1);
        return capitalizedFirst + rest;
      };
      const getAge = (birthDate) => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
      const composeSMS = () => {
        showModal.value = true;
      }

      const invoiceSMS = () => {
        showInvoiceModal.value = true;
      }

      const openUnitDrawer = () => {
        router.push(`/unit/${props.unit_id}/details`);
      }
      const WaterBillSMS = () => {
        WaterBillModal.value = true;
      }

      const goBack = () => {
        router.go(-1);
      }

      const closeLease = (unit) => {
        unitDetails.value = unit;
        showCloseLease.value = true;
      }
      

      return {
        closeLease,
        fetchLeaseData,
        showCloseLease,
        goBack,
        fetchUnitData,
        openUnitDrawer,
        isLoading,
        unitDetails,
        capitalized,
        dateTime,
        getAge,
        composeSMS,
        showModal,
        WaterBillSMS,
        WaterBillModal,
        invoiceSMS,
        showInvoiceModal,
        props
      };
    }, 
    data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    },    
    created() {
      this.fetchUnitData();
    }
  };
  </script>
  <style scoped>
    dt {
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 1.4rem;
      color: #495057;
      margin-bottom: 0.5rem;
    }
    dl {
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 20px 0;
      padding: 0 16px;
    }
    dd {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.4rem;
      color: #495057;
    }
    .sidebar dl {
      display: grid;
      grid-template-columns: 60% 40%;
      margin: 30px 0 10px;
    }
    .contant-info dl {
      display: grid;
      grid-template-columns: 30% 70%;
    }
    .side-glance {
      max-height: 180px;
      width: 100%;
      object-fit: cover;
    }
    .sidebar button {
      margin: 20px auto;
      display: block;
    }

  .btn {
      text-align: center;
      vertical-align: middle;
  }

  .dd-with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1px;
  }

 </style>
  