import {api} from "@/api";

export const createLease = async(body) => {
    let response = await api
        .post("lease-unit", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const closeLease = async(body) => {
    return await api
        .post("close-lease", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
};