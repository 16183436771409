<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-barcode text-primary me-2" aria-hidden="true"></i>Business Expense</div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="businessExpenseFormRef"
          :model="businessExpenseForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-form-item label="Name" prop="name">
                <el-input v-model="businessExpenseForm.name" />
              </el-form-item>
            </div>
            <div class="col-md-12 mb-3">
              <el-form-item label="amount" prop="amount">
                <el-input v-model="businessExpenseForm.amount" />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Description" prop="description">
                <el-input
                    v-model="businessExpenseForm.description"
                    autosize
                    type="textarea"
                    placeholder="Please input description"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Date" prop="date">
                <el-date-picker
                    style="width: 100%"
                    v-model="businessExpenseForm.date"
                    type="date"
                    placeholder="Select Date"
                    :size="size"
                />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(businessExpenseFormRef)"
          >Submit</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { ElNotification } from "element-plus";
import {createBusinessExpense, updateBusinessExpense} from "../../../api/property-manager/businessExpense";

const value2 = ref('')
const businessExpenseFormRef = ref();
const isLoading = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "business-expense-form",

  props: {
    businessExpense: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const businessExpenseForm = reactive({
      name: props?.businessExpense?.name || "",
      amount: props?.businessExpense?.amount || "",
      description: props?.businessExpense?.description || "",
      date: props?.businessExpense?.date || "",
    });

    const rules = reactive({
      name: [
        {
          required: true,
          message: "Please input name",
          trigger: ["blur", "change"],
        },
      ],
      amount: [
        {
          required: true,
          message: "Please input amount",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input description",
          trigger: ["blur", "change"],
        },
      ],

      date: [
        {
          required: true,
          message: "Please select date",
          trigger: ["blur", "change"],
        },
      ]
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("date", businessExpenseForm.date);
          formData.append("description", businessExpenseForm.description);
          formData.append("name", businessExpenseForm.name);
          formData.append("amount", businessExpenseForm.amount);
          formData.append("managerId", propertyManagerId);

          if (businessExpenseForm.id > 0) {
            updateBusinessExpense(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-business-expense");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>

                    ElNotification({
                      title: "Error",
                      message: err.message,
                      type: "error",
                    })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            createBusinessExpense(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-business-expense");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),

                )
                .finally(() => {
                  isLoading.value = false;
                });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      businessExpenseForm,
      submitForm,
      rules,
      businessExpenseFormRef,
      isLoading,
      props,
      value2
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 630px;
  max-height: 600px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
