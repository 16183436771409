<template>
    <div class="card">
      <div class="card-header pb-0 mb-5 d-flex justify-content-between">
      <div class="d-flex align-items-center gap-2 ">
        <i class="fa fa-list text-primary text-md opacity-10"></i>
        <h6 class="mt-2">Logs</h6>
      </div>
      <div class="cta">
        <FilterTablesForm
            :show_logs_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
       </div>
      </div>
         <div class="card-body px-0 pt-0 pb-2">
          <div v-if="logs.data.length === 0">
        <empty-state-table />
       </div>
       <div class="table-responsive p-0" v-else>
         <div class="card-table">
           <table class="table align-items-center mb-0">
             <thead>
             <tr>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                 Name
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
               >
                 Email
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Log
               </th>
 
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Activity
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Device
               </th>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Activity Time
               </th>
 
             </tr>
             </thead>
             <tbody>
              <tr v-for="(item, index) in logs.data" :key="index">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex align-items-center">
                     <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                         getInitials(
                             item.user.name
                         ).toUpperCase()
                       }}
                     </div>
                     <p class="text-xs font-weight-bold mb-0">{{ item.user.name }}</p>
                   </div>
                 </div>
               </td>
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.user?.email }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.slug }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.log }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.device }}</p>
               </td>
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{  dateTime(item.created_at) }}</p>
               </td>
             </tr>
               
             </tbody>
           </table>
         </div>
       </div>
       <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="paginate.total_pages"
            :perPage="paginate.per_page"
            :currentPage="paginate.current_page"
            @pagechanged="onPageChange"
        />
       </div>
     </div>
    </div>
   
 </template>
 <script  >
 import Pagination from "./Pagination.vue";
 import EmptyStateTable from "../../components/EmptyTableState.vue";
 import { ref, reactive } from "vue";
 import moment from "moment";
 import FilterTablesForm from "./FilterTablesForm.vue";
 const filter_params = ref({});
 const date = ref('')
export default {
  name: "logs-table",
  props: {
    logs: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    }
  },

  components: {
    Pagination,
    EmptyStateTable,
    FilterTablesForm 
    
  },

  setup(props){
  const total_records = props.logs?.total || 0;
  console.log('logs total  is', props.logs.total)
  const per_page = props.logs?.per_page || 0;
  const total_pages = ref(0);
  if (per_page > 0 && total_records > 0) {
    total_pages.value = Math.ceil(total_records / per_page);
  }

  const paginate = reactive({
    current_page: props.logs?.current_page || 1,
    total_pages: total_pages,
    per_page: per_page,
  });
  const onPageChange = (page) => {
   props.refresh(filter_params, page)
  };

  const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

  const dateTime = (value) => {
    return moment(value).format("Do MMM YYYY HH:mm:ss");
  };
  const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
  
  return {
    _filterCallBack,
    filter_params,
    onPageChange,
    paginate,
    getInitials,
    dateTime,
    date
  }
  },

}
 </script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
  padding: 1.5rem;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
</style>
