<template>
  <body>
  <div class="bill">
    <div class="brand">
      {{ propertyManager?.name }}
    </div>
    <div class="address">
      {{ propertyManager?.phy_add }} <br>
      Phone No:  {{ propertyManager?.phone }}<br>
    </div>

    <div>RENT RECEIPT </div>
    <div class="bill-details">
      <div class="flex justify-between">
        <div>TENANT NAME: {{ payment?.units?.leases[0]?.tenant?.firstName  + " " + payment?.units?.leases[0]?.tenant?.lastName }}</div>
      </div>
      <div class="flex justify-between">
        <div>P.NAME: {{ payment.units?.property?.propertyName }}</div>
        <div>UNIT NO: {{ payment?.unitCode }}</div>
      </div>
      <div class="flex justify-between">
        <div>DATE: {{ dateTime(payment?.paymentDate) }}</div>
      </div>
    </div>
    <table class="table">
      <tr class="header">
        <th>
          Particulars
        </th>
        <th>
        </th>
        <th>
          Amount
        </th>
      </tr>
      <tr>
        <td>Rental Fee</td>
        <td></td>
        <td>{{ formatNumber(payment?.amountPaid) }}</td>
      </tr>
      <tr class="total">
        <td></td>
        <td>Total</td>
        <td>{{ formatNumber(payment?.amountPaid) }}</td>
      </tr>
      <tr>
        <td></td>
        <td>M.R.I</td>
        <td>0.0</td>
      </tr>
      <tr class="net-amount">
        <td></td>
        <td>Net Amnt</td>
        <td>{{ formatNumber(payment?.amountPaid) }}</td>
      </tr>
      <tr class="net-amount">
        <td></td>
        <td>Balance</td>
        <td>{{ formatNumber(payment?.units?.leases[0]?.accounts[0]?.balance) }}</td>
      </tr>
    </table>
    Payment Method: {{ payment?.source }}<br>
    Transaction ID: {{ payment?.thirdpartyPaymentRef }}
    <br>Served By: {{ user?.name }} <br>
    Thank You For Renting With Us!
  </div>
  </body>

</template>
<script>

import moment from "moment/moment";
import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";
export default {
  name: "receipt details",
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.propertyManager = JSON.parse(localStorage.getItem("propertyManager"));
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  data() {
    return {
      logo,
      logoWhite,
      name: "",
      propertyManager: "",
      user: "",
      path:"",
      url:"",
    };
  },
  setup() {
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY HH:mm");
    };
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    return {
      dateTime,
      formatNumber
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/vcr-osd-mono');
body {
  font-family: Arial, sans-serif;
  color: #000;
  text-align:center;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.bill{
  width: 300px;
  box-shadow: 0 0 3px #aaa;
  padding: 0.5px 0.5px;
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.table{
  border-collapse: collapse;
  width: 100%;
}
.table .header{
  border-top: 2px dashed #000;
  border-bottom: 2px dashed #000;
}
.table {
  text-align: left;
}
.table th,
.table td {
  color: #000;
}
.table .total td:first-of-type {
  border-top: none;
  border-bottom: none;
}
.table .total td {
  border-top: 2px dashed #000;
  border-bottom: 2px dashed #000;
}
.table .net-amount td:first-of-type {
  border-top: none;
}
.table .net-amount td {
  border-top: 2px dashed #000;
}
.table .net-amount{
  border-bottom: 2px dashed #000;
}
@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
</style>

