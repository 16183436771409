import {api} from "../index";

export const fetchBusinessIncome = async() => {
    let response = await api
        .get("business-incomes")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeBusinessIncome = async(id) => {
    let response = await api
        .delete(`business-incomes/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const createBusinessIncome = async(postBody) => {
    let response = await api
        .post("business-incomes", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateBusinessIncome = async(body) => {
    let response = await api
        .post("business-incomes/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
