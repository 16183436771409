import {api} from "../index";

export const fetchBusinessExpense = async() => {
    let response = await api
        .get("business-expenses")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeBusinessExpense = async(id) => {
    let response = await api
        .delete(`business-expenses/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const createBusinessExpense = async(postBody) => {
    let response = await api
        .post("business-expenses", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateBusinessExpense = async(body) => {
    let response = await api
        .post("business-expenses/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
