<template>
    <div class="text-center">
        <router-link to="/">
            <img src="@/assets/img/logo-ct.png" alt="" style="width:18rem;">
        </router-link>
        
    </div>
    <div class="modal-overlay col-md-6 mx-auto p-3" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header text-center">
          <i class="fa fa-calendar text-primary me-2" /><b>Book a demo</b></div>
        <hr class="horizontal dark m-0" />
  
        <el-form
            ref="demoFormRef"
            :model="demoForm"
            :rules="rules"
            label-width="120px"
            size=""
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-1">
                <el-form-item label="Name" prop="name">
                  <el-input
                      v-model="demoForm.name"
                      placeholder="Please input name"
                      clearable />
                </el-form-item>
              </div>

              <div class="col-md-12 mb-1">
                <el-form-item label="Email" prop="email">
                  <el-input
                      v-model="demoForm.email"
                      placeholder="Please input email"
                      clearable />
                </el-form-item>
              </div>
  
              <div class="col-md-12 mb-1">
                <el-form-item label="Message" prop="message">
                  <el-input
                      v-model="demoForm.message"
                      placeholder="Please input message"
                      type="textarea"
                      clearable />
                </el-form-item>
              </div>
  
              <div class="col-md-12 mb-1">
                <el-form-item label="Date" prop="date">
                  <el-date-picker
                      style="width: 100%"
                      v-model="demoForm.date"
                      type="date"
                      placeholder="Select Date"
                      :size="size"
                  />
                </el-form-item>
              </div>
  
            </div>
          </div>
          <div class="text-center mb-3">
            <el-button
                type="success"
                :loading="isLoading"
                @click="submitForm(demoFormRef)"
            >Schedule Demo</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { bookDemo } from "@/api/property-manager/demo";
  import { ElNotification } from "element-plus";
  
  const demoFormRef = ref();
  const isLoading = ref(false);
  const body = document.getElementsByTagName("body")[0];
  
  export default {
    name: "penalty-form",
  
 
    setup() {
  
      const demoForm = reactive({
        name: "",
        message: "",
        email: "",
        date: "",
      });
  
      const rules = reactive({
  
        name: [
          {
            required: true,
            message: "Please input name",
            trigger: ["blur", "change"],
          },
        ],
     
        email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
        date: [
          {
            required: true,
            message: "Please select date",
            trigger: ["blur", "change"],
          },
        ]
      });
  
  
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {
            isLoading.value = true;
            const formData = new FormData();
            formData.append("date", demoForm.date);
            formData.append("name", demoForm.name);
            formData.append("email", demoForm.email);
            formData.append("message", demoForm.message);
            console.log(formData);
  
            bookDemo(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    demoForm.name = ""
                    demoForm.message = ""
                    demoForm.email = ""
                    demoForm.date = ""
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),
  
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            console.log("error submit!", fields);
          }
        });
      };
  
      return {
        demoForm,
        submitForm,
        rules,
        demoFormRef,
        isLoading,
    
      };
    },
    created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  };
  </script>
  <!-- <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 480px;
    max-height: 530px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 520px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
    margin: 5% 0 0 10px;
    cursor: pointer;
    background-color: #f4443e;
    color: #ffffff;
    border: none;
    height: 30px;
    width: 30px;
    opacity: .75;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease;
  }
  
  .close i {
    font-size: 15px;
  }
  input {
    border-radius: 6px;
  }
  </style>
   -->