<template>
  <div v-if="isLoading"><Loader /></div>
    <div class="py-4 container-fluid" v-else>
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12 pointer" @click="paymentsTable">
              <card
                :title="stats.money.title"
                :value="monthlyCollection"
                :percentage="stats.money.percentage"
                :iconClass="stats.money.iconClass"
                :iconBackground="stats.money.iconBackground"
                :detail="stats.money.detail"
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                :title="stats.users.title"
                :value="dashboardData.total_units"
                :percentage="stats.users.percentage"
                :iconClass="stats.users.iconClass"
                :iconBackground="stats.users.iconBackground"
                :detail="stats.users.detail"
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                :title="stats.clients.title"
                :value="dashboardData.leased_units"
                :percentage="stats.clients.percentage"
                :iconClass="stats.clients.iconClass"
                :iconBackground="stats.clients.iconBackground"
                :percentageColor="stats.clients.percentageColor"
                :detail="stats.clients.detail"
                directionReverse
              ></card>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <card
                :title="stats.sales.title"
                :value="dashboardData.un_leased_units"
                :percentage="stats.sales.percentage"
                :iconClass="stats.sales.iconClass"
                :iconBackground="stats.sales.iconBackground"
                :detail="stats.sales.detail"
                directionReverse
              ></card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <!-- line chart -->
              <div class="card z-index-2">
                <gradient-line-chart
                  :monthly_stats="dashboardData.monthly_stats"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Card from "../../Main/Cards/Card.vue";
  import GradientLineChart from "../../Main/Charts/GradientLineChart.vue";
 
  import {onMounted, ref} from "vue";
  import { fetchDashboardData } from "../../api/landlord/dashboard";
  import Loader from "../components/Loader.vue";
  import {fetchDashboardManagerPayments} from "../../api/property-manager/payments";
  import { useRouter } from "vue-router";


  const dashboardData = ref([]);
  const payments = ref([]);
  const monthlyCollection = ref();
  const isLoading = ref();

  export default {
    name: "dashboard-default",

    setup() {
      const router = useRouter()
      const paymentsTable = ()=>{
        router.push('/manager/payments')
      }
      const fetchPayments = async (filter, page) => {
          isLoading.value = true;
          let entity_params = {};
          try {
              if (filter === undefined) {
                entity_params = ref({});
              } else {
                entity_params = filter;
            }
            const response = await fetchDashboardManagerPayments(entity_params, page)
            payments.value = response.data;

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                isLoading.value = false;
            }
      }
      const fetchData = async () => {
        isLoading.value = true;
        try {
          const dashboardInfo = await fetchDashboardData();
          dashboardData.value = dashboardInfo.data;
          monthlyCollection.value = "ksh " + dashboardData.value.month_collection

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          isLoading.value = false;
        }
      };

      onMounted(() => {
        fetchData();
        fetchPayments();
      });

      return {
        isLoading,
        dashboardData,
        monthlyCollection,
        fetchData,
        payments,
        fetchPayments,
        paymentsTable
      }
    },

    data() {
      return {
        stats: {
          money: {
            title: "Current Month Collection",
            value: "KES. 530,000",
            percentage: "",
            iconClass: "ni ni-money-coins",
            detail: "",
            iconBackground: "bg-gradient-primary",
          },
          users: {
            title: "Total Units",
            value: "230",
            percentage: "",
            iconClass: "fa fa-building-o",
            iconBackground: "bg-gradient-danger",
            detail: "",
          },
          clients: {
            title: "Occupied Units",
            value: "208",
            percentage: "",
            iconClass: "fa fa-hourglass",
            percentageColor: "text-danger",
            iconBackground: "bg-gradient-success",
            detail: "",
          },
          sales: {
            title: "Vacant Units",
            value: "22",
            percentage: "",
            iconClass: "fa fa-hourglass-end",
            iconBackground: "bg-gradient-warning",
            detail: "",
          },
        }
      };
    },
    components: {
      Loader,
      Card,
      GradientLineChart,
    },
  };
  </script>
  <style scoped>
  .pointer{
    cursor:pointer;
  }
  </style>
  