<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading"><Loader /></div>
    <div class="card">
      <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
        <template #icon>
          <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
        </template>
      </el-page-header>
      <div class="card-body pb-2 mt-0">
        <section class="card-area">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click.native="handleClick">
            <el-tab-pane name="payments" v-if="hasPermission('view.payments')">
              <template #label>
                <i class="fa fa-money" aria-hidden="true"></i>&nbsp; &nbsp;Payments
              </template>
              <unit-payments-table
                  :payments="unitPayments"
                  :unitId="unitId"
                  :refresh="fetchPaymentsData"
              />
            </el-tab-pane>
            <el-tab-pane name="water_bills" v-if="hasPermission('view.water')">
              <template #label>
                <i class="fa fa-tint" aria-hidden="true"></i>&nbsp; &nbsp;Water Bills
              </template>
              <water-table
                  :water="unitWaterBills"
                  :searchQuery="searchTerm"
                  :unitId="unitId"
                  :unitRate="unitWaterRate"
                  :refresh="fetchWaterBillsData"
                  :refresh_rates="fetchPreviousReadingAndPerUnitRate"
                  :previousReading="waterPreviousReading"
              />
            </el-tab-pane>
            <el-tab-pane name="unit_charges" v-if="hasPermission('view.unit_charges')">
              <template #label>
                <i class="fa fa-bolt" aria-hidden="true"></i>&nbsp; &nbsp;Unit Charges
              </template>
              <unit-charges-table
                  :charges="unitCharges"
                  :refresh="fetchUnitChargesData"
                  :unitId="unitId"
              />
            </el-tab-pane>
            <el-tab-pane label="Penalties " name="penalties" v-if="hasPermission('view.penalties')">
              <template #label>
                <i class="fa fa-times" aria-hidden="true"></i>&nbsp; &nbsp;Penalties
              </template>
              <penalties-table
                  :penalties="unitPenalties"
                  :refresh="fetchPenaltiesData"
                  :unitId="unitId"
              />
            </el-tab-pane>
            <el-tab-pane name="arrears" v-if="hasPermission('view.arrears')">
              <template #label>
                <i class="fa fa-snowflake-o" aria-hidden="true"></i>&nbsp; &nbsp;Arrears
              </template>
              <arrears-table
                  :arrears="unitArrears"
                  :searchQuery="searchTerm"
                  :refresh="fetchArrearsData"
                  :unitId="unitId"
              />
            </el-tab-pane>
            <el-tab-pane name="deposits" v-if="hasPermission('view.deposits')">
              <template #label>
                <i class="fa fa-dot-circle-o" aria-hidden="true"></i>&nbsp; &nbsp;Deposits
              </template>
              <deposits-table
                  :deposits="unitDeposits"
                  :searchQuery="searchTerm"
                  :refresh="fetchDepositsData"
                  :unitId="unitId"
              />
            </el-tab-pane>
          </el-tabs>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import UnitPaymentsTable from "../../views/components/property-manager/UnitPaymentsTable.vue";
import DepositsTable from "../../views/components/property-manager/DepositsTable.vue";
import ArrearsTable from "../../views/components/property-manager/ArrearsTable.vue";
import WaterTable from "../../views/components/property-manager/WaterTable.vue";
import PenaltiesTable from "../../views/components/property-manager/PenaltiesTable.vue";
import UnitChargesTable from "../../views/components/property-manager/UnitChargesTable.vue";
import Loader from "@/views/components/Loader.vue";
import router from "@/router";
import moment from "moment";
import {fetchData } from "@/api/property-manager/waters";
import {
  fetchUnitArrears,
  fetchUnitDeposits,
  fetchUnitPayments,
  fetchUnitPenalties,
  fetchUnitUnitCharges,
  fetchUnitWaterBills
} from "../../api/property-manager/units";

const isLoading = ref(false);
const unitId = ref(null);
const unitPayments = ref([]);
const unitDeposits = ref([]);
const unitArrears = ref([]);
const unitWaterBills = ref([]);
const unitCharges = ref([]);
const unitPenalties = ref([]);
const activeName = ref('payments');
const searchTerm = ref("");

const unitWaterRate = ref(null);
const waterPreviousReading = ref(null);

export default {
  name: "closed-leases",
  components: {
    Loader,
    DepositsTable,
    UnitPaymentsTable,
    ArrearsTable,
    WaterTable,
    PenaltiesTable,
    UnitChargesTable
  },
  props: {
    property: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const handleClick = () => {}

    const fetchPaymentsData = async (search, page) => {
      searchTerm.value = search;
      console.log(unitId.value)
      try {
        isLoading.value = true;
        const response = await fetchUnitPayments(search, page, unitId.value);
        unitPayments.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchDepositsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchUnitDeposits(search, page, unitId.value);
        unitDeposits.value = response.data;
      } catch (error) {
        console.log("Error fetching deposits:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchArrearsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchUnitArrears(search, page, unitId.value);
        unitArrears.value = response.data;
      } catch (error) {
        console.log("Error fetching arrears:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchWaterBillsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchUnitWaterBills(search, page, unitId.value);
        unitWaterBills.value = response.data;
      } catch (error) {
        console.log("Error fetching water bills:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchPenaltiesData = async (page) => {
      try {
        isLoading.value = true;
        const response = await fetchUnitPenalties(page, unitId.value);
        unitPenalties.value = response.data;
      } catch (error) {
        console.log("Error fetching penalties:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchUnitChargesData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchUnitUnitCharges(search, page, unitId.value);
        unitCharges.value = response.data;
      } catch (error) {
        console.log("Error fetching unit charges:", error);
      } finally {
        isLoading.value = false;
      }
    };

    function fetchPreviousReadingAndPerUnitRate(){

      fetchData(unitId.value)
          .then((data) => {
            unitWaterRate.value = data.data.unit_rate
            waterPreviousReading.value = data.data.previous_reading
          })
          .catch((err) =>
              console.log(err),

          )
    }

    onMounted(() => {
      const unitIdFromRoute = router.currentRoute.value.params.id;
      unitId.value = unitIdFromRoute;
      console.log(router.currentRoute.value.params)
      fetchPaymentsData();
      fetchPreviousReadingAndPerUnitRate();
    });

    watch(activeName, async (newTab, oldTab) => {
      console.log("Active tab changed from", oldTab, "to", newTab);
      if (newTab === "payments") {
        fetchPaymentsData();
      } else if (newTab === "deposits"){
        fetchDepositsData();
      } else if (newTab === "arrears"){
        fetchArrearsData();
      } else if (newTab === "water_bills"){
        fetchWaterBillsData();
        fetchPreviousReadingAndPerUnitRate();
      } else if (newTab === "penalties"){
        fetchPenaltiesData();
      } else if (newTab === "unit_charges"){
        fetchUnitChargesData();
      }
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const goBack = () => {
      router.go(-1);
    }
   

    return {
      goBack,
      dateTime,
      capitalized,
      isLoading,
      searchTerm,
      activeName,
      handleClick,
      unitPayments,
      unitDeposits,
      unitArrears,
      unitWaterBills,
      unitCharges,
      unitPenalties,
      fetchPaymentsData,
      fetchDepositsData,
      fetchArrearsData,
      fetchPenaltiesData,
      fetchWaterBillsData,
      fetchUnitChargesData,
      fetchPreviousReadingAndPerUnitRate,
      unitId,
      unitWaterRate,
      waterPreviousReading,
    };
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    methods: {
      hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
    }, 
};
</script>

<style scoped>

h6 {
  margin: 0;
}
</style>
<style scoped>

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}


ol {
  list-style: none;
  padding: 1rem 0;
}

ol li:before {
  content: "✓";
  color: green;
}
</style>
