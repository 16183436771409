import { api } from "../";

export const uploads = async(body) => {
    let response = await api
        .post("imports", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};