<template>
  <argon-pagination>
    <argon-pagination-item @click="onClickFirstPage" first  :label="DoubleLeftIcon" />
    <argon-pagination-item @click="onClickPreviousPage" prev />
    <div v-for="page in pages" :key="page">
      <argon-pagination-item
          @click="onClickPage(page)"
          :class="{ active: isPageActive(page) }"
          :label="page"
      />
    </div>
    <argon-pagination-item @click="onClickNextPage" next />
    <argon-pagination-item @click="onClickLastPage" last :label="DoubleRightIcon"  />
  </argon-pagination>
</template>

<script>
import ArgonPagination from "../../../components/ArgonPagination.vue";
import ArgonPaginationItem from "../../../components/ArgonPaginationItem.vue";

export default {
  components: { ArgonPagination, ArgonPaginationItem },
  props: {
    DoubleLeftIcon: {
      type: [String, Object],
      required: false,
      default: "<<",
    },
    DoubleRightIcon: {
      type: [String, Object],
      required: false,
      default: ">>",
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    startPage() {
      if (this.currentPage <= this.maxVisibleButtons - 2) {
        return 1;
      } else if (this.currentPage >= this.totalPages - (this.maxVisibleButtons - 2)) {
        return this.totalPages - (this.maxVisibleButtons - 2);
      } else {
        return this.currentPage - Math.floor((this.maxVisibleButtons - 2) / 2);
      }
    },

    pages() {
      const range = [];
      const lastTwoDigits = (num) => (num < 10 ? `${num}` : num.toString().slice(-2));

      if (this.totalPages <= this.maxVisibleButtons) {
        return Array.from({ length: this.totalPages }, (_, i) => lastTwoDigits(i + 1));
      } else {
        if (this.currentPage <= 3) {
          range.push(...Array.from({ length: 5 }, (_, i) => lastTwoDigits(i + 1)));
          if (this.totalPages > 5) {
            range.push("...", lastTwoDigits(this.totalPages));
          }
        } else if (this.currentPage >= this.totalPages - 2) {
          range.push(lastTwoDigits(1), "...");
          if (this.totalPages > 5) {
            range.push(...Array.from({ length: 5 }, (_, i) => lastTwoDigits(this.totalPages - 4 + i)));
          }
        } else {
          range.push(lastTwoDigits(1), "...");
          for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
            if (i + 1 <= this.totalPages) {
              range.push(lastTwoDigits(i + 1));
            }
          }
          range.push("...", lastTwoDigits(this.totalPages));
        }
      }

      return range;

    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      if (page !== "...") {
        this.$emit("pagechanged", page);
      }
    },
    onClickNextPage() {
      const nextPage = this.currentPage + 1;
      if (nextPage <= this.totalPages) {
        this.$emit("pagechanged", nextPage);
      }
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      if (this.currentPage > this.maxVisibleButtons && page === "..." && this.currentPage < this.totalPages - this.maxVisibleButtons + 1) {
        return true;
      } else if (this.currentPage <= this.maxVisibleButtons && page === 1) {
        return true;
      } else if (this.currentPage > this.totalPages - this.maxVisibleButtons + 1 && page === this.totalPages) {
        return true;
      } else {
        return this.currentPage === parseInt(page, 10);
      }
    },
  },
};
</script>
