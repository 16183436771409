<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid mt-6" v-else>
    <div class="card shadow-lg mb-4">
      <div class="card-body p-3">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-lg bg-gradient-primary">
              <h4 class="text-white">{{ getInitials(user.name) }}</h4>
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{ user?.name }}</h5>
              <p class="mb-0 font-weight-bold text-sm">Landlord</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <el-form
            ref="profileFormRef"
            :model="profileForm"
            label-width="auto"
            class="demo-ruleForm"
            size="large"
            status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true"
        >
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Landlord Information</p>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item label="Name" prop="first_name">
                    <el-input v-model="profileForm.first_name" disabled/>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="profileForm.email" disabled/>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Role" prop="role">
                    <el-input v-model="profileForm.role" disabled />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Kra Pin" prop="kra_pin">
                    <el-input v-model="profileForm.kra_pin" disabled />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item label="Created Date" prop="created_at">
                    <el-input v-model="profileForm.created_at" disabled />
                  </el-form-item>
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
        </el-form>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="text-uppercase text-sm">Landlord Properties Statistics</p>
            </div>
          </div>
          <div class="card-body">

          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="card">
          <div class="card-body">
            <p class="text-uppercase text-sm">Landlord Properties</p>
            <properties-table
                :properties="properties"
                :refresh="fetchProperties"
                :landlord="landlord"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import setNavPills from "../../../assets/js/nav-pills.js";
import setTooltip from "../../../assets/js/tooltip.js";
import Loader from "../../components/Loader.vue"
import { reactive, ref } from "vue";
import moment from "moment";
import { fetchLandlordProperties, fetchLandlordDetails } from "../../../api/property-manager/landlords";
import PropertiesTable from "./PropertiesTable.vue";

const body = document.getElementsByTagName("body")[0];
const profileFormRef = ref();
const searchTerm = ref();
const user = ref({});
const properties = ref({});
const isLoading = ref(false);
const landlord = ref(false);
const isUpdatingProfile = ref(false);
const showMenu = ref(false);

export default {
  name: "landlord profile",
  components: {PropertiesTable, Loader },
  props: {
    landlord_id: {
      required: true,
    },
  },
  setup(props) {

    const profileForm = reactive({
      email: "",
      phone: "",
      address: "",
      first_name: "",
      last_name: "",
      created_at: "",
      role: "",
    });

    const fetchProperties = async (search, page) => {
      searchTerm.value = search;
      isLoading.value = true;
      try {
        const response = await fetchLandlordProperties(props.landlord_id, search, page);
        properties.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchDetails = async () => {
      isLoading.value = true;
      try {
        const response = await fetchLandlordDetails(props.landlord_id);
        let data = response.data;
        user.value = response.data;

        profileForm.first_name = data.name || "Unavailable";
        profileForm.email = data.email || "Unavailable";
        profileForm.kra_pin = data.kra_pin || "Unavailable";
        profileForm.phone = data.phone || "Unavailable";
        profileForm.address = data.phy_add || "Unavailable";
        profileForm.role = "Landlord";
        profileForm.created_at = moment(data.created_on).format("DD/MM/YYYY") || "Unavailable";
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return {
      showMenu,
      landlord,
      properties,
      fetchDetails,
      fetchProperties,
      profileForm,
      user,
      getInitials,
      isLoading,
      profileFormRef,
      resetForm,
      isUpdatingProfile,
    };
  },

  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
  },
  created() {
    this.fetchDetails();
    this.fetchProperties();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
