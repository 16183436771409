<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <tenant-statement-view/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import TenantStatementView from "../components/property-manager/TenantStatementView.vue";
  import { fetchTenants } from "../../api/property-manager/tenants";
  import { ElLoading } from "element-plus";
  import { ref } from "vue";
  
  const isLoading = ref(false);
  const tenants = ref([]);
  const searchTerm = ref("");
  export default {
    name: "accounting-report",
    components: {
      TenantStatementView
    },
    setup() {
      const fetchData = async (search, page) => {
        searchTerm.value = search;
        isLoading.value = true;
        try {
          const response = await fetchTenants(search, page);
          tenants.value = response.data;
          console.log(response.data)
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };
  
      const fullScreenLoader = () => {
        ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      };
  
  
      return {
        fullScreenLoader,
        fetchData,
        tenants,
        isLoading,
        searchTerm
      };
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  