<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-grav text-primary me-2" />{{
          props.managerialUser.id != null ? "Edit User" : "New User"
        }}
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="managerialUserFormRef"
          :model="managerialUserForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <el-form-item label="First Name" prop="first_name">
                <el-input v-model="managerialUserForm.first_name" />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item label="Last Name" prop="last_name">
                <el-input v-model="managerialUserForm.last_name" />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item label="Email" prop="email">
                <el-input v-model="managerialUserForm.email" />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item label="Address" prop="address">
                <el-input v-model="managerialUserForm.address" />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <label prop="role" class="el-form-item__label">Role</label>
              <el-form-item prop="role">
                <el-select-v2
                    prop="role"
                    v-model="managerialUserForm.role"
                    filterable
                    :options="roles"
                    style="width: 100%"
                />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <label class="el-form-item__label">Phone Number</label>
              <el-form-item prop="phone">
                <vue-tel-input
                    v-model="managerialUserForm.phone"
                    :value="managerialUserForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                    style="width: 100%"
                ></vue-tel-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item label="Password" prop="password">
                <el-input v-model="managerialUserForm.password" />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(managerialUserFormRef)"
          >
            {{
              props.managerialUser.id != null
                  ? "Update User"
                  : "Create User"
            }}</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { updateManagerialUser, createManagerialUser } from "../../../api/property-manager/managerialUsers";
import { unPagineatedRoles } from "../../../api/property-manager/roles";
import { ElNotification } from "element-plus";

const managerialUserFormRef = ref();
const isLoading = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");
const roles = ref([]);

const phoneBindingProps = {
  mode: "international",
  styleClasses: "has-default",
  defaultCountry: "KE",
  onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
  inputOptions: {
    showDialCode: false,
    placeholder: "Mobile Number",
    name: "phone",
  },
};
export default {
  name: "managerial-user-form",

  props: {
    managerialUser: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const managerialUserForm = reactive({
      id: props?.managerialUser?.id || 0,
      email: props?.managerialUser?.email || "",
      role: props?.managerialUser?.role || "",
      first_name: props?.managerialUser?.firstName || "",
      last_name: props?.managerialUser?.lastName || "",
      address: props?.managerialUser?.userAddress || "",
      password: "",
      phone: props?.managerialUser?.primaryPhone || ""
    });

    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input correct email address",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        {
          required: true,
          message: "Please input your phone number",
          trigger: ["blur", "change"],
        },

        {
          min: 6,
          max: 255,
          message: "Minimum characters should be 6",
          trigger: "blur",
        },
      ],
      first_name: [
        {
          required: true,
          message: "Please input first name",
          trigger: ["blur", "change"],
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input last name",
          trigger: ["blur", "change"],
        },
      ],
      address: [
        {
          required: true,
          message: "Please input address",
          trigger: ["blur", "change"],
        },
      ] ,
      role: [
        {
          required: true,
          message: "Please select a role",
          trigger: ["blur", "change"],
        },
      ]
    });

    const checkMobileNumber = (event) => {
      if (event.valid) {
        managerialUserForm.phone = event.number;
        phoneBindingProps.styleClasses = "has-success";
      } else {
        managerialUserForm.phone = "";
        phoneBindingProps.styleClasses = "has-danger";
      }
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {

          isLoading.value = true;
          const formData = new FormData();
          formData.append("id", managerialUserForm.id);
          formData.append("email", managerialUserForm.email);
          formData.append("roleId", managerialUserForm.role);
          formData.append("firstName", managerialUserForm.first_name);
          formData.append("lastName", managerialUserForm.last_name);
          formData.append("userAddress", managerialUserForm.address);
          formData.append("password", managerialUserForm.password);
          formData.append("primaryPhone", managerialUserForm.phone);
          formData.append("manager_ID", propertyManagerId);

          if (managerialUserForm.id > 0) {
            updateManagerialUser(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-managerial-users");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>

                    ElNotification({
                      title: "Error",
                      message: err.message,
                      type: "error",
                    })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            createManagerialUser(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-managerial-users");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),

                )
                .finally(() => {
                  isLoading.value = false;
                });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      managerialUserForm,
      submitForm,
      rules,
      managerialUserFormRef,
      isLoading,
      roles,
      props,
      phoneBindingProps,
      checkMobileNumber,
    };
  },
  created() {
    this.fetchRolesList();
  },
  methods: {
    async fetchRolesList() {
      try {
        const response = await unPagineatedRoles();

        const tempRoles = response.data.map((role) => ({
          value: `${role.id}`,
          label: `${role.name}`,
        }));
        roles.value = tempRoles;

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 700px;
  max-height: 530px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}


</style>
