<template>
    <div class="card">
      <div class="card-header pb-0 mb-5 d-flex justify-content-between">
      <div class="d-flex align-items-center gap-2 ">
        <i class="fa fa-list text-primary text-md opacity-10"></i>
        <h6 class="">Total Expected Amount</h6>
      </div>
      <div class="cta">
        <!-- <FilterTablesForm
            :show_logs_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        /> -->
        <el-button-group>
            <el-button
                type="info"
                @click="submitExcelForm(accountFormRef)"
            >
              <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
            </el-button>
            <el-button
                type="warning"
                @click="downloadPdf(accountFormRef)"
            >
              <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
            </el-button>
          </el-button-group>
       </div>
      </div>
         <div class="card-body px-0 pt-0 pb-2">
          <div v-if="response.data.length === 0">
        <empty-state-table />
       </div>
       <div class="table-responsive p-0" v-else>
         <div class="card-table">
           <table class="table align-items-center mb-0">
             <thead class="sticky-top">
             <tr>
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                 Tenant Name
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
               >
                 Phone
               </th>
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 UnitCode
               </th>
 
 
               <th
                   class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
               >
                 Amount
               </th>
             </tr>
             </thead>
             <tbody>
              <tr v-for="(item, index) in response.data" :key="index">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex align-items-center">
                     <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                         getInitials(
                             item.tenant?.firstName + " " + item.tenant?.lastName 
                         ).toUpperCase()
                       }}
                     </div>
                     <p class="text-xs font-weight-bold mb-0">{{ item.tenant?.firstName + " " + item.tenant?.lastName }}</p>
                   </div>
                 </div>
               </td>
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.tenant?.primaryPhone }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.unit?.unitCode }}</p>
               </td>
 
               <td>
                 <p class="text-xs font-weight-bold mb-0">{{ item.accounts[0]?.balance }}</p>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
       <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="paginate.total_pages"
            :perPage="paginate.per_page"
            :currentPage="paginate.current_page"
            @pagechanged="onPageChange"
        />
       </div>
     </div>
    </div>
   
 </template>
 <script  >
 import Pagination from "./Pagination.vue";
 import EmptyStateTable from "../../components/EmptyTableState.vue";
 import { ref, reactive } from "vue";
 import moment from "moment";
 import { ElMessage } from "element-plus";
//  import FilterTablesForm from "./FilterTablesForm.vue";
import {
    downloadExpectedExcel,
    downloadExpectedPdf
  } from "../../../api/property-manager/downloads";
 const filter_params = ref({});
 const date = ref('')
export default {
  name: "logs-table",
  props: {
    response: {
      type: Object,
      required: true,
    },
    propertyId: {
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    }
  },

  components: {
    Pagination,
    EmptyStateTable,
    // FilterTablesForm 
    
  },

  setup(props){
  const total_records = props.response?.total || 0;
  const per_page = props.response?.per_page || 0;
  const total_pages = ref(0);
  if (per_page > 0 && total_records > 0) {
    total_pages.value = Math.ceil(total_records / per_page);
  }

  const paginate = reactive({
    current_page: props.response?.current_page || 1,
    total_pages: total_pages,
    per_page: per_page,
  });
  const onPageChange = () => {
   props.refresh(filter_params, 1)
  };

  const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

  const dateTime = (value) => {
    return moment(value).format("Do MMM YYYY HH:mm:ss");
  };
  const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    async function submitExcelForm(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadExpectedExcel(filter_params, props.propertyId)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "expected amount_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
       //
      }
    }

   async function downloadPdf(){
    try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadExpectedPdf(filter_params,  props.propertyId)
            .then((Response) => {
              console.log('started downloading');
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "expected amount_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        //
      }
    }

  
  return {
    _filterCallBack,
    filter_params,
    onPageChange,
    paginate,
    getInitials,
    dateTime,
    date,
    submitExcelForm,
    downloadPdf
  }
  },

}
 </script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
  padding: 1.5rem;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
