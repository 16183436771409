import {api} from "@/api";

export const createDeposit = async(body) => {
    let response = await api
        .post("deposits", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeDeposit = async(id) => {
    let response = await api
        .delete(`deposits/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
