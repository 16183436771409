<template>
  <div class="card">
<!--    <div class="card-body">-->
<!--      <p class="text-uppercase text-sm">Imports From CSV</p>-->
<!--    </div>-->
    <div class="contant-info">
      <dl>
        <dt class="dd-with-button">Upload Lease</dt>
        <dd class="dd-with-button"><button type="button" @click="uploadButton('Lease')" class="btn uploads btn-success">Upload </button></dd>
        <dt class="dd-with-button">Upload Water Bills</dt>
        <dd class="dd-with-button"><button type="button" @click="uploadButton('Water Bills')" class="btn uploads btn-success">Upload</button></dd>
        <dt class="dd-with-button">Upload Bulk Payments</dt>
        <dd class="dd-with-button"><button type="button" @click="uploadButton('Bulk Payments')" class="btn uploads btn-success">Upload</button></dd>
        <dt class="dd-with-button">Upload Bulk Arrears</dt>
        <dd class="dd-with-button"><button type="button" @click="uploadButton('Bulk Arrears')" class="btn uploads btn-success">Upload</button></dd>
        <dt class="dd-with-button">Upload Bulk Deposit</dt>
        <dd class="dd-with-button"><button type="button" @click="uploadButton('Bulk Deposits')" class="btn uploads btn-success">Upload</button></dd>
      </dl>
    </div>
  </div>
  <UploadsForm
      v-if="showUploadsModal"
      @close-modal="showUploadsModal = false"
      :uploads="uploadsDetails"
      @refresh-uploads="refresh"
  />
</template>
<script>

import { ref } from "vue";
import UploadsForm from "@/components/Modals/property-manager/UploadsForm.vue";

const uploadsDetails = ref(false);
const showUploadsModal = ref(false);

export default {
  name: "Uploads CSVs Table",
  components: {
    UploadsForm
  },
  props: {
    propertyId: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    }
  },
  setup(props){

    const uploadButton = (variable) => {

      let name = ""
      if (variable === "Lease") {
        name = "lease"
      } else if (variable === "Water Bills") {
        name = "water_bills"
      } else if (variable === "Bulk Payments") {
        name = "payments"
      } else if (variable === "Bulk Arrears") {
        name = "arrears"
      } else if (variable === "Bulk Deposits") {
        name = "deposits"
      }

      uploadsDetails.value = {
        property_id: props.propertyId,
        label: variable,
        name: name
      };
      showUploadsModal.value = true;
    };


    return {
      uploadsDetails,
      uploadButton,
      showUploadsModal,
      props
    }
  }
}
</script>
<style scoped>
.card-body {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
dt {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: #495057;
  margin-bottom: 0.5rem;
}
dl {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 20px 0;
  padding: 0 16px;
}
dd {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #495057;
}
.sidebar dl {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 30px 0 10px;
}
.contant-info dl {
  display: grid;
  grid-template-columns: 30% 70%;
}

.dd-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1px;
}

.uploads {
  margin: 20px auto;
  display: block;
}
h6 {
  margin: 0;
}

</style>