<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
                :title="stats.money.title"
                :value="monthlyCollection"
                :percentage="stats.money.percentage"
                :iconClass="stats.money.iconClass"
                :iconBackground="stats.money.iconBackground"
                :detail="stats.money.detail"
                directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
                :title="stats.users.title"
                :value="dashboardData.total_units"
                :percentage="stats.users.percentage"
                :iconClass="stats.users.iconClass"
                :iconBackground="stats.users.iconBackground"
                :detail="stats.users.detail"
                directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
                :title="stats.clients.title"
                :value="dashboardData.property_managers"
                :percentage="stats.clients.percentage"
                :iconClass="stats.clients.iconClass"
                :iconBackground="stats.clients.iconBackground"
                :percentageColor="stats.clients.percentageColor"
                :detail="stats.clients.detail"
                directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
                :title="stats.sales.title"
                :value="dashboardData.un_leased_units"
                :percentage="stats.sales.percentage"
                :iconClass="stats.sales.iconClass"
                :iconBackground="stats.sales.iconBackground"
                :detail="stats.sales.detail"
                directionReverse
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card z-index-2">
              <gradient-line-chart
                  :monthly_stats="dashboardData.monthly_stats"
              />
            </div>
          </div>
<!--          <div class="col-lg-6">-->
<!--            <div class="card  z-index-2">-->

<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../../Main/Cards/Card.vue";
import GradientLineChart from "../../Main/Charts/GradientLineChart.vue";
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import {onMounted, ref} from "vue";
import { fetchDashboardData } from "../../api/admin/dashboard";
import Loader from "../components/Loader.vue";


const dashboardData = ref([]);
const payments = ref([]);
const monthlyCollection = ref();
const isLoading = ref();

export default {
  name: "dashboard-default",

  setup() {

    const fetchData = async () => {
      isLoading.value = true;
      try {
        const dashboardInfo = await fetchDashboardData();
        dashboardData.value = dashboardInfo.data;
        monthlyCollection.value = "ksh " + dashboardData.value.month_collection

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      isLoading,
      dashboardData,
      monthlyCollection,
      fetchData,
      payments,
    }
  },

  data() {
    return {
      stats: {
        money: {
          title: "Current Month Collection",
          value: "KES. 530,000",
          percentage: "",
          iconClass: "ni ni-money-coins",
          detail: "",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Total Units",
          value: "230",
          percentage: "",
          iconClass: "fa fa-building-o",
          iconBackground: "bg-gradient-danger",
          detail: "",
        },
        clients: {
          title: "Billable Managers",
          value: "208",
          percentage: "",
          iconClass: "fa fa-user-plus",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "",
        },
        sales: {
          title: "Vacant Units",
          value: "22",
          percentage: "",
          iconClass: "fa fa-hourglass-end",
          iconBackground: "bg-gradient-warning",
          detail: "",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    Loader,
    Card,
    GradientLineChart,
  },
};
</script>
