<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-credit-card text-primary text-md opacity-10"></i>
        <h6>Payments</h6>
      </div>
      <div  v-if="isNotDashboard" class="cta">
        <!-- <button
        v-if="hasPermission('create.payments')"
            class="btn mb-0 btn-primary  btn-xs null null ms-auto"
            @click="initiatePayment"
        >
          <i class="fas fa-plus text-white me-1" aria-hidden="true"></i>Initiate
          Payment
        </button>
        <button
            v-if="hasPermission('create.payments')"
            class="btn mb-0 btn-success btn-xs null null ms-auto"
            @click="newPayment"
        >
          <i class="fas fa-plus  text-white me-1" aria-hidden="true"></i>New
          Payment
        </button> -->
        <FilterTablesForm
            :show_payments_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
        <el-button-group>
          <el-button
              type="info"
              @click="submitExcelForm()"
          >
            <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Excel
          </el-button>
          <el-button
              type="warning"
              @click="downloadPdf()"
          >
            <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; PDF
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="card-body">
      <div v-if="payments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Unit Information
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start"
              >
                Tenant Name
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>


              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Balance
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Transaction Reference
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Payment Source
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment Date
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in payments.data" :key="item.payId + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.units?.property?.propertyName
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.unitCode }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item.units?.property?.propertyName }}</p>
                  </div>
                </div>
              </td>

              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item?.units?.leases[0]?.tenant?.firstName  + " " + item?.units?.leases[0]?.tenant?.lastName }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ item?.units?.leases[0]?.tenant?.primaryPhone }}</p>
                  </div>
                </div>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item.amountPaid) }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ formatNumber(item?.units?.leases[0]?.accounts[0]?.balance) }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.thirdpartyPaymentRef }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.source }}</p>
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.paymentDate)
                    }}</span>
              </td>
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                      v-if="hasPermission('print.receipt')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="printInvoicePdf(item)"
                      ><i class="fa fa-print" aria-hidden="true"></i>
                        View Receipt
                      </el-dropdown-item>
                      <el-dropdown-item
                          class="text-secondary font-weight-bold text-xs"
                          @click="downloadReceipt(item.payId)"
                      ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Download Receipt
                      </el-dropdown-item>
                      <router-link
                          :to="{ path: `/unit/${item.unitId}` }"
                          class="text-secondary"
                      ><el-dropdown-item
                          v-if="hasPermission('view.tenants')"
                          class="text-secondary font-weight-bold text-xs"
                      ><i class="fa fa-eye" aria-hidden="true"></i>
                        View Tenant</el-dropdown-item
                      ></router-link
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
    <ManualPaymentForm
        v-if="showModal"
        @close-modal="showModal = false"
        :tenantsDropdown="tenantsDropdown"
        @refresh="refresh"
    />
    <StkPushForm
        v-if="showStkModal"
        @close-modal="showStkModal = false"
        @refresh-payments="refresh"
    />
    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1000"
        filename="receipt.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-content-width="120%"
        @progress="onProgress($event)"
        ref="html2Pdf"
    >
    <template v-slot:pdf-content>
      <PrintReceiptForm
          :payment="payment"
      />
    </template>
  </vue3-html2pdf>
</template>

<script>
import ManualPaymentForm from "../../../components/Modals/property-manager/ManualPaymentForm.vue";
import StkPushForm from "../../../components/Modals/property-manager/StkPushForm.vue";
import EmptyStateTable from "../EmptyTableState.vue";
import moment from "moment";
import { ref, reactive } from "vue";
import Pagination from "../property-manager/Pagination.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import PrintReceiptForm from "../../../components/Modals/property-manager/PrintReceiptForm.vue";
import FilterTablesForm from "../admin/FilterTablesForm.vue";
import { ElMessage } from "element-plus";
import {
  downloadPaymentsExcel,
  downloadPaymentsPdf, downloadReceiptPdf
} from "../../../api/property-manager/downloads";

const showModal = ref(false);
const showStkModal = ref(false);
const managerTenants = ref({});
const filter_params = ref({});
const modalData = ref({});
const isLoading = ref(false);
const tenantsDropdown = ref(false);
const download_perc = ref(0);
const showProgress = ref(false);

export default {
  name: "admin-payments-table",
  props: {
    payments: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    isNotDashboard: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    PrintReceiptForm,
    Pagination,
    ManualPaymentForm,
    EmptyStateTable,
    StkPushForm,
    Vue3Html2pdf,
    FilterTablesForm,
  },

  setup(props) {
    const htmlToPdfOptions = {
      margin: 0.15,
      enableLinks: false,
      image: {
        type: 'jpeg',
        quality: 1,
      },
      html2canvas: {
        scale: 4,
        useCORS: true,
      },
      jsPDF: {
        unit: "in",
        format: [4.55, 8],
        orientation: "portrait",
        fontSize: 10,
      }
    };

    const onProgress = async (percentage) => {
      if (percentage == 100) showProgress.value = false;
      else {
        showProgress.value = true;
      }
      download_perc.value = percentage;
    };
    const total_records = props?.payments?.total || 0;
    const per_page = props?.payments?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.payments?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });
    const onPageChange = (page) => {
      props.refresh(filter_params, page);
      props.refreshPayments(page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY HH:mm:ss");
    };
    const newPayment = () => {
      tenantsDropdown.value = true;
      showModal.value = true;
    };

    const initiatePayment = (tenants) => {
      managerTenants.value = tenants;
      showStkModal.value = true;
    }
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };
    async function submitExcelForm(){
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadPaymentsExcel(filter_params)
            .then((Response) => {
              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/vnd.ms-excel",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "payments_" + Math.round(+new Date() / 1000) + ".xlsx"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

   async function downloadPdf(){
    try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Your report will be downloading shortly. Please wait...",
        });

        await downloadPaymentsPdf(filter_params)
            .then((Response) => {
              console.log(Response)

              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "payments_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

    async function downloadReceipt(id){
    try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Receipt will be downloading shortly. Please wait...",
        });
      isLoading.value = true;

        await downloadReceiptPdf(id)
            .then((Response) => {
              console.log(Response)

              let fileURL = window.URL.createObjectURL(
                  new Blob([Response], {
                    type: "application/pdf",
                  })
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                  "download",
                  "tenant_receipt_" + Math.round(+new Date() / 1000) + ".pdf"
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);

              ElMessage({
                type: "error",
                message: "Whoops! something went wrong downloading report",
              });
            });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }


    return {
      downloadPdf,
      submitExcelForm,
      formatNumber,
      Pagination,
      onPageChange,
      dateTime,
      getInitials,
      showModal,
      showStkModal,
      tenantsDropdown,
      modalData,
      managerTenants,
      newPayment,
      initiatePayment,
      isLoading,
      onSelectHandler,
      htmlToPdfOptions,
      onProgress,
      _filterCallBack,
      filter_params,
      downloadReceipt
    };
  },
 
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      payment: null,
      userPermissions: storedPermissions || [],
    };
  },

  methods: {
    printInvoicePdf(payment) {
      this.payment = payment;
      this.$refs.html2Pdf.generatePdf();
    },
    hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
  },
};
</script>

<style scoped>

.cta button {
  margin-right: 10px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

h6 {
  margin: 0;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
