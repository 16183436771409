import {api} from "@/api";

export const sendPasswordsToTenants = async(body) => {
    let response = await api
        .post("send-passwords", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const ComposeBlastSmsToTenants = async(body) => {
    let response = await api
        .post("compose-blast-sms", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sendInvoiceToTenants = async(body) => {
    let response = await api
        .post("send-invoices", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sendWaterBillsToTenants = async(body) => {
    let response = await api
        .post("send-water-bills", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const sendScheduledSMSToTenants = async(body) => {
    let response = await api
        .post("send-scheduled-sms", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const ComposeSMSToTenant = async(body) => {
    let response = await api
        .post("send-tenant-sms", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


export const ComposeInvoiceSMSToTenant  = async(body) => {
    let response = await api
        .post("send-tenant-invoice", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
