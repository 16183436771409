<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-file-archive-o text-primary text-md opacity-10"></i>
        <h6>Unallocated Payments</h6>
      </div>
      <div class="cta">
        <FilterTablesForm
            :show_unallocated_payments_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
      </div>
    </div>
    <div class="card-body">
      <div v-if="unallocatedPayments.data.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  text-start"
              >
                Payment Source
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Transaction Reference
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Paid By
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Amount
              </th>

              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Unit Code
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Payment Date
              </th>

              <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in unallocatedPayments.data" :key="item.id + index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <div class="avatar avatar-sm me-3 bg-gradient-primary">
                      {{
                        getInitials(
                            item.paymentSource
                        ).toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ item.paymentSource }}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.thirdpartyTranRef }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.paidInByName }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.paymentAmount }}</p>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.paymentRef }}</p>
              </td>

              <td class="align-middle text-center text-sm">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(parseInt(item.status))"
                  >{{ parseInt(item.status) === 0 ? "Unallocated" : "Allocated" }}</span
                  >
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.created_at)
                    }}</span>
              </td>
              <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                          v-if="parseInt(item.status) === 0 && hasPermission('allocate.unallocated_payments')"
                          class="text-secondary font-weight-bold text-xs"
                          @click="allocatePayment(item)"
                      ><i class="fa fa-bookmark" aria-hidden="true"></i>
                        Allocate
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>

  <AllocatePaymentForm
      v-if="showModal"
      @close-modal="showModal = false"
      :paymentToAllocate="paymentToAllocate"
      @refresh="refresh"
  />

</template>

<script>

import EmptyStateTable from "../EmptyTableState.vue";
import moment from "moment";
import { ref, reactive } from "vue";
import {fetchLeasedManagerTenants} from "@/api/property-manager/tenants";
import Pagination from "../property-manager/Pagination.vue";
import AllocatePaymentForm from "../../../components/Modals/property-manager/AllocatePaymentForm.vue";
import FilterTablesForm from "../admin/FilterTablesForm.vue";

const isLoading = ref(false);
const tenantsDropdown = ref(false);
const tenants = ref([]);
const paymentToAllocate = ref([]);
const showModal = ref(false);
const filter_params = ref({});


export default {
  name: "admin-unallocated-payments-table",
  props: {
    unallocatedPayments: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  components: {
    FilterTablesForm,
    Pagination,
    EmptyStateTable,
    AllocatePaymentForm
  },

  setup(props) {

    const paymentForm = reactive({
      id: props?.unallocatedPayments?.unitId || 0,
      reference_no: props?.unallocatedPayments?.reference_no || "",
      amount: props?.unallocatedPayments?.amount || "",
      date: props?.unallocatedPayments?.date || "",
      propertyId: props?.unallocatedPayments?.propertyId || "",
      date_range: "",
      tenant: ""
    });

    const total_records = props?.unallocatedPayments?.total || 0;
    const per_page = props?.unallocatedPayments?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.unallocatedPayments?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });
    const onPageChange = (page) => {
      props.refresh(filter_params, page);
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };
    const onSelectHandler = () => {};
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const allocatePayment = (item) => {
      paymentToAllocate.value = item
      showModal.value = true;
      console.log(item)
    }
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

   

    return {
     
      showModal,
      paymentToAllocate,
      allocatePayment,
      Pagination,
      onPageChange,
      dateTime,
      getStatusColor,
      getInitials,
      tenantsDropdown,
      isLoading,
      onSelectHandler,
      paymentForm,
      tenants,
      filter_params,
      _filterCallBack,
      shortcuts
    };
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
    },
    
  created() {
    this.fetchTenantList();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.tenantId}`,
          label: `${tenant.firstName  + " " + tenant.lastName  + " - " + tenant.leases[0]?.unit?.unitCode}`,
        }));

        tenants.value = tempTenants;
        console.log(tenants)
      } catch (error) {
        console.log(error);
      }
    },
    hasPermission(slug) {
        return this.userPermissions.includes(slug);
      },
  },
};
</script>

<style scoped>

.cta button {
  margin-right: 10px;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
  
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}

.custom-green-button{
  background-color: #1bb11b;
  color: #fff;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>
