<template>

  <div  class="py-4 container-fluid">
    <div  class="row">
      <div v-if="isLoading"><Loader /></div>
      <div v-else class="col-12">
        <div class="card">
          <div class="card-header pb-0 mb-5">
            <div class="d-flex align-items-center gap-2">
              <i class="fa fa-paper-plane text-primary text-md opacity-10"></i>
              <h6>Send KRA Invoices</h6>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card-body">
              <p class="text-uppercase text-sm">Property Information</p>
              <div class="row">
                <div class="col-md-4 with-vertical-line">
                  <dl>
                    <dt v-if="property?.propertyName">Property Name</dt>
                    <dd v-if="property?.propertyName">
                      {{ capitalized(property?.propertyName) }}
                    </dd>
                    <dt v-if="property?.propertyAddress">Property Location</dt>
                    <dd v-if="property?.propertyAddress">
                      {{ capitalized(property?.propertyAddress) }}
                    </dd>
                  </dl>
                </div>
                <div class="col-md-4 with-vertical-line">
                  <dl>
                    <dt v-if="property?.landlords?.name">Landlord Name</dt>
                    <dd v-if="property?.landlords?.name">
                      {{ capitalized(property.landlords.name) }}
                    </dd>
                    <dt v-if="property?.lastUpdated">Created Date</dt>
                    <dd v-if="property?.lastUpdated">
                      {{ dateTime(property.lastUpdated) }}
                    </dd>
                  </dl>
                </div>
                <div class="col-md-3">
                  <dl>
                    <dt v-if="property?.landlord_kra_pin">Landlord Pin</dt>
                    <dd v-if="property?.landlord_kra_pin">
                      {{ capitalized(property.landlord_kra_pin) }}
                    </dd>
                    <dt v-if="property?.kra_rate">KRA Rate</dt>
                    <dd v-if="property?.kra_rate">{{ property?.kra_rate }}</dd>
                  </dl>
                </div>
              </div>
              <hr class="horizontal dark" />
            </div>
          </div>
          <div class="px-0 pt-0 pb-2">
            <div class="card-body">
              <el-tabs
                v-model="activeName"
                class="demo-tabs"
                @tab-click.native="handleClick"
              >
                <el-tab-pane name="payments">
                  <template #label>
                    <i class="fa fa-credit-card" aria-hidden="true"></i
                    >&nbsp;&nbsp; Payments
                  </template>
                  <PaymentEtimsInvoice
                    :refresh="fetchData"
                    :payments="payments"
                  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { fetchCurrentPayments } from "@/api/property-manager/payments";
import { fetchPropertyDetails } from "@/api/property-manager/properties";
import moment from "moment/moment";
import Loader from "../Loader.vue";
import { useRoute } from "vue-router";
import EmptyStateTable from "../EmptyTableState.vue";
import PaymentEtimsInvoice from "@/views/components/property-manager/PaymentEtimsInvoice.vue";
import eTimsUnitsTable from "@/views/components/property-manager/eTimsUnitsTable.vue";

const selected = ref([]);
const payments = ref([]);
const property = ref([]);
const isLoading = ref(true);
const activeName = ref("payments");
const handleClick = () => {};

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    eTimsUnitsTable,
    PaymentEtimsInvoice,
    // eslint-disable-next-line vue/no-unused-components
    EmptyStateTable,
    Loader,
  },
  setup() {
    const route = useRoute();
    const fetchData = async () => {
      const propertyId = route.params.property_id;
      isLoading.value = true;
      try {
        const response = await fetchCurrentPayments(propertyId);
        const propertyInfo = await fetchPropertyDetails(propertyId);

        property.value = propertyInfo.data;
        payments.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    watch(activeName, async (newTab) => {
      if (newTab === "payments") {
        await fetchData();
      }
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    return {
      isLoading,
      fetchData,
      property,
      selected,
      capitalized,
      dateTime,
      activeName,
      handleClick,
      payments,
    };
  },
};
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
