<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-grav text-primary me-2" />{{
            props.tenant.tenantId != null ? "Edit Tenant" : "New Tenant"
          }}
        </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
          ref="tenantFormRef"
          :model="tenantForm"
          :rules="rules"
          label-width="150px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="card-body">
            <div class="row">
              <div class="inline-form-group">
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="First Name*" prop="first_name">
                    <el-input v-model="tenantForm.first_name" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Last Name*" prop="last_name">
                    <el-input v-model="tenantForm.last_name" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item" style="margin-top: -2%;">
                  <label class="el-form-item__label">Phone Number*</label>
                  <vue-tel-input
                    v-model="tenantForm.phone"
                    :value="tenantForm.phone"
                    @validate="checkMobileNumber"
                    v-bind="phoneBindingProps"
                  ></vue-tel-input>
               </div>
              </div>

              <div class="inline-form-group">
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Email*" prop="email">
                    <el-input v-model="tenantForm.email" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Kra Pin" prop="kra_pin">
                    <el-input v-model="tenantForm.kra_pin" />
                  </el-form-item>
                </div>
              </div>
              <h6 class="mb-3">Next of Kin Details</h6>
              <div class="inline-form-group">
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Name" prop="">
                    <el-input v-model="tenantForm.kin_name" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item">
                  <el-form-item label="Relationship" prop="">
                    <el-input v-model="tenantForm.kin_relationship" />
                  </el-form-item>
                </div>
                <div class="col-md-12 mb-3 inline-form-item" style="margin-top: -2%;">
                  <label class="el-form-item__label">Phone Number</label>
                  <vue-tel-input
                    v-model="tenantForm.kin_phone"
                    :value="tenantForm.kin_phone"
                    @validate="checkMobileNumber2"
                    v-bind="phoneBindingProps"
                  ></vue-tel-input>
               </div>
              </div>
            </div>
          </div>
          <div class="card--footer">
            <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(tenantFormRef)"
            >
              {{
                props.tenant.tenantId != null
                  ? "Update Tenant"
                  : "Create Tenant"
              }}</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { createTenant, updateTenant } from "../../../api/property-manager/tenants";
  import { ElNotification } from "element-plus";
  
  const tenantFormRef = ref();
  const isLoading = ref(false);


  const phoneBindingProps = {
    mode: "international",
    styleClasses: "has-default",
    defaultCountry: "KE",
    onlyCountries: ["KE", "RW", "UG", "TZ", "ET", "BW"],
    inputOptions: {
      showDialCode: false,
      placeholder: "Mobile Number",
      name: "phone",
    },
  };
  export default {
    name: "tenant-form",
  
    props: {
      tenant: {
        type: Object,
        required: false,
      },
    },
  
    setup(props, { emit }) {
      const tenantForm = reactive({
        id: props?.tenant?.tenantId || 0,
        email: props?.tenant?.email || "",
        kra_pin: props?.tenant?.kra_pin || "",
        first_name: props?.tenant?.firstName || "",
        last_name: props?.tenant?.lastName || "",
        phone: props?.tenant?.primaryPhone || "",
        kin_name: props?.tenant?.kin_name || "",
        kin_relationship: props?.tenant?.kin_relationship || "",
        kin_phone: props?.tenant?.kin_phone || "",
      });
  
      const rules = reactive({
        email: [
          {
            required: true,
            message: "Please input email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Please input your phone number",
            trigger: ["blur", "change"],
          },
  
          {
            min: 6,
            max: 255,
            message: "Minimum characters should be 6",
            trigger: "blur",
          },
        ],
        first_name: [
          {
            required: true,
            message: "Please input first name",
            trigger: ["blur", "change"],
          },
        ],
        last_name: [
          {
            required: true,
            message: "Please input last name",
            trigger: ["blur", "change"],
          },
        ]
      });
  
      const checkMobileNumber = (event) => {
        if (event.valid) {
          tenantForm.phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          tenantForm.phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };
      const checkMobileNumber2 = (event) => {
        if (event.valid) {
          tenantForm.kin_phone = event.number;
          phoneBindingProps.styleClasses = "has-success";
        } else {
          tenantForm.kin_phone = "";
          phoneBindingProps.styleClasses = "has-danger";
        }
      };
  
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {

            isLoading.value = true;
            const formData = new FormData();
            formData.append("id", tenantForm.id);
            formData.append("email", tenantForm.email);
            formData.append("kra_pin", tenantForm.kra_pin);
            formData.append("firstName", tenantForm.first_name);
            formData.append("lastName", tenantForm.last_name);
            formData.append("primaryPhone", tenantForm.phone);
            formData.append("kin_phone", tenantForm.kin_phone);
            formData.append("kin_name", tenantForm.kin_name);
            formData.append("kin_relationship", tenantForm.kin_relationship);

            if (tenantForm.id > 0) {
              updateTenant(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-tenants");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
                )
                .finally(() => {
                  isLoading.value = false;
                });
            } else {
              createTenant(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-tenants");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                console.log(err),
                 
                )
                .finally(() => {
                  isLoading.value = false;
                });
            }
          } else {
            console.log("error submit!", fields);
          }
        });
      };
  
      return {
        tenantForm,
        submitForm,
        rules,
        tenantFormRef,
        isLoading,
        props,
        phoneBindingProps,
        checkMobileNumber,
        checkMobileNumber2
      };
    },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 700px;
    max-height: 530px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 530px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
  input {
    border-radius: 6px;
  }
  .inline-form-group {
    display: flex;
  }

  .inline-form-item {
    flex: 1;
    margin-right: 20px;
  }
</style>
  