<template>
  <div class="invoice-box">
    <table cellpadding="0" cellspacing="0">
      <tr class="top_row">
        <td colspan="4">
          <h2 style="margin-bottom: 0px;">Invoice</h2>
        </td>
        <td style="width:30%; margin-right: 10px;">
<!--         <h6>Invoice No : {{ invoice.invoice_number }}</h6>-->
        </td>
      </tr>
      <tr class="top">
        <td colspan="4">
          <table>
            <tr>
              <td>
                <b>Served By:</b> <br>
                Swifthub Limited, <br>
                Westland, Commercial Center, Block C 3rd Floor, <br>
                Opposite Kenrail Towers, <br>
                P.O Box 79930-00200 Nairobi Kenya,<br>
                mreside@swifthub.co.ke,<br>
                +254711852147. <br>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="information">
        <td colspan="6">
          <table>
            <tr>
              <td colspan="3"><b>Addressed To: </b>
                <br>{{ propertyManager.name }}
                <br>{{ propertyManager.phone }}
                <br>{{ propertyManager.email }}
                <br>{{ propertyManager.phy_add }}
              </td>
              <td><b>Invoice Details</b><br>
                <b>Invoice Number: </b>{{ invoice.invoice_number }}<br>
                <b>Invoice Date: </b>{{ dateTime(invoice.created_at) }}<br>
                <b>Due Date: </b>{{ dueDate(invoice.created_at) }}<br><br>

              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="heading">
        <td style="width:25%;">
          DESCRIPTION
        </td>
        <td style="width:10%; text-align:center;">
          UNITS
        </td>
        <td style="width:10%; text-align:right;">
           PER UNIT (KES)
        </td>
        <td style="width:15%; text-align:right;">
          SUB TOTAL (KES)
        </td>
        <td style="width:15%; text-align:right;">
          VAT ( 16%)
        </td>
        <td style="width:15%; text-align:right;">
          TOTAL (KES)
        </td>
      </tr>
      <tr class="item">
        <td style="width:25%;">
          Recurrent invoice amount for the month of July
        </td>
        <td style="width:10%; text-align:center;">
          {{ formatNumber(invoice.number_of_units) }}
        </td>
        <td style="width:10%; text-align:right;">
          {{ formatNumber(propertyManager.billing_rate) }}
        </td>
        <td style="width:15%; text-align:right;">
          {{ formatNumber(invoice.commisioned_amount) }}
        </td>
        <td style="width:15%; text-align:right;">
          {{ formatNumber(invoice.vat) }}
        </td>
        <td style="width:15%; text-align:right;">
          {{ formatNumber(invoice.total_amount) }}
        </td>
      </tr>
      <br><br>
      <tr class="total">
        <td colspan="6" align="right">Total Amount in Words: <b>Three Hundred Eighty Shillings Only</b></td>
      </tr>
      <tr>
        <td colspan="6">
          <table cellspacing="0px" cellpadding="2px">
            <tr>
              <td width="50%">
                <h6>Declaration:</h6>
                We declare that this invoice shows the actual price of the service
                described above and that all particulars are true and correct.
                <br>
                <h6>Payment Details</h6>
                <b>Bank : </b>Equity Bank, Kilimani<br>
                <b>Account Name: </b>SWIFTHUB LIMITED<br>
                <b>Account Number: </b>1450274203334<br>
                <b>Mpesa Paybill </b>4119871<br>
                <b>Account Number </b>6580083283<br>
              </td>
              <td>
                * This is a computer-generated invoice and does not require a physical signature
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="6" align="center">
          <b>Authorized Signature</b>
          <br>
          <br>
          ...................................
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

import moment from "moment/moment";


export default {
  name: "invoice details",
  props: {
    invoice: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const propertyManager = JSON.parse(localStorage.getItem("propertyManager"));

    const dueDate = (value) => {

      var initialDate = new Date(value);

      initialDate.setDate(initialDate.getDate() + 5);

      var updatedDate = initialDate.toISOString();

      return moment(updatedDate).format("Do MMM YYYY");
    };
    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };
    return {
      propertyManager,
      dateTime,
      dueDate,
      formatNumber
    };
  },
};
</script>

<style scoped>
.top_row{ background-color:#5f924d; }
.td_w{ }
button{ padding:5px 10px; font-size:14px;}
.invoice-box {
  max-width: 890px;
  margin: auto;
  padding:10px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 14px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-bottom: solid 1px #ccc;
}
.invoice-box table td {
  padding: 5px;
  vertical-align:middle;
}
.invoice-box table tr td:nth-child(2) {
  text-align: right;
}
.invoice-box table tr.top table td {
  padding-bottom: 20px;
}
.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}
.invoice-box table tr.information table td {
  padding-bottom: 40px;
}
.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size:12px;
}
.invoice-box table tr.details td {
  padding-bottom: 20px;
}
.invoice-box table tr.item td{
  border-bottom: 1px solid #eee;
}
.invoice-box table tr.item.last td {
  border-bottom: none;
}
.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}
.rtl table {
  text-align: right;
}
.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>

