import {api} from "@/api";

export const fetchTenantDocuments = async(search, page, id) => {
    var url = `property-docs/${id}`;
    if (search)
        url =
            url +
            "&searchFields=unitCode:like;isLeased:like;hoaFee:like;lastUpdated:like;&search=" +
            search;
    if (page) url = url + "?page=" + page;
    url = url.replace(`property-docs/${id}?`, `property-docs/${id}?`);
    let response = await api
        .get(url)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeTenantDocument = async(id) => {
    let response = await api
        .delete(`tenant-docs/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const uploadDocument = async(body) => {
    let response = await api
        .post("tenant-docs", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

