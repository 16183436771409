<template>
  <div class="card">
    <div class="row">
      <div class="card-body px-0 pt-0 pb-2">
        <div  v-if="charges.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive p-0" v-else>
          <div class="card-table">
            <table class="table align-items-center mb-0">
              <thead class="sticky-top">
              <tr>

                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Unit Code
                </th>

                <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Bill Name
                </th>

                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Amount
                </th>

                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Date
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in charges.data" :key="item.id + index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item.unitCode
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item.unitCode }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.billName }}</p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.billAmount }}</p>
                </td>

                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{
                                        dateTime(item.createdAtFromSourceSys)
                                      }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <Pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
      <hr class="horizontal dark" />
    </div>
  </div>
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import {reactive, ref, toRefs, watch} from "vue";
import moment from "moment/moment";

export default {
  name: "Closed Lease Table",
  components: {
    EmptyStateTable,
    Pagination
  },
  props: {
    charges: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  setup(props){
    const { charges, refresh, searchQuery } = toRefs(props);

    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = charges.value?.total || 0;
      const per_page = charges.value?.per_page || 10; // Set a default value if per_page is not available
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(charges, () => {
      updateTotalPages();
      Pagination.current_page = charges.value?.current_page || 1; // Update the current_page property
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: charges.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });
    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    return {
      getInitials,
      dateTime,
      Pagination,
      onPageChange,
    }
  }
}
</script>
<style scoped>
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.fa-user-md {
  font-size: 25px;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
</style>