<template>
  <div class="modal-overlay" @click="$emit('close-uploads-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-upload text-primary me-2" aria-hidden="true"></i> Upload Document
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="uploadsFormRef"
          :model="uploadsForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-form-item label="Tenant" prop="tenant">
                <el-select-v2
                    v-model="uploadsForm.tenant"
                    filterable
                    :options="tenants"
                    placeholder="Please select a tenant"
                    style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Title" prop="title">
                <el-input
                    v-model="uploadsForm.title"
                    placeholder="Please input document title"
                    clearable
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Description" prop="description">
                <el-input
                    v-model="uploadsForm.description"
                    autosize
                    type="textarea"
                    placeholder="Please input description"
                />
              </el-form-item>
            </div>
            <div class="mb-3">
              <el-upload
                  label="Upload"
                  class="upload-demo"
                  drag
                  accept=".xls,.xlsx"
                  action="https://web-api.m-reside.com/api/m-reside/v1/uploads"
                  :on-success="handleUploadSuccess"
                  :on-error="handleUploadError"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Drop Excel file here or <em>click to upload</em>
                </div>
                <div class="el-upload__tip">Excel files only</div>
              </el-upload>
            </div>
            </div>
        </div>

        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >Cancel</button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(uploadsFormRef)">
            Submit
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>

import {reactive, ref} from "vue";
import {ElNotification} from "element-plus";
import { uploadDocument } from "@/api/property-manager/documents";
import {fetchLeasedManagerTenants} from "../../../api/property-manager/tenants";

const uploadsFormRef = ref();
const isLoading = ref(false);
const tenants = ref([]);

export default {
  name: "uploads-form",
  props: {
    uploads: {
      type: Object,
      required: false,
    },
    propertyId: {
      type: Object,
      required: false,
    },
  },


  setup(props, { emit }) {

    const formData = new FormData();

    const handleUploadSuccess = (response, file) => {
      console.log('Upload success:', response, file);
      console.log(file)
      const fileContent = file.raw;

      formData.append("file", fileContent);
    }
    const handleUploadError = (err, file) => {
      console.error('Upload error:', err, file);
    }

    const uploadsForm = reactive({
      file: props?.uploads?.file || 0,
      name: props?.uploads?.name || "",
      description: "",
      title: "",
      tenant: "",
    });

    const rules = reactive({

      file: [
        {
          required: true,
          message: "Please upload file",
          trigger: ["blur", "change"],
        },
      ],
      title: [
        {
          required: true,
          message: "Please input title",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please write down description",
          trigger: ["blur", "change"],
        },
      ],
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          formData.append("propertyId", props?.propertyId);
          formData.append("description", uploadsForm.description);
          formData.append("title", uploadsForm.title);
          formData.append("tenantId", uploadsForm.tenant);

          uploadDocument(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    return {
      submitForm,
      rules,
      uploadsForm,
      uploadsFormRef,
      isLoading,
      tenants,
      props,
      handleUploadSuccess,
      handleUploadError,
    };
  },
  created() {
    this.fetchTenantList();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.tenantId}`,
          label: `${tenant.firstName  + " " + tenant.lastName}`,
        }));

        tenants.value = tempTenants;
        console.log(tenants)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 550px;
  max-height: 560px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}

.card-body {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}

.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}

.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>