import {api} from "@/api";

export const createCharge = async(body) => {
    let response = await api
        .post("unit-charges", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const removeUnitCharge = async(id) => {
    let response = await api
        .delete(`unit-charges/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
 