<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="card" @click.stop>
        <div class="card-header">
          <i class="fa fa-pencil text-primary me-2" />Compose Sms / Email  </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
            ref="messageFormRef"
            :model="messageForm"
            :rules="rules"
            label-width="200px"
            size="" status-icon
            :scroll-to-error="true"
            label-position="top"
            :hide-required-asterisk="true">
       
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-5">
                <div ref="compose_sms_editor"></div>
              </div>
  
            </div>
          </div>
          <div class="card--footer">
            <button
                class="btn mb-0 btn-danger btn-xs null null"
                @click="$emit('close-modal')"
            >
              Cancel
            </button>
  
            <el-button-group>
              <el-button
                  type="primary"
                  :loading="isLoading"
                  @click="submitChecked('email')"
              >
                <i class="fa fa-stack-exchange" aria-hidden="true"></i>&nbsp; Email
              </el-button>
              <el-button
                  type="success"
                  :loading="isLoading"
                  @click="submitChecked('sms')"
              >
                <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; SMS
              </el-button>
            </el-button-group>
          </div>
        </el-form>
      </div>
      <div class="close bg-danger" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import {onMounted, reactive, ref} from "vue";
  import { ElNotification } from "element-plus";
  import { ComposeSMSToTenant } from "@/api/property-manager/communications";
  import Quill from "quill";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.bubble.css";
  import "quill/dist/quill.snow.css";
  
  const isLoading = ref(false);
  
  
  export default {
  
  
    props: {
      unitId: {
        required: true,
      },
      
    },
  
    setup(props, { emit }) {
  
      const compose_sms_editor = ref(null);
      const messageFormRef = ref();
       
      const messageForm = reactive({
      message:  "",
    });
  
      onMounted(() => {
        const quill = new Quill(compose_sms_editor.value, {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              ["bold", "italic", "underline", "strike", "link"],
              [{ list: "bullet" }, { list: "ordered" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              ["image"],
              ["clean"],
              ["page-break"],
              ["template"],
              [{ 'font': [ 'sans-serif', 'serif'] }],
              [{ 'color': [] }],
              ["copy", "cut", "paste"],
              ["preview", "fullscreen"],
              ["table"],
  
            ],
          },
          theme: "snow",
          fonts: {
            'customFont': 'Your Custom Font, sans-serif',
            'Helvetica': 'Helvetica, sans-serif',
            'Arial': 'Arial, sans-serif',
            'Times New Roman': '"Times New Roman", serif',
            'Comic Sans': '"Comic Sans MS", cursive',
            'Tahoma': 'Tahoma, sans-serif',
            'script': 'Script Font, cursive',
            'monospaced': 'Monospace Font, monospace',
            'display': 'Display Font, cursive',
          },
          formats: [
            "bold",
            "underline",
            "strike",
            "header",
            "italic",
            "link",
            "list",
            "indent",
            "align",
            "image",
            "page-break",
            "template",
            "font",
            "link",
            "image",
            "color",
            "preview",
            "fullscreen",
            "table",
          ],
          placeholder: "Type something in here!",
        });
  
        quill.root.classList.add('ql-font-customFont');
  
        quill.getModule('toolbar').addHandler('color', (value) => {
          if (value === 'reset') {
            quill.format('color', false);
          } else {
            quill.format('color', value);
          }
        });

        quill.on("text-change", () => {
        messageForm.message = quill.root.textContent;
      });
  
      });
    const rules = reactive({
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });

      const submitChecked = (method) => {
      const message = messageForm.message;

      if (message === '') {
        ElNotification({
          title: "Error",
          message: "Please type a message",
          type: "error",
        })
        return;
      } 
      const formData = new FormData();
      formData.append("message", message);
      formData.append("unitId", props.unitId);
      formData.append("method", method);
      console.log(props.unitId);

      ComposeSMSToTenant(formData)
          .then((data) => {
            if (data.success) {
             
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
              emit("close-modal");
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {
            messageForm.message = " ";
            isLoading.value = false;
          });
    };
  
      return {
        submitChecked,
        messageFormRef,
        compose_sms_editor,
        rules,
        isLoading,
        props
      };
    },
  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 5px;
    max-width: 650px;
    max-height: 350px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
  input {
    border-radius: 6px;
  }
  </style>
  
