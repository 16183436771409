
<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading"><Loader /></div>
          <property-month-balance-table
              :response="totalMonthBalances"
              :propertyId="id"
              :refresh="fetchData"
              v-else
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import PropertyMonthBalanceTable from "../components/property-manager/PropertyMonthBalanceTable.vue";
  import Loader from "./../components/Loader.vue";
  import { totalMonthBalance} from "../../api/property-manager/properties";
  import { ElLoading } from "element-plus";
  import { ref } from "vue";
  import { useRoute } from "vue-router";
  
  const isLoading = ref(false);
  const totalMonthBalances = ref([]);
  
  export default {
    name: "Logs",
    components: {
      PropertyMonthBalanceTable,
      Loader,
    },

    setup() {
      const route = useRoute()
      const id = ref(route.params.id)

      const fetchData = async (filter, page) => {
        isLoading.value = true;
        let entity_params = {};
        try {
          if (filter == undefined) {
            entity_params = ref({});
          } else {
            entity_params = filter;
          }
          const response = await totalMonthBalance (entity_params, page, id.value);
          totalMonthBalances.value = response.data
          console.log('response is',response.data)
        } catch (error) {
          console.log(error);
        } finally {
          isLoading.value = false;
        }
      };
  
      const fullScreenLoader = () => {
        ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      };
  
      return {
        fullScreenLoader,
        fetchData,
        isLoading,
        totalMonthBalances,
        id
     
      };
    },
    mounted() {
      this.fetchData();
      
    },
  };
  </script>
  
  