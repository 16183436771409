<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <div class="card-body">
                <div v-if="invoices.data.length === 0">
                  <empty-state-table />
                </div>
                <div class="table-responsive" v-else>
                  <div class="card-table">
                    <table class="table align-items-center">
                      <thead class="sticky-top">
                        <tr>
                          <th
                            class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                          >
                            <input type="checkbox" v-model="selectAll" />
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Invoice Date
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Property Manager
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Invoice Number
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Number Of Units
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Sub Total
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Total
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Status
                          </th>

                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            Payment Status
                          </th>

                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in invoices.data"
                          :key="item.id + index"
                        >
                          <td>
                            <input
                              type="checkbox"
                              v-model="selected"
                              :value="item.id"
                              number
                            />
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ dateTime(item.created_at) }}
                            </p>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item.property_manager_name }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item.invoice_number }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ formatNumber(item.number_of_units) }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ formatNumber(item.commisioned_amount) }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ formatNumber(item.total_amount) }}
                            </p>
                          </td>

                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              {{ item.qr_code_url ? "Sent" : "Not Sent" }}
                            </p>
                          </td>

                          <td class="text-xs font-weight-bold mb-0">
                            <span
                              class="badge badge-sm"
                              :class="getStatusColor(parseInt(item.status))"
                              >{{
                                parseInt(item.status) === 0 ? "Unpaid" : "Paid"
                              }}</span
                            >
                          </td>
                          <td class="align-middle text-center">
                            <el-dropdown trigger="click">
                              <span class="el-dropdown-link">
                                <i class="fa fa-ellipsis-v text-primary"></i>
                              </span>
                              <template #dropdown>
                                <el-dropdown-menu>
                                  <el-dropdown-item
                                    v-if="item.qr_code_url"
                                    class="text-secondary font-weight-bold text-xs"
                                    @click="downloadInvoice(item.id)"
                                    ><i
                                      class="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                    Download Invoice
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                            </el-dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                class="card--footer"
                style="margin-top: 20px; margin-bottom: 80px"
              >
                <el-button
                  type="success"
                  :loading="isLoading"
                  @click="submitChecked()"
                  >Submit</el-button
                >
                <el-button type="danger" @click="toggleSelection()"
                  >Clear selection</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive } from "vue";
import moment from "moment/moment";

import { ElNotification } from "element-plus";
import { sendManagerInvoices } from "@/api/kra";
import EmptyStateTable from "../EmptyTableState.vue";
import { postToKra, kraManagerInvoiceResponse } from "@/api/kra";
import { ElMessage } from "element-plus";
import { downloadManagerInvoicePdf } from "../../../api/property-manager/downloads";

const selected = ref([]);
const isLoading = ref(false);
export default {
  name: "invoices-table",
  props: {
    invoices: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    EmptyStateTable,
  },

  setup(props) {
    const dateFormRef = ref();

    const dateForm = reactive({
      date: "",
    });

    const messageForm = reactive({
      message: "",
    });

    const rules = reactive({
      date: [
        {
          required: true,
          message: "Please pick a date",
          trigger: ["blur", "change"],
        },
      ],
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });
    const shortcuts = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Tomorrow",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "Next week",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];

    const multipleTableRef = ref();

    const toggleSelection = () => {
      return (selected.value = []);
    };

    const selectAll = computed({
      get() {
        if (props.invoices.data && props.invoices.data.length > 0) {
          let allChecked = true;
          for (const invoice of props.invoices.data) {
            if (!selected.value.includes(invoice.id)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          props.invoices.data.forEach((invoice) => {
            checked.push(invoice.id);
          });
        }

        selected.value = checked;
      },
    });

    const formatNumber = (numberToFormat) => {
      return new Intl.NumberFormat().format(numberToFormat);
    };

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const getStatusColor = (status) => {
      switch (status) {
        case 0:
          return "bg-gradient-danger";
        case 1:
          return "bg-gradient-success";

        default:
          return null;
      }
    };

    const submitChecked = () => {
      if (!selected.value) {
        ElNotification({
          title: "Error",
          message: "Please select user",
          type: "error",
        });
        return;
      }

      isLoading.value = true;
      const formData = new FormData();
      formData.append("commisionIds", JSON.stringify(selected.value));

      sendManagerInvoices(formData)
        .then(async (data) => {
          if (data.success) {
            const kra_results = [];
            const response = data.data;

            await Promise.all(
              response.map(async (field) => {
                const invoice_number = field.invoice_number;
                const result = await postToKra(field.kra_json);
                const result_object = {
                  invoice_number: invoice_number,
                  result: result.data,
                };
                kra_results.push(result_object);
              })
            );

            console.log(kra_results);
            kraManagerInvoiceResponse(kra_results).then((response) => {
              console.log(response);
            });

            selected.value = [];
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
          } else if (data.error) {
            selected.value = [];
            ElNotification({
              title: "Error",
              message: data.error,
              type: "error",
            });
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          isLoading.value = false;
        });
    };

    async function downloadInvoice(id) {
      try {
        ElMessage({
          showClose: true,
          type: "success",
          message: "Invoice will be downloading shortly. Please wait...",
        });
        isLoading.value = true;

        await downloadManagerInvoicePdf(id)
          .then((Response) => {
            console.log(Response);

            let fileURL = window.URL.createObjectURL(
              new Blob([Response], {
                type: "application/pdf",
              })
            );
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "manager_invoice_" + Math.round(+new Date() / 1000) + ".pdf"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);

            ElMessage({
              type: "error",
              message: "Whoops! something went wrong downloading report",
            });
          });
      } catch (error) {
        console.log(error);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      isLoading,
      multipleTableRef,
      toggleSelection,
      selected,
      capitalized,
      dateTime,
      submitChecked,
      selectAll,
      downloadInvoice,
      rules,
      messageForm,
      dateFormRef,
      dateForm,
      shortcuts,
      formatNumber,
      getStatusColor,
    };
  },
};
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
