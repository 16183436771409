import { api } from "@/api";

const payment_url = (root, filter, page) => {
  var url = root;

  if (filter.value !== undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=unitCode:like;thirdpartyPaymentRef:like;amountPaid:like;source:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;

  if (filter.value !== undefined && filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;

  if (filter.value !== undefined && filter.value.tenant)
    url = url + "&leaseId=" + filter.value.tenant;

  if (filter.value !== undefined && filter.value.reference_code)
    url = url + "&thirdpartyPaymentRef=" + filter.value.reference_code;

  if (filter.value !== undefined && filter.value.payment_source)
    url = url + "&source=" + filter.value.payment_source;

  if (filter.value !== undefined && filter.value.unit_code)
    url = url + "&unitCode=" + filter.value.unit_code;

  if (filter.value !== undefined && filter.value.propertyId)
    url = url + "&propertyId=" + filter.value.propertyId;

  if (filter.value !== undefined && filter.value.allocation_status)
    url = url + "&allocationStatus=" + filter.value.allocation_status;

  url = url.replace("manager/payment?&", "manager/payment?");

  return url;
};
export const fetchManagersPayments = async (filter, page) => {
  return await api
    .get(payment_url("manager/payments?", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const fetchDashboardManagerPayments = async (filter, page) => {
  return await api
    .get(payment_url("manager/dashboard/payments?", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const fetchPropertyPayments = async (filter, page, id) => {
  return await api
    .get(payment_url(`property/payments/${id}?`, filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const fetchManagersUnallocatedPayments = async (filter, page) => {
  return await api
    .get(payment_url("manager/unallocated-payments?", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const stkPush = async (postBody) => {
  return await api
    .post("stk-push", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const stkManagerPush = async (postBody) => {
  return await api
    .post("manager-stk-push", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const buySms = async (postBody) => {
  return await api
    .post("buy-sms", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const allocatePayment = async (postBody) => {
  return await api
    .post("allocated/payment", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const makeManualPayment = async (postBody) => {
  return await api
    .post("manual/payment", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const fetchCurrentPayments = async (id) => {
  return await api
    .get("property/current/month/payments/" + id)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
