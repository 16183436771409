<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-envelope-open-o text-primary me-2" />Create Penalty </div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="penaltyFormRef"
          :model="penaltyForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-1">
              <el-form-item label="Amount" prop="amount">
                <el-input
                    v-model="penaltyForm.amount"
                    placeholder="Please input amount"
                    clearable />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-1">
              <el-form-item label="Description" prop="description">
                <el-input
                    v-model="penaltyForm.description"
                    placeholder="Please input penalty name"
                    clearable />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-1">
              <el-form-item label="Date" prop="date">
                <el-date-picker
                    style="width: 100%"
                    v-model="penaltyForm.date"
                    type="date"
                    placeholder="Select Date"
                    :size="size"
                />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(penaltyFormRef)"
          >Create Penalty</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { createPenalty } from "@/api/property-manager/penalties";
import { ElNotification } from "element-plus";

const penaltyFormRef = ref();
const isLoading = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "penalty-form",

  props: {
    property: {
      type: Object,
      required: false,
    },
    unitId: {
      required: false,
    },
    refresh: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const penaltyForm = reactive({
      amount: props?.property?.amount || "",
      description: props?.property?.description || "",
      date: props?.property?.date || "",
      unitId: props?.unitId || ""
    });

    const rules = reactive({

      amount: [
        {
          required: true,
          message: "Please input amount",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input description",
          trigger: ["blur", "change"],
        },
      ],
      date: [
        {
          required: true,
          message: "Please select date",
          trigger: ["blur", "change"],
        },
      ]
    });


    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("date", penaltyForm.date);
          formData.append("amount", penaltyForm.amount);
          formData.append("description", penaltyForm.description);
          formData.append("managerId", propertyManagerId);
          formData.append("unitId", penaltyForm.unitId);
          console.log(formData);

          createPenalty(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      penaltyForm,
      submitForm,
      rules,
      penaltyFormRef,
      isLoading,
      props
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 480px;
  max-height: 420px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
