<template>
  <div class="table-responsive p-0">
    <div class="card-body">
      <div v-if="units?.length === 0">
        <empty-state-table />
      </div>
      <div class="table-responsive" v-else>
        <div class="card-table">
          <table class="table align-items-center">
            <thead class="sticky-top">
              <tr>
                <th
                  class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                >
                  <input type="checkbox" v-model="selectAll" />
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Tenant Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Phone Number
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Tenant Email
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Unit Code
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Rent
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in units" :key="item.unitId + index">
                <td>
                  <input
                    type="checkbox"
                    v-model="selected"
                    :value="item.unitId"
                    number
                  />
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{
                      item?.leases[0]?.tenant?.firstName +
                      " " +
                      item?.leases[0]?.tenant?.lastName
                    }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item?.leases[0]?.tenant?.primaryPhone }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item?.leases[0]?.tenant?.email }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item?.unitCode }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item?.hoaFee }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 with-vertical-line">
            <p class="text-uppercase text-sm text-center">
              Enter Invoice Due Date
            </p>
            <el-form
              ref="dateFormRef"
              :model="dateForm"
              :rules="rules.date"
              label-width="200px"
              size=""
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
            >
              <div class="card-body">
                <div class="col-md-12 mb-3">
                  <el-form-item label="Pick Date And Time" prop="date">
                    <el-date-picker
                      v-model="dateForm.date"
                      style="width: 100%"
                      type="datetime"
                      placeholder="Select date and time"
                      :shortcuts="shortcuts"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="col-md-6">
            <p class="text-uppercase text-sm text-center">
              Enter Footer Message Here
            </p>
            <el-form
              ref="messageFormRef"
              :model="messageForm"
              :rules="rules.message"
              label-width="200px"
              size=""
              status-icon
              :scroll-to-error="true"
              label-position="top"
              :hide-required-asterisk="true"
            >
              <div class="card-body">
                <div class="mb-3">
                  <el-form-item label="Message" prop="message">
                    <el-input
                      v-model="messageForm.message"
                      placeholder=""
                      type="textarea"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <hr class="horizontal dark" />
      </div>
    </div>
    <div class="card--footer" style="margin-top: 20px; margin-bottom: 80px">
      <el-button type="success" :loading="isLoading" @click="submitChecked()"
        >Submit</el-button
      >
      <el-button type="danger" @click="toggleSelection()"
        >Clear selection</el-button
      >
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import { ElNotification } from "element-plus";
import {
  kraRentInvoiceResponse,
  postToDeities,
  sendRentInvoices,
} from "@/api/kra";

const selected = ref([]);
const isLoading = ref(false);
const dateFormRef = ref();

const dateForm = reactive({
  date: "",
});

const messageForm = reactive({
  message: "",
});

const rules = reactive({
  date: [
    {
      required: true,
      message: "Please pick a date",
      trigger: ["blur", "change"],
    },
  ],
  message: [
    {
      required: true,
      message: "Please input your message",
      trigger: ["blur", "change"],
    },
  ],
});
const shortcuts = [
  {
    text: "Today",
    value: new Date(),
  },
  {
    text: "Tomorrow",
    value: () => {
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24);
      return date;
    },
  },
  {
    text: "Next week",
    value: () => {
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
      return date;
    },
  },
];

export default defineComponent({
  components: { EmptyStateTable },
  props: {
    units: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    }
  },
  setup(props) {
    const toggleSelection = () => {
      return (selected.value = []);
    };
    const selectAll = computed({
      get() {
        if (props.units && props.units.length > 0) {
          let allChecked = true;
          for (const unit of props.units) {
            if (!selected.value.includes(unit.unitId)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          props.units.forEach((unit) => {
            checked.push(unit.unitId);
          });
        }
        selected.value = checked;
      },
    });

    const submitChecked = () => {
      if (!selected.value) {
        ElNotification({
          title: "Error",
          message: "Please select user",
          type: "error",
        });
        return;
      }

      isLoading.value = true;
      const formData = new FormData();
      formData.append("unitIds", JSON.stringify(selected.value));

      sendRentInvoices(formData)
        .then(async (data) => {
          if (data.success) {
            const kra_results = [];
            const response = data.data;

            await Promise.all(
              response.map(async (field) => {
                const invoice_number = field.invoice_number;
                const type = field.type;
                const result = await postToDeities(field.kra_json);
                const result_object = {
                  type: type,
                  invoice_number: invoice_number,
                  result: result.data,
                };
                kra_results.push(result_object);
              })
            );

            kraRentInvoiceResponse(kra_results).then((response) => {
              console.log(response);
            });

            selected.value = [];
            ElNotification({
              title: "Success",
              message: data.message,
              type: "success",
            });
          } else if (data.error) {
            selected.value = [];
            ElNotification({
              title: "Error",
              message: data.error,
              type: "error",
            });
          } else {
            Object.values(data.errors).forEach((val) =>
              ElNotification({
                title: "Error",
                message: val[0],
                type: "error",
              })
            );
          }
        })
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
          })
        )
        .finally(() => {
          props.refresh();
          isLoading.value = false;
        });
    };

    return {
      toggleSelection,
      selectAll,
      submitChecked,
      shortcuts,
      isLoading,
      rules,
      dateFormRef,
      messageForm,
      dateForm,
      selected,
    };
  },
});
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
