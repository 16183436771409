<template>
    <div class="card h-100">
        <div class="pb-0 card-header mb-0">
            <h6>{{ title }}</h6>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas id="chart-pie" class="chart-canvas" height="500"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "Pie-chart",

    props: {
        title: {
            type: String,
        },
        units: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        // Pie chart for occupied vs unoccupied units
        var ctxPie = document.getElementById("chart-pie").getContext("2d");
        new Chart(ctxPie, {
            type: "pie",
            data: {
                labels: this.units.unit_statuses,
                datasets: [{
                   
                    data: this.units.total_units,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.6)', // Leased
                        'rgba(54, 162, 235, 0.6)'  // Unleased
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                    }
                }
            }
        });
    },
};
</script>
