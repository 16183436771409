import {api} from "../index";

export const fetchManagerialUsers = async() => {
    let response = await api
        .get("system-users")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};


export const fetchManagerialUserLogs = async(id) => {
    let response = await api
        .get(`system-user/logs/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const viewManagerialUser = async(id) => {
    let response = await api
        .get(`system-users/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const removeManagerialUser = async(id) => {
    let response = await api
        .delete(`system-users/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}
export const createManagerialUser = async(postBody) => {
    let response = await api
        .post("system-users", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const updateManagerialUser = async(body) => {
    let response = await api
        .patch("system-users/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

