<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <property-managers-table
            :propertyManagers="propertyManagers"
            :refresh="fetchData"
            :searchQuery="searchTerm"
            v-else
        />
      </div>
    </div>
  </div>
</template>

<script>

import PropertyManagersTable from "../components/admin/PropertyManagersTable.vue";
import Loader from "./../components/Loader.vue";
import { ElLoading } from "element-plus";
import { ref } from "vue";
import {fetchManagersData} from "../../api/admin/managers";

const isLoading = ref(false);
const landlord = ref(true);
const propertyManagers = ref([]);
const searchTerm = ref("");
export default {
  name: "propertyManagers",
  components: {
    PropertyManagersTable,
    Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      let entity_params = {};
      isLoading.value = true;
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchManagersData(entity_params, page);
        propertyManagers.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      propertyManagers,
      isLoading,
      searchTerm,
      landlord,

    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>
