<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-home text-primary me-2" />Lease Unit</div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="leaseFormRef"
          :model="leaseForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-form-item label="Tenant" prop="tenant">
                <el-select-v2
                    v-model="leaseForm.tenant"
                    filterable
                    :options="tenants"
                    placeholder="Please select a tenant"
                    style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Lease Term" prop="date_range">
                <el-date-picker
                    v-model="leaseForm.date_range"
                    style="width: 100%"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(leaseFormRef)"
          >Lease</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {createLease} from "@/api/property-manager/leases";
import { fetchUnLeasedManagerTenants } from "../../../api/property-manager/tenants";
import { ElNotification } from "element-plus";

const value2 = ref('')
const leaseFormRef = ref();
const isLoading = ref(false);
const tenants = ref([]);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "lease-form",

  props: {
    unit: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    const leaseForm = reactive({
      id: props?.unit?.unitId || 0,
      tenant: props?.unit?.tenant || "",
      date_range: props?.unit?.date_range || "",
    });

    console.log(props?.unit?.unitId)
    const rules = reactive({

      tenant: [
        {
          required: true,
          message: "Please select tenant",
          trigger: ["blur", "change"],
        },
      ],

      date_range: [
        {
          required: true,
          message: "Please select date-range",
          trigger: ["blur", "change"],
        },
      ]
    });
    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("unitId", props?.unit?.unitId);
          formData.append("date_range", leaseForm.date_range);
          formData.append("tenantId", leaseForm.tenant);
          formData.append("managerId", propertyManagerId);

          createLease(formData)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                        ElNotification({
                          title: "Error",
                          message: val[0],
                          type: "error",
                        })
                    );
                  }
                })
                .catch((err) =>
                    console.log(err),

                )
                .finally(() => {
                  isLoading.value = false;
                });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      leaseForm,
      submitForm,
      rules,
      leaseFormRef,
      isLoading,
      props,
      tenants,
      value2
    };
  },

  created() {
    this.fetchTenantList();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchUnLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.tenantId}`,
          label: `${tenant.firstName  + " " + tenant.lastName}`,
        }));

        tenants.value = tempTenants;
        console.log(tenants)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 600px;
  max-height: 380px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
