import {api} from "@/api";
export const createPenalty = async(body) => {
    let response = await api
        .post("penalties", body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const removePenalty = async(id) => {
    let response = await api
        .delete(`penalties/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}