import { api } from "../index";

const property_manager_invoices_url = (root, filter, page) => {
  var url = root;

  if (filter.value !== undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=invoice_number:like;number_of_units:like;commisioned_amount:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;

  url = url.replace(
    "admin/property-managers/invoices?",
    "admin/property-managers/invoices?"
  );

  return url;
};
export const fetchInvoices = async (filter, page) => {
  return await api
    .get(property_manager_invoices_url(`commisions`, filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const getInvoice = async (id) => {
  return await api
    .get(`invoice/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const getInvoices = async (propertyId) => {
  return await api
    .get(`property-invoices/${propertyId}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const getCreditNotes = async (propertyId) => {
  return await api
    .get(`property-credit-notes/${propertyId}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
