import { api } from "../";
const tenant_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=firstName:like;lastName:like;email:like;primaryPhone:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.tenant_email)
        url = url + "&tenant_email=" + filter.value.tenant_email;

    if (filter.value != undefined && filter.value.tenant_name)
        url = url + "&tenant_name=" + filter.value.tenant_name;

    if (filter.value != undefined && filter.value.propertyManagerId)
    url = url + "&propertyManagerId=" + filter.value.propertyManagerId;

    if (filter.value != undefined && filter.value.lease_status)
        url = url + "&lease_status=" + filter.value.lease_status;

    url = url.replace("admin/search/tenants?&", "admin/search/tenants?");

    return url;
};

export const fetchTenants = async(filter, page) => {

    let response = await api
        .get(tenant_url("/admin/admin/tenants?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchTenantDetails = async(id) => {
    let response = await api
        .get(`tenants/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchLeasedManagerTenants = async() => {
    let response = await api
        .get("/admin/leased/tenants")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchUnLeasedManagerTenants = async() => {
    let response = await api
        .get("managers/un-leased/tenants")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyTenants = async(id) => {
    let response = await api
        .get("property/tenants/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyTenantsWithAccountBalance = async(id) => {
    let response = await api
        .get("property/tenants/accounts/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchPaymentsData = async(id) => {
    let response = await api
        .get("tenants/payments/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchDepositsData = async(id) => {
    let response = await api
        .get("tenants/deposit/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchWaterBillData = async(id) => {
    let response = await api
        .get("tenants/water-bills/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const fetchUnitChargesData = async(id) => {
    let response = await api
        .get("tenants/unit-charges/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPenaltiesData = async(id) => {
    let response = await api
        .get("tenants/penalties/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchArrearsData = async(id) => {
    let response = await api
        .get("tenants/arrears/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchPropertyTenantsWaterBill = async(id) => {
    let response = await api
        .get("property/units/water-bill/"+ id)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const createTenant = async(postBody) => {
    let response = await api
        .post("tenants", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateTenant = async(body) => {
    let response = await api
        .post("update/tenant/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const changeTenantStatus = async(id) => {
    let response = await api
        .post(`deactivate/${id}/tenant`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

export const removeTenant = async(id) => {
    let response = await api
        .delete(`tenants/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
}

