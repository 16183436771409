<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center">
        <button @click="newTenantDocument" class="btn mb-0 btn-success btn-xs null null ms-auto suspend-button" >
          <i class="fas fa-plus me-1" aria-hidden="true"></i>New Document
        </button>
      </div>
    </div>
    <div class="row">
      <div class="card-body px-0 pt-0 pb-2">
        <div  v-if="tenantDocument.data.length === 0">
          <empty-state-table />
        </div>
        <div class="table-responsive p-0" v-else>
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Tenant Name
                </th>

                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Title
                </th>

                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Description
                </th>

                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Created Date
                </th>

                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in tenantDocument.data" :key="item.docId + index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <div class="avatar avatar-sm me-3 bg-gradient-primary">
                        {{
                          getInitials(
                              item?.tenant?.firstName  + " " + item?.tenant?.lastName
                          ).toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ item?.tenant?.firstName  + " " + item?.tenant?.lastName }}
                      </h6>
                    </div>
                  </div>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.docTitle }}</p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ item.docDesc }}</p>
                </td>

                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{
                                        dateTime(item.uploadDate)
                                      }}</span>
                </td>
                <td class="align-middle text-center">
                  <el-dropdown trigger="click">
                                      <span class="el-dropdown-link">
                                        <i class="fa fa-ellipsis-v text-primary"></i>
                                      </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="downloadDocument(item.docId)"
                        ><i class="fa fa-cloud-download" aria-hidden="true"></i>
                          Download
                        </el-dropdown-item>
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="deleteTenantDocument(item.docId)"
                        ><i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center mb-3">
        <Pagination
            :totalPages="Pagination.total_pages"
            :perPage="Pagination.per_page"
            :currentPage="Pagination.current_page"
            @pagechanged="onPageChange"
        />
      </div>
      <hr class="horizontal dark" />
    </div>
  </div>
  <UploadTenantDocumentForm
      v-if="showModal"
      @close-modal="showModal = false"
      :propertyId="props.propertyId"
      @refresh="refresh"
  />
</template>
<script>

import EmptyStateTable from "@/views/components/EmptyTableState.vue";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import UploadTenantDocumentForm from "@/components/Modals/property-manager/UploadTenantDocumentForm.vue";
import {h, reactive, ref, toRefs, watch} from "vue";
import {ElMessage, ElMessageBox} from "element-plus";
import { removeTenantDocument } from "@/api/property-manager/documents";
import moment from "moment/moment";

const showModal = ref(false);
const isLoading = ref(false);
export default {
  name: "TenantDocumentTable",

  components: {
    UploadTenantDocumentForm,
    EmptyStateTable,
    Pagination
  },
  props: {
    tenantDocument: {
      type: Object,
      required: true,
    },
    propertyId: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  setup(props){

    const { tenantDocument, refresh, searchQuery } = toRefs(props);

    const total_pages = ref(0);
    const per_page = ref(0);
    const updateTotalPages = () => {
      const total_records = tenantDocument.value?.total || 0;
      const per_page = tenantDocument.value?.per_page || 10; // Set a default value if per_page is not available
      if (per_page > 0 && total_records > 0) {
        total_pages.value = Math.ceil(total_records / per_page);
      } else {
        total_pages.value = 0;
      }
    };

    watch(tenantDocument, () => {
      updateTotalPages();
      Pagination.current_page = tenantDocument.value?.current_page || 1; // Update the current_page property
    });

    updateTotalPages();

    const Pagination = reactive({
      current_page: tenantDocument.value?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      refresh.value(Filter.searchquery, page);
    };

    const Filter = reactive({
      searchquery: searchQuery.value != null ? searchQuery.value : "",
    });
    const newTenantDocument = () => {
      showModal.value = true;
    }
    const deleteTenantDocument = async (id) => {
      ElMessageBox.confirm(
          h(
              "div",
              { style: "color: black, font-size: 14px" },
              "The expense will be deleted. Continue?"
          ),
          "Delete Expense",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true,
          }
      ).then(async () => {
        try {
          isLoading.value = true;
          const { message, success } = await removeTenantDocument(id);

          if (success) {
            refresh.value();
            ElMessage({
              type: "success",
              message: message,
            });
          } else {
            ElMessage({
              type: "error",
              message: message,
            });
          }
        } catch (error) {
          ElMessage({
            type: "error",
            message: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      });
    };

    const getInitials = (name) => {
      if (name) {
        const nameSplit = name?.split(" ");
        const firstInitials = nameSplit[0]?.charAt(0);
        const secondInitials = nameSplit[1]?.charAt(0);

        return (
            (firstInitials ? firstInitials : "") +
            (secondInitials ? secondInitials : "")
        );
      }
      return "";
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    return {
      newTenantDocument,
      showModal,
      props,
      deleteTenantDocument,
      getInitials,
      dateTime,
      Pagination,
      onPageChange,
    }
  }
}
</script>
<style scoped>
.suspend-button {
  position: absolute;
  right: 50px;
}

.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}

</style>