<template>
    <div class="card h-100">
        <div class="pb-0 card-header mb-0">
            <h6>{{ title }}</h6>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas id="chart-line" class="chart-canvas" height="500"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "line-chart",

    props: {
        title: {
            type: String,
            default: "PROPERTY VS UNIT GRAPH",
        },
        property_unit: {
            type: Object,
            required: true,
        },
    },

    mounted() {

        //line chart for property vs unit

        var ctxLine = document.getElementById("chart-line").getContext("2d");
        new Chart(ctxLine, {
            type: "line",
            data: {
                labels: this.property_unit.properties,
                datasets: [{
                    label: "Unit Count",
                    data: this.property_unit.unit_counts,
                    borderColor: "#4BB543 ",
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                    }
                }
            }
        });
    },
};
</script>
