import {api} from "../index";


export const getUserDetails = async() => {
    let response = await api
        .get("/business/settings")
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateProfile = async(postBody, id) => {
    let response = await api
        .post(`update/business/${id}`, postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateLogo = async(body) => {
    let response = await api
        .post("update-logo", body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const deleteLogo = async() => {
    let response = await api
        .get("remove-logo")
        .then((response) => response)
        .catch((e) => e.response);
    return response;
};