import {api} from "@/api";

const log_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=log:like;device:like;slug:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.user_name)
        url = url + "&user_name=" + filter.value.user_name;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;


    url = url.replace("logs?&", "logs?");

    return url;
};
export const fetchManagersLogs = async(filter, page) => {
    return await api
        .get(log_url("logs?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};